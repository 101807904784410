import { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const useUnsavedChangesWarning = (hasUnsavedChanges: boolean) => {
  const navigate = useNavigate();

  const handleNavigation = useCallback(
    (path: string) => {
      if (hasUnsavedChanges) {
        // Instead of returning false, we'll just navigate
        navigate(path);
      } else {
        navigate(path);
      }
    },
    [hasUnsavedChanges, navigate],
  );

  useEffect(() => {
    const handleBeforeUnload = (_event: BeforeUnloadEvent) => {};

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  return handleNavigation;
};

export default useUnsavedChangesWarning;
