import React from "react";
import { Box, Container, Typography, Button, Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";

const SubscriptionSuccess: React.FC = () => {
  return (
    <Box
      sx={{ py: 8, background: "#101A2F", minHeight: "100vh", display: "flex" }}
    >
      <Helmet>
        <title>Thank you for your subscription!</title>
        <meta
          name="description"
          content="Thank you for subscribing to Attend Pro."
        />
      </Helmet>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8} lg={6}>
            <Box
              sx={{
                textAlign: "center",
                p: 4,
                bgcolor: "white",
                borderRadius: 2,
                boxShadow: 3,
              }}
              color="black"
            >
              <Typography variant="h4" gutterBottom>
                Welcome to Attend Pro!
              </Typography>
              <Typography variant="h6" component="h1" gutterBottom>
                Your subscription was successful.
              </Typography>
              <Typography variant="body1" paragraph>
                Please sign up or log in to get started with your new
                subscription.
              </Typography>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button variant="contained" color="primary" href="/login">
                    Log In
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="primary" href="/login">
                    Sign Up
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SubscriptionSuccess;
