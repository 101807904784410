import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DrawerContent from "./DrawerContent";
import { AppBar, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { FlexibleEncounterData, useSignalR } from "../contexts/SignalRContext";
import SnackbarSuccess from "./SnackBarSuccess";
import { useUser } from "../contexts/UserContext";
import * as Sentry from "@sentry/react";
const drawerWidth = 300;

export interface IEncounter {
  id: string;
  date: string;
  status: "in progress" | "completed";
  startTime: string;
  endTime?: string;
  autoRemoveAt?: string;
  totalDurationInSeconds?: number;
  title?: string;
  patientDetails?: string;
  deletedAt?: string;
  deletedBy?: string;
}

export default function Sidebar() {
  const { userData } = useUser();
  const [encounters, setEncounters] = useState<IEncounter[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { addEncounterUpdateListener, removeEncounterUpdateListener } =
    useSignalR();
  const location = useLocation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const userEmail = userData?.email;
  const [deletingEncounters, setDeletingEncounters] = useState<Set<string>>(
    new Set(),
  );
  const { handleLogout, fetchWithAuth } = useUser();

  const logout = () => {
    handleLogout();
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDeleteAllEncounters = async () => {
    try {
      const apiResponse = await fetchWithAuth("DeleteAllEncounters", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (apiResponse.ok) {
        setEncounters([]);
        navigate("/");
      } else {
        throw new Error(`Failed to delete encounter: ${apiResponse.status}`);
      }
    } catch (error) {
      console.error("Error deleting encounter:", error);
    }
  };

  const handleDeleteEncounter = async (encounterId: string) => {
    // Optimistically remove the encounter from the list
    setEncounters(
      encounters.filter((encounter) => encounter.id !== encounterId),
    );

    // Set the deleting state for this encounter
    setDeletingEncounters((prev) => new Set(prev).add(encounterId));

    try {
      const apiResponse = await fetchWithAuth("DeleteEncounter", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ encounterId }),
      });

      if (apiResponse.ok) {
        // If currently on the deleted encounter's detail page, navigate away
        if (location.pathname === `/encounter/${encounterId}`) {
          navigate("/");
        }
        // Show success message
        setSnackbarMessage("Encounter deleted successfully");
        setSnackbarOpen(true);
      } else {
        throw new Error(`Failed to delete encounter: ${apiResponse.status}`);
      }
    } catch (error) {
      console.error("Error deleting encounter:", error);
      // Revert the optimistic update
      setEncounters((prevEncounters) =>
        [...prevEncounters, encounters.find((e) => e.id === encounterId)!].sort(
          (a, b) =>
            new Date(b.startTime).getTime() - new Date(a.startTime).getTime(),
        ),
      );
      // Show an error message to the user (you'll need to implement this)
      // showErrorMessage('Failed to delete encounter. Please try again.');
    } finally {
      // Remove the deleting state for this encounter
      setDeletingEncounters((prev) => {
        const newSet = new Set(prev);
        newSet.delete(encounterId);
        return newSet;
      });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchEncounters = async (): Promise<void> => {
      setLoading(true);

      try {
        const apiResponse = await fetchWithAuth("GetEncounters", {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!apiResponse.ok) {
          throw new Error(
            `API responded with status code ${apiResponse.status}`,
          );
        }

        const data = await apiResponse.json();
        data.sort(
          (a: IEncounter, b: IEncounter) =>
            new Date(b.startTime).getTime() - new Date(a.startTime).getTime(),
        );
        setEncounters(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching encounters:", error);
        // Add Sentry logging for non-OK responses
        Sentry.captureException(error, {
          tags: {
            context: "FetchEncounters",
          },
        });
        setLoading(false);
      }
    };

    fetchEncounters();

    const handleSideBarSignalRUpdate = (
      updatedDataArray: FlexibleEncounterData[],
    ) => {
      setEncounters((prevEncounters) => {
        let updatedEncounters = [...prevEncounters];

        updatedDataArray.forEach((updatedData) => {
          const index = updatedEncounters.findIndex(
            (encounter) => encounter.id === updatedData.encounterId,
          );

          if (index !== -1) {
            // Update existing encounter
            updatedEncounters[index] = {
              ...updatedEncounters[index],
              title: updatedData.title ?? updatedEncounters[index].title,
              status:
                (updatedData.status as "in progress" | "completed") ??
                updatedEncounters[index].status,
              patientDetails:
                updatedData.patientDetails ??
                updatedEncounters[index].patientDetails,
              startTime:
                updatedData.startTime ?? updatedEncounters[index].startTime,
              totalDurationInSeconds:
                updatedData.totalDurationInSeconds ??
                updatedEncounters[index].totalDurationInSeconds,
            };
          } else {
            // Add new encounter
            updatedEncounters.push({
              id: updatedData.encounterId,
              date: new Date(updatedData.startTime).toLocaleDateString(),
              status: updatedData.status as "in progress" | "completed",
              startTime: updatedData.startTime,
              endTime: updatedData.endTime,
              title: updatedData.title,
              patientDetails: updatedData.patientDetails,
              totalDurationInSeconds: updatedData.totalDurationInSeconds || 0,
            });
          }
        });

        // Sort the encounters by startTime, most recent first
        updatedEncounters.sort(
          (a, b) =>
            new Date(b.startTime).getTime() - new Date(a.startTime).getTime(),
        );

        return updatedEncounters;
      });
    };

    addEncounterUpdateListener("sidebar", handleSideBarSignalRUpdate);

    return () => {
      removeEncounterUpdateListener("sidebar", handleSideBarSignalRUpdate);
      //console.log("removed callback handler");
    };
  }, [userData]);

  const handleCreateEncounter = async () => {
    navigate("/");
    try {
      const apiResponse = await fetchWithAuth("GetEncounters", {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!apiResponse.ok) {
        throw new Error(`API responded with status code ${apiResponse.status}`);
      }

      const data = await apiResponse.json();
      data.sort(
        (a: IEncounter, b: IEncounter) =>
          new Date(b.startTime).getTime() - new Date(a.startTime).getTime(),
      );
      setEncounters(data);
    } catch (error) {
      console.error("Error fetching encounters:", error);
      // Add Sentry logging for non-OK responses
      Sentry.captureException(error, {
        tags: {
          context: "FetchEncounters",
        },
      });
    }
  };

  return (
    <Box
      sx={{ display: "flex" }}
      p={{ xs: 0, sm: 1 }}
      role="navigation"
      aria-label="Main Navigation"
    >
      <AppBar
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          width: { xs: "100%" }, // Full width on mobile screens
          display: { sm: "none" }, // Hide AppBar on screens larger than mobile
          backgroundColor: "#101A2F",
        }}
      >
        <Toolbar>
          <IconButton
            aria-label="Toggle navigation menu"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ color: "white", mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h4"
            color="white"
            noWrap
            component="div"
            role="banner"
          >
            <img
              src="/attend.svg"
              alt="Attend Logo"
              width="30"
              height="30"
              style={{ marginTop: "4px", marginRight: "4px" }}
            />
            Attend
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        aria-label="Mobile Navigation Menu"
      >
        {
          <DrawerContent
            encounters={encounters}
            handleCreateEncounter={handleCreateEncounter}
            handleDeleteEncounter={handleDeleteEncounter}
            handleDeleteAllEncounters={handleDeleteAllEncounters}
            loading={loading}
            userEmail={userEmail}
            logout={logout}
            deletingEncounters={deletingEncounters}
            currentPath={location.pathname} // Pass the current path to DrawerContent
          />
        }
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        open
      >
        {
          <DrawerContent
            encounters={encounters}
            handleCreateEncounter={handleCreateEncounter}
            handleDeleteEncounter={handleDeleteEncounter}
            handleDeleteAllEncounters={handleDeleteAllEncounters}
            loading={loading}
            userEmail={userEmail}
            logout={logout}
            deletingEncounters={deletingEncounters}
            currentPath={location.pathname} // Pass the current path to DrawerContent
          />
        }
      </Drawer>
      <SnackbarSuccess
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
}
