import React, { useRef, useEffect } from "react";

interface AudioBarVisualizerProps {
  audioLevel: number;
  isRecording: boolean;
}

const AudioBarVisualizer: React.FC<AudioBarVisualizerProps> = ({
  audioLevel,
  isRecording,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const animationFrameRef = useRef<number | null>(null);
  const dataArrayRef = useRef<number[]>(new Array(50).fill(0));

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const draw = () => {
      // Shift the existing data to the left
      dataArrayRef.current.shift();
      // Add the new audio level to the end
      dataArrayRef.current.push(audioLevel);

      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Set the bar style
      ctx.fillStyle = isRecording ? "#ff4d4d" : "#E8EBF1";

      // Draw the bars
      const barWidth = canvas.width / 100;
      const middleY = canvas.height / 2;
      dataArrayRef.current.forEach((value, index) => {
        const barHeight = value * canvas.height; // Use half of the canvas height
        const x = index * 2 * barWidth;

        // Draw the top half of the bar
        ctx.fillRect(x, middleY - barHeight, barWidth - 1, barHeight);

        // Draw the bottom half of the bar
        ctx.fillRect(x, middleY, barWidth - 1, barHeight);
      });

      // Request the next animation frame
      animationFrameRef.current = requestAnimationFrame(draw);
    };

    draw();

    return () => {
      if (animationFrameRef.current !== null) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [audioLevel, isRecording]);

  return (
    <canvas
      ref={canvasRef}
      width={300}
      height={100}
      style={{ width: "100%", height: "100px" }}
    />
  );
};

export default AudioBarVisualizer;
