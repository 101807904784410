import React, { useEffect, useState } from "react";
import { Alert, Button, LinearProgress } from "@mui/material";
import { OktaAuth } from "@okta/okta-auth-js";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";
import Template from "./Template";
import fetchAPI from "../../utilities/fetchAPI";
import config from "../../config";

const oktaIssuer = config.OKTA_ISSUER;
const oktaClientId = config.OKTA_CLIENT_ID;
export const oktaAuthClient = new OktaAuth({
  issuer: oktaIssuer,
  clientId: oktaClientId,
  redirectUri: window.location.origin + "/octave-login",
  scopes: ["openid", "profile", "email"],
});

const OctaveLogin: React.FC = () => {
  const navigate = useNavigate();
  const { authenticate } = useUser();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (oktaAuthClient.token.isLoginRedirect()) {
      setIsLoading(true);
      console.log("issuer" + oktaIssuer, "\nclient Id" + oktaClientId);
      oktaAuthClient.token.parseFromUrl().then((res) => {
        fetchAPI("ValidateOktaSignIn", {
          method: "POST",
          body: JSON.stringify({
            idToken: res.tokens.idToken?.idToken,
          }),
        })
          .then((response) => {
            if (!response.ok) {
              return Promise.reject(response);
            }

            return response.json();
          })
          .then((body) => {
            authenticate(body);
            navigate("/");
          })
          .catch(async (e) => {
            console.log(e);
            try {
              const error = await e.text();
              setError(error);
            } catch {
              setError(
                "Something went wrong. Please try again or contact support@crafthealthtech.com.",
              );
            }
            setIsLoading(false);
          });
      });
    }
  }, []);

  const signInWithOkta = () => {
    setIsLoading(true);
    console.log("issuer" + oktaIssuer, "\nclient Id" + oktaClientId);
    oktaAuthClient.signInWithRedirect();
  };

  if (!isLoading) {
    signInWithOkta();
  }

  return (
    <Template>
      <>
        {!isLoading && (
          <Button
            type="button"
            size="large"
            fullWidth
            disableElevation
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              height: { xs: "36px", sm: "42px" },
              fontSize: { xs: "14px", sm: "16px" },
              borderRadius: "3px",
            }}
            onClick={signInWithOkta}
            disabled={isLoading}
          >
            Sign in with Okta
          </Button>
        )}
        {isLoading && <LinearProgress sx={{ width: "100%" }} />}
        {error && (
          <Alert severity="error" sx={{ width: "100%" }}>
            {error}
          </Alert>
        )}
      </>
    </Template>
  );
};

export default OctaveLogin;
