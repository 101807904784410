import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useUser } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const SubscriptionSuccessReturningUser: React.FC = () => {
  const { fetchUserData } = useUser();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchUserData();
        setIsLoading(false);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Box
      sx={{ py: 8, background: "#101A2F", minHeight: "100vh", display: "flex" }}
    >
      <Helmet>
        <title>Thank you for your subscription!</title>
        <meta
          name="description"
          content="Thank you for subscribing to Attend Pro."
        />
      </Helmet>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8} lg={6}>
            <Box
              sx={{
                textAlign: "center",
                p: 4,
                bgcolor: "white",
                borderRadius: 2,
                boxShadow: 3,
              }}
              color="black"
            >
              <Typography variant="h4" gutterBottom>
                Welcome to Attend Pro!
              </Typography>
              <Typography variant="h6" gutterBottom>
                Your subscription was successful.
              </Typography>
              <Typography variant="body1" paragraph>
                Please wait while we update your account...
              </Typography>
              {isLoading && (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                  <CircularProgress />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SubscriptionSuccessReturningUser;
