import React from "react";
import { Box, Container, Typography, Link, Grid } from "@mui/material";

const Footer: React.FC = () => {
  return (
    <Box sx={{ py: 4, backgroundColor: "#101A2F", color: "white" }}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Contact Us </Typography>
            <Typography>
              Email:{" "}
              <Link href="mailto:info@crafthealthtech.com" color="inherit">
                info@crafthealthtech.com
              </Link>
            </Typography>
            <Typography>2261 Market Street STE 10689</Typography>
            <Typography>San Francisco, CA 94114 </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="caption">
              Copyright Craft Health Technologies, LLC. 2024
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
