import { useCallback, useRef } from "react";

const useDebounce = (callback: (...args: any[]) => void, delay: number) => {
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);
  const latestArgs = useRef<any[]>([]);

  const debouncedCallback = useCallback(
    (...args: any[]) => {
      latestArgs.current = args;
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
      debounceTimer.current = setTimeout(async () => {
        await callback(...latestArgs.current);
      }, delay);
    },
    [callback, delay],
  );

  return debouncedCallback;
};

export default useDebounce;
