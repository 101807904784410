import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Transcript from "./Transcript";
import {
  ContentCopy as CopyIcon,
  AutoFixHigh as MagicWandIcon,
} from "@mui/icons-material";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import CheckIcon from "@mui/icons-material/Check";
import NoteRevisionInput from "./NoteRevisionInput";
import {
  FlexibleEncounterData,
  NoteContent,
} from "../../contexts/SignalRContext";
import DynamicNoteRenderer from "./DynamicNoteRenderer";
import SnackbarError from "../../reusable-components/SnackBarError";
import ChangeTemplateDialog from "../../reusable-components/ChangeTemplateDialog";
import SnackbarSuccess from "../../reusable-components/SnackBarSuccess";
import { useUser } from "../../contexts/UserContext";
import * as Sentry from "@sentry/react";
interface FlexibleDocumentationDetailsProps {
  encounterData: FlexibleEncounterData;
  noteContent: NoteContent;
  onNoteContentChange: (sectionTitle: string, content: string) => void;
  awaitingRevision: boolean;
  setAwaitingRevision: (content: boolean) => void;
  onTemplateChange: (templateId: string) => void; // New prop for handling template change
}

const FlexibleDocumentationDetails: React.FC<
  FlexibleDocumentationDetailsProps
> = ({
  encounterData,
  noteContent,
  onNoteContentChange,
  awaitingRevision,
  setAwaitingRevision,
  onTemplateChange,
}) => {
  const [isRevisionInputOpen, setIsRevisionInputOpen] = useState(false);
  const [learnSucceeded, setLearnSucceeded] = useState(false);
  const noteContentRef = useRef<{ [key: string]: string }>({});
  const [errorSnackbar, setErrorSnackbar] = useState<{
    open: boolean;
    message: string;
  }>({ open: false, message: "" });
  const [isChangeTemplateDialogOpen, setIsChangeTemplateDialogOpen] =
    useState(false);
  const [successSnackbar, setSuccessSnackbar] = useState<{
    open: boolean;
    message: string;
  }>({ open: false, message: "" });
  const { fetchWithAuth } = useUser();

  const isLegacyTemplate = encounterData.noteTemplateId
    ?.toLowerCase()
    .includes("soap");

  //console.log("DocumentationDetails received encounterData:", encounterData);

  useEffect(() => {
    noteContentRef.current = Object.entries(noteContent).reduce(
      (acc, [key, value]) => {
        acc[key] = value.content;
        return acc;
      },
      {} as { [key: string]: string },
    );
  }, [noteContent]);

  const updateNoteContentRef = (sectionTitle: string, content: string) => {
    noteContentRef.current[sectionTitle] = content;
    onNoteContentChange(sectionTitle, content);
  };

  const handleReviseClick = () => {
    setIsRevisionInputOpen(true);
  };

  const handleConciseClick = () => {
    if (encounterData.encounterId) {
      const requestBody = {
        encounterId: encounterData.encounterId,
        request:
          "Make the note as concise as possible while remaining comprehensive. Use abbreviations if necessary",
      };
      setAwaitingRevision(true);

      fetchWithAuth("RequestDocumentationRevision", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => {
          if (response.ok) {
            console.log("Instruction sent successfully");
          } else {
            console.error("Error sending instruction:", response.status);
          }
        })
        .catch((error) => {
          console.error("Error sending instruction:", error);
          setErrorSnackbar({
            open: true,
            message:
              "Something went wrong when requesting an edit. Please try again.",
          });
          setAwaitingRevision(false);
        });
    }
  };

  const handleLearnStyleClick = () => {
    if (Object.keys(noteContent).length > 0) {
      // Extracting SOAP note sections from the noteContent
      const subjective = noteContent["Subjective"]?.content || "";
      const objective = noteContent["Objective"]?.content || "";
      const assessmentAndPlan =
        noteContent["Assessment and Plan"]?.content || "";

      // Checking if all required sections are present and non-empty
      if (subjective && objective && assessmentAndPlan) {
        const requestBody = {
          subjective,
          objective,
          assessmentAndPlan,
        };

        fetchWithAuth("SetCustomSoapNote", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        })
          .then((response) => {
            if (response.ok) {
              console.log("Style learned successfully");
              setLearnSucceeded(true);
            } else {
              console.error("Error learning style:", response.status);
            }
          })
          .catch((error) => {
            console.error("Error learning style:", error);
          });
      } else {
        console.error("One or more required SOAP note sections are empty");
      }
    } else {
      console.error("Note content is empty");
    }
  };

  const handleSendInstruction = (request: string) => {
    if (encounterData.encounterId) {
      const requestBody = {
        encounterId: encounterData.encounterId,
        request: request,
      };
      setAwaitingRevision(true);

      fetchWithAuth("RequestDocumentationRevision", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => {
          if (response.ok) {
            console.log("Instruction sent successfully");
          } else {
            throw new Error("Failed to send instruction");
          }
        })
        .catch((error) => {
          console.error("Error sending instruction:", error);
          setErrorSnackbar({
            open: true,
            message:
              "Something went wrong when requesting an edit. Please try again.",
          });
          setAwaitingRevision(false);
        });
    }
  };

  const handleCloseRevisionInput = () => {
    setIsRevisionInputOpen(false);
  };

  const handleCloseErrorSnackbar = () => {
    setErrorSnackbar({ open: false, message: "" });
  };

  const handleCopyNote = () => {
    const textToCopy = Object.entries(noteContentRef.current)
      .map(([title, content]) => `${title}:\n${content}`)
      .join("\n\n");

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setSuccessSnackbar({ open: true, message: "Copied Note to Clipboard" });
        console.log("Content copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        Sentry.captureException(err);
      });
  };

  const handleCloseSuccessSnackbar = () => {
    setSuccessSnackbar({ open: false, message: "" });
  };

  const handleTemplateChipClick = () => {
    setIsChangeTemplateDialogOpen(true);
  };

  const handleCloseTemplateDialog = () => {
    setIsChangeTemplateDialogOpen(false);
  };

  return (
    <Box
      display="flex"
      flexGrow={1}
      overflow="hidden"
      role="region"
      aria-label="Encounter Documentation"
    >
      <Card
        variant="outlined"
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardHeader
          title={
            <Box>
              <Typography variant="h1" sx={{ fontSize: "h5.fontSize" }}>
                Encounter Note
              </Typography>
              {encounterData.noteTemplate &&
                encounterData.noteTemplate.name && (
                  <Tooltip
                    title={encounterData.noteTemplate.description || ""}
                    arrow
                  >
                    <Chip
                      label={encounterData.noteTemplate.name}
                      color="secondary"
                      size="small"
                      sx={{ height: "22px" }}
                      onClick={handleTemplateChipClick}
                      role="button"
                      aria-label={`Current template: ${encounterData.noteTemplate.name}. Click to change template.`}
                    />
                  </Tooltip>
                )}
            </Box>
          }
          action={
            <Button
              variant="contained"
              color="primary"
              startIcon={<CopyIcon sx={{ height: "15px" }} />}
              onClick={handleCopyNote}
              disabled={!encounterData || awaitingRevision}
              disableElevation
              aria-label="Copy note to clipboard"
            >
              Copy to clipboard
            </Button>
          }
        />
        <CardContent sx={{ flexGrow: 1, overflow: "auto" }}>
          <Box role="toolbar" aria-label="Note editing tools">
            <Button
              variant="outlined"
              color="primary"
              disabled={awaitingRevision}
              onClick={handleConciseClick}
              sx={{ mr: 1, mb: 1 }}
              aria-label="Make note more concise"
            >
              Make Concise
            </Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={awaitingRevision}
              startIcon={<MagicWandIcon sx={{ height: "15px" }} />}
              onClick={handleReviseClick}
              sx={{ mr: 1, mb: 1 }}
              aria-label="Open revision dialog"
            >
              Revise
            </Button>
            {isLegacyTemplate && (
              <Button
                variant="outlined"
                disabled={awaitingRevision}
                color={learnSucceeded ? "success" : "primary"}
                startIcon={learnSucceeded ? <CheckIcon /> : <FingerprintIcon />}
                onClick={handleLearnStyleClick}
                sx={{ mr: 1, mb: 1 }}
                aria-label={
                  learnSucceeded
                    ? "Style has been learned"
                    : "Learn documentation style"
                }
              >
                {learnSucceeded ? "Style learned" : "Learn style"}
              </Button>
            )}
          </Box>

          {awaitingRevision && (
            <Typography variant="caption" role="status" aria-live="polite">
              Generating...
            </Typography>
          )}

          <Stack spacing={1}>
            <>
              <DynamicNoteRenderer
                noteTemplate={encounterData.noteTemplate}
                noteContent={noteContent}
                updateNoteContentRef={updateNoteContentRef}
                awaitingRevision={awaitingRevision}
              />
              <Transcript
                transcript={encounterData.transcript}
                locale={encounterData?.locale}
              />
            </>
          </Stack>
        </CardContent>
      </Card>
      {isRevisionInputOpen && (
        <NoteRevisionInput
          open={isRevisionInputOpen}
          onSendInstruction={handleSendInstruction}
          onClose={handleCloseRevisionInput}
          aria-label="Revision instructions"
        />
      )}
      <SnackbarSuccess
        open={successSnackbar.open}
        message={successSnackbar.message}
        onClose={handleCloseSuccessSnackbar}
        aria-live="polite"
      />

      <SnackbarError
        open={errorSnackbar.open}
        message={errorSnackbar.message}
        onClose={handleCloseErrorSnackbar}
        aria-live="assertive"
      />

      <ChangeTemplateDialog
        open={isChangeTemplateDialogOpen}
        onClose={handleCloseTemplateDialog}
        selectedTemplate={encounterData.noteTemplateId || ""}
        onTemplateChange={onTemplateChange}
        isTemplateSelectLocked={awaitingRevision}
      />
    </Box>
  );
};

export default FlexibleDocumentationDetails;
