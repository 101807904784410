import React from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Alert,
  Button,
  TextField,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from "@mui/material";
import { IEncounterDocument } from "../../contexts/SignalRContext";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { getFormResponseSectionContent } from "../../models/templates";
import LoadingCard from "../../reusable-components/LoadingCard";
import ErrorIcon from "@mui/icons-material/Error";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface DocumentTabsProps {
  activeTab: number;
  setActiveTab: (tab: number) => void;
  encounterDocuments: IEncounterDocument[];
  // Remove onDeleteDocument prop from here
}

const DocumentTabs: React.FC<DocumentTabsProps> = ({
  activeTab,
  setActiveTab,
  encounterDocuments,
  // Remove onDeleteDocument
}) => {
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const getTabLabel = (doc: IEncounterDocument, index: number) => {
    const label = doc.name || `Document ${index + 1}`;
    let icon = null;

    switch (doc.status) {
      case "failed":
        icon = (
          <ErrorIcon fontSize="small" sx={{ ml: 1, color: "error.main" }} />
        );
        break;
    }

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {label}
        {icon}
      </Box>
    );
  };

  return (
    <Tabs
      value={activeTab}
      onChange={handleTabChange}
      variant="scrollable"
      scrollButtons="auto"
      sx={{
        borderBottom: 1,
        borderColor: "divider",
        "& .MuiTabs-flexContainer": {
          gap: "8px",
          justifyContent: "flex-start",
        },
        "& .MuiTabs-scroller": {
          height: "auto",
        },
      }}
    >
      <Tab key="instructions" label="Patient Instructions" sx={tabStyle} />
      {encounterDocuments.map((doc, index) => (
        <Tab key={doc.id} label={getTabLabel(doc, index)} sx={tabStyle} />
      ))}
    </Tabs>
  );
};

const tabStyle = {
  textTransform: "none",
  fontSize: "0.75rem",
  minWidth: "50px",
  maxWidth: "125px",
  minHeight: "36px",
  padding: "6px 6px",
  borderRadius: "4px",
  border: "0px solid",
  borderColor: "primary.dark",
  fontWeight: "bold",
  opacity: 0.7, // Default opacity for unselected tabs
  color: "text.primary",
  "&.Mui-selected": {
    opacity: 1, // Full opacity for selected tab
    backgroundColor: "background.paper",
  },
  "&:hover": {
    backgroundColor: "action.hover",
    opacity: 0.9, // Slightly increase opacity on hover
  },
  "& .MuiTab-wrapper": {
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
};

export default DocumentTabs;

interface TabContentProps {
  activeTab: number;
  setActiveTab: (tab: number) => void;
  patientInstructions: string;
  setPatientInstructions: (instructions: string) => void;
  encounterDocuments: IEncounterDocument[];
  handleDocumentContentChange: (index: number, newContent: string) => void;
  handleCopyContent: () => void;
  onDeleteDocument: (documentId: string) => void; // New prop
}

export const TabContent: React.FC<TabContentProps> = ({
  activeTab,
  setActiveTab, // Add this prop
  patientInstructions,
  setPatientInstructions,
  encounterDocuments,
  handleDocumentContentChange,
  handleCopyContent,
  onDeleteDocument,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleDelete = () => {
    if (activeTab !== 0) {
      const doc = encounterDocuments[activeTab - 1];
      if (doc) {
        onDeleteDocument(doc.id);

        // Adjust the active tab after deletion
        if (activeTab === encounterDocuments.length) {
          // If deleting the last tab, move to the previous one
          setActiveTab(activeTab - 1);
        } else {
          // If deleting a tab in the middle, stay on the same index
          // (which will now show the next document)
          setActiveTab(activeTab);
        }
      }
    }
    setOpen(false);
  };

  const renderHeader = () => {
    let caption = null;
    let actionButtons = null;

    if (activeTab !== 0) {
      const doc = encounterDocuments[activeTab - 1];
      if (doc && doc.status === "completed") {
        caption = (
          <Typography variant="caption" color="text.secondary">
            {doc.name} - Created on {new Date(doc.createdAt).toLocaleString()}
          </Typography>
        );
        actionButtons = (
          <React.Fragment>
            <ButtonGroup
              variant="outlined"
              ref={anchorRef}
              aria-label="split button"
            >
              <Button
                onClick={handleCopyContent}
                startIcon={<ContentCopyIcon />}
              >
                Copy
              </Button>
              <Button
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select action"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              sx={{
                zIndex: 1,
              }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        <MenuItem onClick={handleDelete}>
                          <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
                          Delete
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </React.Fragment>
        );
      }
    } else {
      actionButtons = (
        <Button
          variant="outlined"
          startIcon={<ContentCopyIcon sx={{ fontSize: ".75rem" }} />}
          onClick={handleCopyContent}
        >
          Copy
        </Button>
      );
    }

    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box flexGrow={1}>{caption}</Box>
        <Box>{actionButtons}</Box>
      </Box>
    );
  };

  const renderContent = () => {
    if (activeTab === 0) {
      return (
        <TextField
          multiline
          fullWidth
          minRows={5}
          maxRows={20}
          value={patientInstructions}
          onChange={(e) => setPatientInstructions(e.target.value)}
          placeholder="Enter patient instructions here..."
          InputProps={{
            inputComponent: TextareaAutosize as any,
          }}
        />
      );
    }

    const doc = encounterDocuments[activeTab - 1];

    if (!doc) {
      return <Box mt={2}>No document found for this tab.</Box>;
    }

    switch (doc.status) {
      case "queued":
        return (
          <LoadingCard
            loadingText="Preparing document"
            helperText="This may take a moment"
          />
        );
      case "failed":
        return (
          <>
            <Alert severity="error" sx={{ mt: 2 }}>
              Unable to generate document. Please try again.
              <Box mt={1}>
                <Button
                  variant="outlined"
                  size="small"
                  color="inherit"
                  onClick={() => onDeleteDocument(doc.id)}
                >
                  Remove
                </Button>
              </Box>
            </Alert>
          </>
        );
      case "completed":
        return (
          <TextField
            multiline
            fullWidth
            minRows={5}
            maxRows={20}
            value={
              getFormResponseSectionContent(doc.content?.sections?.[0]) || ""
            }
            onChange={(e) =>
              handleDocumentContentChange(activeTab - 1, e.target.value)
            }
            InputProps={{
              inputComponent: TextareaAutosize as any,
            }}
          />
        );
      default:
        return <Box mt={2}>Unknown document status.</Box>;
    }
  };

  return (
    <Box mt={2}>
      {renderHeader()}
      {renderContent()}
    </Box>
  );
};
