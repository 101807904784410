import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Button,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import TemplateHeader from "./TemplateHeader";
import SectionList from "./SectionList";
import SnackbarError from "../../reusable-components/SnackBarError";
import SnackbarSuccess from "../../reusable-components/SnackBarSuccess";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";
import { IFormTemplate, FormSection } from "../../models/templates";
import { useNavigate } from "react-router-dom";
import TemplateContentInput from "./TemplateContentInput";
import { useUser } from "../../contexts/UserContext";

interface ClinicianTemplateBuilderProps {
  preloadedTemplate?: IFormTemplate;
}

const ClinicianTemplateBuilder: React.FC<ClinicianTemplateBuilderProps> = ({
  preloadedTemplate,
}) => {
  const initialTemplateState: IFormTemplate = {
    id: "",
    name: "",
    description: "",
    version: 1,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    formType: "clinical",
    ownerType: "user",
    isPublic: false,
    sections: [],
  };

  const [template, setTemplate] = useState<IFormTemplate>(initialTemplateState);
  const [nameError, setNameError] = useState<string | undefined>(undefined);
  const [successSnackbar, setSuccessSnackbar] = useState<{
    open: boolean;
    message: string;
  }>({
    open: false,
    message: "",
  });
  const [errorSnackbar, setErrorSnackbar] = useState<{
    open: boolean;
    message: string;
  }>({
    open: false,
    message: "",
  });
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [isContentInputMode, setIsContentInputMode] =
    useState<boolean>(!preloadedTemplate);
  const [isSectionBeingEdited, setIsSectionBeingEdited] =
    useState<boolean>(false);
  const handleNavigation = useUnsavedChangesWarning(hasUnsavedChanges);
  const { fetchWithAuth } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (preloadedTemplate) {
      setTemplate(preloadedTemplate);
      setIsContentInputMode(false);
    }
  }, [preloadedTemplate]);

  useEffect(() => {
    setHasUnsavedChanges(
      template.name !== initialTemplateState.name ||
        template.description !== initialTemplateState.description ||
        template.sections.length > 0,
    );
  }, [template]);

  const handleTemplateChange = (field: keyof IFormTemplate, value: string) => {
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      [field]: value,
    }));
    if (field === "name") {
      setNameError(value.trim() ? undefined : "Template name is required");
    }
  };

  const handleAddSection = (newSection: FormSection) => {
    if (
      template.sections.some((section) => section.title === newSection.title)
    ) {
      setErrorSnackbar({
        open: true,
        message:
          "A section with this title already exists. Please choose a unique title.",
      });
      return;
    }
    setTemplate({
      ...template,
      sections: [...template.sections, newSection],
    });
  };

  const handleUpdateSection = (updatedSection: FormSection, index: number) => {
    if (
      template.sections.some(
        (section, i) => i !== index && section.title === updatedSection.title,
      )
    ) {
      setErrorSnackbar({
        open: true,
        message:
          "A section with this title already exists. Please choose a unique title.",
      });
      return;
    }
    setTemplate({
      ...template,
      sections: template.sections.map((section, i) =>
        i === index ? updatedSection : section,
      ),
    });
  };

  const handleRemoveSection = (index: number) => {
    setTemplate({
      ...template,
      sections: template.sections.filter((_, i) => i !== index),
    });
  };

  const handleMoveSection = (index: number, direction: "up" | "down") => {
    const newSections = [...template.sections];
    const newIndex = direction === "up" ? index - 1 : index + 1;

    if (newIndex >= 0 && newIndex < newSections.length) {
      [newSections[index], newSections[newIndex]] = [
        newSections[newIndex],
        newSections[index],
      ];
      setTemplate({ ...template, sections: newSections });
    }
  };

  const saveTemplate = async () => {
    if (!template.name.trim()) {
      setNameError("Template name is required");
      return;
    }

    try {
      const endpoint = template.id
        ? "UpdateClinicalFormTemplate"
        : "CreateClinicalFormTemplate";
      const method = template.id ? "PUT" : "POST";

      const response = await fetchWithAuth(endpoint, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(template),
      });

      if (response.ok) {
        const savedTemplate = await response.json();
        console.log("Template saved successfully:", savedTemplate);
        setSuccessSnackbar({
          open: true,
          message: `Template ${template.id ? "updated" : "saved"} successfully!`,
        });

        // Clear the form after successful save
        setTemplate(initialTemplateState);
        // Navigate back to template library after successful save
        navigate("/template-library");
        setNameError(undefined);
      } else {
        throw new Error(
          `Error ${template.id ? "updating" : "saving"} template: ${response.status}`,
        );
      }
    } catch (error) {
      setErrorSnackbar({
        open: true,
        message: `Error ${template.id ? "updating" : "saving"} template. Please try again.`,
      });
    }
  };

  const handleCancel = () => {
    if (hasUnsavedChanges) {
      setShowConfirmDialog(true);
    } else {
      handleNavigation("/template-library");
    }
  };

  const handleConfirmCancel = () => {
    setShowConfirmDialog(false);
    handleNavigation("/template-library");
  };

  const handleCloseConfirmDialog = () => {
    setShowConfirmDialog(false);
  };

  const handleCloseSuccessSnackbar = () => {
    setSuccessSnackbar({ ...successSnackbar, open: false });
  };

  const handleCloseErrorSnackbar = () => {
    setErrorSnackbar({ ...errorSnackbar, open: false });
  };

  const handleTemplateGenerated = (generatedTemplate: IFormTemplate) => {
    setTemplate(generatedTemplate);
    setIsContentInputMode(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Paper
        elevation={0}
        sx={{
          p: 4,
          maxWidth: 1000,
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <Box sx={{ flexGrow: 1, overflow: "auto", pb: 10 }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2, mr: 2 }}>
            <Typography
              variant="body2"
              fontWeight={600}
              sx={{ color: "text.secondary", mr: 2 }}
            >
              {preloadedTemplate ? "Edit Template" : "Create Template"}
            </Typography>
            {preloadedTemplate && (
              <Typography variant="caption" color="text.secondary">
                Version {template.version}, last updated on{" "}
                {new Date(template.updatedAt).toLocaleDateString()}{" "}
                {new Date(template.updatedAt).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}
              </Typography>
            )}
          </Box>

          {isContentInputMode ? (
            <TemplateContentInput
              onTemplateGenerated={handleTemplateGenerated}
            />
          ) : (
            <>
              <TemplateHeader
                name={template.name}
                description={template.description}
                onNameChange={(value) => handleTemplateChange("name", value)}
                onDescriptionChange={(value) =>
                  handleTemplateChange("description", value)
                }
                nameError={nameError}
              />

              <Divider sx={{ my: 4 }} />

              <Typography
                variant="h5"
                fontWeight={400}
                gutterBottom
                sx={{ color: "black", mb: 2 }}
              >
                Sections
              </Typography>
              <SectionList
                sections={template.sections}
                onUpdateSection={handleUpdateSection}
                onRemoveSection={handleRemoveSection}
                onMoveSection={handleMoveSection}
                onAddSection={handleAddSection}
                onEditStateChange={setIsSectionBeingEdited}
              />
            </>
          )}
        </Box>
        {!isContentInputMode && (
          <Box
            sx={{
              position: "sticky",
              bottom: 0,
              left: 0,
              right: 0,
              padding: 2,
              borderTop: "1px solid #e0e0e0",
              display: "flex",
              justifyContent: "flex-end",
              zIndex: 1000,
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCancel}
              size="large"
              disableElevation
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={saveTemplate}
              size="large"
              disabled={
                !template.name.trim() ||
                template.sections.length === 0 ||
                isSectionBeingEdited
              }
            >
              Save Template
            </Button>
          </Box>
        )}
      </Paper>
      <SnackbarSuccess
        open={successSnackbar.open}
        message={successSnackbar.message}
        onClose={handleCloseSuccessSnackbar}
      />
      <SnackbarError
        open={errorSnackbar.open}
        message={errorSnackbar.message}
        onClose={handleCloseErrorSnackbar}
      />
      <Dialog
        open={showConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Unsaved Changes"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have unsaved changes. Are you sure you want to leave without
            saving?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Stay
          </Button>
          <Button onClick={handleConfirmCancel} color="primary" autoFocus>
            Leave
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ClinicianTemplateBuilder;
