import React from "react";
import { Box, Container, Typography } from "@mui/material";

const About: React.FC = () => {
  return (
    <Box
      sx={{ py: 8, background: "#101A2F", textAlign: "left", color: "#FFFFFF" }}
    >
      <Container>
        <Typography variant="h4" component="h2" gutterBottom>
          Built by a Physician for Clinicians Everywhere
        </Typography>
        <Typography variant="subtitle1" maxWidth={800} paragraph>
          We need tools that simplify our work and bring joy back to caregiving,
          combating burnout and keeping our best clinicians available when we
          need them most.
        </Typography>
        <Typography variant="subtitle1" maxWidth={800} paragraph>
          After over a decade in primary care and building tech for top
          healthcare companies, our founder launched Attend to revolutionize the
          lives of clinicians and patients through innovative technology.
        </Typography>
      </Container>
    </Box>
  );
};

export default About;
