import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Divider,
  Button,
  List,
  ListItem,
  Chip,
  CircularProgress,
  Stack,
  IconButton,
} from "@mui/material";
import { IFormTemplate } from "../../models/templates";
import NotFound from "../../reusable-components/NotFoundDisplay";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useUser } from "../../contexts/UserContext";

const TemplateViewer: React.FC = () => {
  const { templateId } = useParams<{ templateId: string }>();
  const navigate = useNavigate();
  const [template, setTemplate] = useState<IFormTemplate | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { fetchWithAuth } = useUser();

  useEffect(() => {
    const fetchTemplate = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetchWithAuth(
          `GetUserClinicalNoteTemplates?templateId=${templateId}`,
        );
        if (response.ok) {
          const data = await response.json();
          setTemplate(data);
        } else if (response.status === 404) {
          setError("not_found");
        } else {
          setError("fetch_error");
        }
      } catch (error) {
        console.error("Error fetching template:", error);
        setError("fetch_error");
      } finally {
        setIsLoading(false);
      }
    };

    if (templateId) {
      fetchTemplate();
    }
  }, [templateId]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error === "not_found") {
    return (
      <NotFound
        itemType="Template"
        message="The requested template could not be found. It may have been deleted or you may not have permission to view it."
        backUrl="/settings"
        backText="Back to Settings"
      />
    );
  }

  if (error === "fetch_error") {
    return (
      <NotFound
        itemType="Template"
        message="The requested template could not be found."
        backUrl="/settings"
        backText="Back to Settings"
      />
    );
  }

  if (!template) {
    return null; // This should never happen, but TypeScript needs it
  }

  const renderSections = () => {
    return (
      <List sx={{ p: 0 }}>
        {template.sections.map((section, index) => (
          <React.Fragment key={index}>
            <ListItem
              sx={{
                display: "block",
                p: 2,
                backgroundColor: "transparent",
                transition: "background-color 0.3s",
                "&:hover": {
                  backgroundColor: "rgba(33, 150, 243, 0.04)",
                },
              }}
            >
              <Box sx={{ position: "relative" }}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", mr: 2, flexGrow: 1 }}
                  >
                    {section.title}
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      opacity: 0,
                      transition: "opacity 0.2s",
                    }}
                  >
                    <IconButton size="small" aria-label="move up" disabled>
                      <ArrowUpwardIcon fontSize="small" />
                    </IconButton>
                    <IconButton size="small" aria-label="move down" disabled>
                      <ArrowDownwardIcon fontSize="small" />
                    </IconButton>
                  </Stack>
                </Box>
                {section.description && (
                  <Typography variant="body1" sx={{ mb: 1, mr: 3 }}>
                    {section.description}
                  </Typography>
                )}
                {section.example && (
                  <Typography
                    variant="body2"
                    sx={{
                      fontStyle: "italic",
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                      mb: 1,
                      ml: 2,
                      mr: 3,
                      mt: 0,
                      color: "text.secondary",
                    }}
                  >
                    "{section.example}"
                  </Typography>
                )}
                <Box sx={{ mt: 1 }}>
                  {section.allowAIPrefill && (
                    <Chip
                      icon={
                        <img
                          src="/attend.svg"
                          style={{ width: 20, height: 20 }}
                          alt="AI"
                        />
                      }
                      label="Auto Compose"
                      size="small"
                      sx={{ mr: 1, backgroundColor: "#101A2F", color: "white" }}
                    />
                  )}
                  {section.required && (
                    <Chip
                      label="Required"
                      size="small"
                      sx={{ backgroundColor: "#e0e0e0" }}
                    />
                  )}
                </Box>
              </Box>
            </ListItem>
            {index < template.sections.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Paper
        elevation={0}
        sx={{
          p: 4,
          maxWidth: 1000,
          mx: "auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <Box sx={{ flexGrow: 1, overflow: "auto", pb: 10 }}>
          <Typography
            variant="h6"
            fontWeight={400}
            gutterBottom
            sx={{ color: "text.secondary", mb: 2 }}
          >
            View Note Template
          </Typography>

          <Typography
            variant="h4"
            fontWeight={400}
            gutterBottom
            sx={{ color: "black", mb: 2 }}
          >
            {template.name}
          </Typography>
          {template.description && (
            <Typography variant="body1" sx={{ mb: 2 }}>
              {template.description}
            </Typography>
          )}

          <Divider sx={{ my: 4 }} />

          <Typography
            variant="h5"
            fontWeight={400}
            gutterBottom
            sx={{ color: "black", mb: 2 }}
          >
            Sections
          </Typography>
          {renderSections()}
        </Box>
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            right: 0,
            padding: 2,
            borderTop: "1px solid #e0e0e0",
            display: "flex",
            justifyContent: "flex-end",
            zIndex: 1000,
            backgroundColor: "background.paper",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/template-library")}
            size="large"
          >
            Back to Templates
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default TemplateViewer;
