import React, { useState, useRef, useEffect, useCallback } from "react";
import { Typography, Card, Box, CircularProgress, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useMonitorUserConsentAndAccess from "../hooks/useMonitorUserConsentAndAccess";
import SubscriptionCard from "./SubscriptionCard";
import { useUser } from "../contexts/UserContext";
import SnackbarError from "./SnackBarError";
import AudioBarVisualizer from "./AudioBarVisualizer";
import useAudioLevel from "../hooks/useAudioLevel";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { SupportedLocale } from "../models/localeTypes";
import EncounterSettings from "./EncounterSettings";

const MAX_RECORDING_TIME = 2 * 60 * 60; // 2 hours in seconds
const WARNING_TIME = 10 * 60; // 10 minutes in seconds
const MIN_CHUNK_DURATION = 1; // Minimum chunk duration in seconds
const MIN_CHUNK_SIZE = 8000; // Minimum chunk size in bytes

interface RecordEncounterProps {
  initialEncounterId?: string;
  initialDuration?: number;
  initialPartNumber?: number;
  onRecordingFinished?: () => void;
  initialLocale?: SupportedLocale;
  initialTemplateId?: string;
}

//TODO - error handling on create encounter
//SignalR message to update user data

const EncounterRecorder: React.FC<RecordEncounterProps> = ({
  initialEncounterId,
  initialDuration = 0,
  initialPartNumber = 0,
  onRecordingFinished,
  initialTemplateId,
  initialLocale,
}) => {
  const { encounterId: urlEncounterId } = useParams<{ encounterId: string }>();
  const navigate = useNavigate();
  const { hasAccess } = useMonitorUserConsentAndAccess();
  const { userData, fetchWithAuth, retryFetch } = useUser();

  // State
  const [isRecording, setIsRecording] = useState(false);
  const [displayPauseState, setDisplayPauseState] =
    useState(!!initialEncounterId); // For UI updates only
  const [isProcessing, setIsProcessing] = useState(false);

  const [isCleaningUp, setIsCleaningUp] = useState(false);
  const [isEndingEncounter, setIsEndingEncounter] = useState(false);
  const [isSettingUp, setIsSettingUp] = useState(false);
  const [error, setError] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [timer, setTimer] = useState(initialDuration);
  const [timeRemaining, setTimeRemaining] = useState(
    MAX_RECORDING_TIME - initialDuration,
  );
  const [showWarning, setShowWarning] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string>("");
  const [isTemplateSelectLocked, setIsTemplateSelectLocked] =
    useState(!!initialEncounterId);

  const [isEncounterInProgress, setIsEncounterInProgress] =
    useState(!!initialEncounterId);
  const [selectedLocale, setSelectedLocale] = useState<SupportedLocale>(
    initialLocale || "en-US",
  );

  // Refs
  const isRecordingRef = useRef<boolean>(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);
  const encounterIdRef = useRef<string | null>(
    initialEncounterId || urlEncounterId || null,
  );
  const partNumberRef = useRef(initialPartNumber);
  const chunkStartTimeRef = useRef<number | null>(null);
  const recordingStartTimeRef = useRef<number | null>(null);
  const pauseTimeRef = useRef<number | null>(null);
  const totalPausedTimeRef = useRef<number>(0);
  const isPausedRef = useRef<boolean>(false); // New ref for paused state
  const audioAnalyser = useRef<AnalyserNode | null>(null);
  const audioLevel = useAudioLevel(isRecording, audioAnalyser.current);
  const encounterCreationPromiseRef = useRef<Promise<void> | null>(null);
  const isPausingDueToErrorRef = useRef<boolean>(false);
  const lastChunkSentTimeRef = useRef<number>(0);

  // Callbacks
  const handleError = useCallback((errorMessage: string) => {
    setError(errorMessage);
    setShowSnackbar(true);
    setIsProcessing(false);
    setIsSettingUp(false);
  }, []);

  const sendAudioChunks = useCallback(
    async (
      audioChunks: Blob[],
      partNumber: number,
      isComplete = false,
      duration: number,
      offset: number,
    ) => {
      // Wait for encounter creation to complete if it's in progress
      try {
        if (encounterCreationPromiseRef.current) {
          await encounterCreationPromiseRef.current;
        }

        if (!encounterIdRef.current) {
          console.log("Skipping sending chunks; no active encounter");
          return false;
        }

        const blob = new Blob(audioChunks, { type: "audio/webm" });
        if (blob.size === 0) {
          console.error("Audio chunk is empty");
          return false;
        }

        const queryParams = new URLSearchParams({
          encounterId: encounterIdRef.current,
          partNumber: partNumber.toString(),
          isComplete: isComplete ? "true" : "false",
          duration: duration.toString(),
          offset: offset.toString(),
        });

        const response = await retryFetch(
          `streamEncounterAudio?${queryParams}`,
          {
            method: "POST",
            body: blob,
            headers: {
              "Content-Type": "application/octet-stream",
            },
          },
          3,
        );

        if (response.ok) {
          console.log("Audio chunk uploaded successfully");
          return true;
        } else {
          if (response.status === 400) {
            handleError(
              "Maximum recording limit reached. Ending the encounter.",
            );
            await endEncounter();
            return false;
          }
          throw new Error("Failed to upload audio chunk");
        }
      } catch (error) {
        console.error("Error sending audio chunk:", error);
        handleError(
          "Unable to contact the server. Please check your internet connection",
        );
        return false;
      }
    },
    [handleError],
  );

  const processAudioChunk = useCallback(
    async (
      chunks: Blob[],
      currentPartNumber: number,
      isComplete: boolean,
      duration: number,
      offset: number,
    ) => {
      let chunksToSend = chunks;
      const currentTime = Date.now();
      const timeSinceLastChunk =
        (currentTime - lastChunkSentTimeRef.current) / 1000;
      const chunkBlob = new Blob(chunks, { type: "audio/webm" });
      const chunkSize = chunkBlob.size;

      if (
        timeSinceLastChunk >= MIN_CHUNK_DURATION &&
        chunkSize >= MIN_CHUNK_SIZE
      ) {
        const nextPartNumber = currentPartNumber + 1;
        await sendAudioChunks(
          chunksToSend,
          nextPartNumber,
          isComplete,
          duration,
          offset,
        );
        partNumberRef.current = nextPartNumber;
        lastChunkSentTimeRef.current = currentTime;
      } else if (isComplete) {
        // If it's the final chunk, send it regardless of size or duration
        const nextPartNumber = currentPartNumber + 1;
        await sendAudioChunks(
          chunksToSend,
          nextPartNumber,
          isComplete,
          duration,
          offset,
        );
        partNumberRef.current = nextPartNumber;
      } else {
        console.log(
          `Ignoring small chunk: duration=${timeSinceLastChunk}s, size=${chunkSize} bytes`,
        );
      }
    },
    [sendAudioChunks],
  );

  const startRecorder = useCallback(async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      handleError("Your browser does not support audio recording");
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          channelCount: 1,
          echoCancellation: false,
          autoGainControl: true,
        },
      });

      const audioContext = new AudioContext();
      const analyser = audioContext.createAnalyser();
      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);
      audioAnalyser.current = analyser;

      const recorder = new MediaRecorder(stream);

      recorder.onstart = () => {
        chunkStartTimeRef.current = Date.now();
        if (recordingStartTimeRef.current === null) {
          recordingStartTimeRef.current = Date.now();
        }
      };

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
          const totalSize = chunksRef.current.reduce(
            (acc, chunk) => acc + chunk.size,
            0,
          );
          if (totalSize >= 2 * 1024 * 1024) {
            if (mediaRecorderRef.current) {
              mediaRecorderRef.current.stop();
            }
          }
        }
      };

      recorder.onstop = async () => {
        const currentTime = Date.now();
        const chunkDuration =
          (currentTime - (chunkStartTimeRef.current || 0)) / 1000; // Duration in seconds
        const chunkOffset =
          ((chunkStartTimeRef.current || 0) -
            (recordingStartTimeRef.current || 0)) /
          1000;

        if (!isPausingDueToErrorRef.current) {
          await processAudioChunk(
            chunksRef.current,
            partNumberRef.current,
            !isRecordingRef.current && !isPausedRef.current,
            chunkDuration,
            chunkOffset,
          );
        } else {
          console.log("Skipping chunk processing due to error-induced pause");
          isPausingDueToErrorRef.current = false; // Reset the flag
        }

        chunksRef.current = []; // Reset chunks after sending

        if (
          isRecordingRef.current &&
          timeRemaining > 0 &&
          !isPausedRef.current
        ) {
          startRecorder(); // Restart recorder immediately if not paused and still recording
        } else if (!isRecordingRef.current && !isPausedRef.current) {
          // Only finalize if we're actually ending the encounter, not pausing
          await finalizeEncounter();
        } else if (timeRemaining <= 0) {
          console.log(
            "Maximum recording time reached in onstop handler. Ending encounter.",
          );
          await finalizeEncounter();
        }
      };

      recorder.start(1000);
      mediaRecorderRef.current = recorder;
      setIsRecording(true);
      isRecordingRef.current = true;
    } catch (error) {
      console.error("Error starting recording:", error);
      handleError(
        "Failed to start recording. Please check your microphone settings.",
      );
    }
  }, [handleError, processAudioChunk, timeRemaining]);

  const finalizeEncounter = useCallback(async () => {
    const currentEncounterId = encounterIdRef.current;
    if (!currentEncounterId) {
      console.error("No active encounter to end");
      return;
    }

    try {
      const response = await fetchWithAuth("EndEncounter", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ encounterId: encounterIdRef.current }),
      });

      if (response.ok) {
        console.log("Encounter ended successfully");
        if (onRecordingFinished) {
          onRecordingFinished();
        } else {
          await cleanUpRefs();
          navigate(`/encounter/${currentEncounterId}`);
        }
      } else {
        throw new Error("Failed to end encounter");
      }
    } catch (error) {
      console.error("Error finalizing encounter:", error);
      throw error; // Rethrow to be caught in the endEncounter function
    }
  }, [navigate, handleError, onRecordingFinished]);

  const stopRecorderAndSendChunks = useCallback(async () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      // Wait for the 'onstop' event to fire and process the last chunk
      await new Promise<void>((resolve) => {
        if (mediaRecorderRef.current) {
          const originalOnStop = mediaRecorderRef.current.onstop;
          mediaRecorderRef.current.onstop = (event) => {
            if (originalOnStop && mediaRecorderRef.current)
              originalOnStop.call(mediaRecorderRef.current, event);
            resolve();
          };
        } else {
          resolve();
        }
      });
    }

    if (chunksRef.current.length > 0) {
      const currentTime = Date.now();
      const chunkDuration =
        (currentTime - (chunkStartTimeRef.current || 0)) / 1000;
      const chunkOffset =
        ((chunkStartTimeRef.current || 0) -
          (recordingStartTimeRef.current || 0)) /
        1000;

      await processAudioChunk(
        chunksRef.current,
        partNumberRef.current,
        false, // Not marking as complete
        chunkDuration,
        chunkOffset,
      );
      chunksRef.current = []; // Reset chunks after sending
    }
  }, [processAudioChunk]);

  useEffect(() => {
    return () => {
      if (!isCleaningUp) {
        setIsCleaningUp(true);
        console.log("Component unmounting, starting cleanup...");

        const cleanup = async () => {
          try {
            if (isRecordingRef.current) {
              await stopRecorderAndSendChunks();
              console.log(
                "Recording stopped and chunks sent due to navigation",
              );
            } else {
              console.log("No active recording to stop");
            }
          } catch (error) {
            console.error("Error during stopRecorderAndSendChunks:", error);
          } finally {
            console.log("Performing full cleanup...");
            performFullCleanup();
            console.log("Full cleanup completed");
          }
        };

        // Execute cleanup and log any errors
        cleanup().catch((error) => {
          console.error("Unexpected error during cleanup:", error);
        });
      }
    };
  }, []);

  const startRecording = useCallback(async () => {
    console.log("entering start recording");
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setError(
        "Your browser does not support audio recording. Please try updating your browser or using a different one.",
      );
      setShowSnackbar(true);
      return;
    }

    setIsSettingUp(true);

    if (!encounterIdRef.current && !encounterCreationPromiseRef.current) {
      encounterCreationPromiseRef.current = fetchWithAuth(
        "CreateAndStartEncounter",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            templateId: selectedTemplate,
            locale: selectedLocale, // Add the selected locale here
          }),
        },
      )
        .then((response) => {
          if (!response.ok) {
            if (response.status === 403) {
              throw new Error("Free Encounter Limit Reached");
            }
            throw new Error("Failed to create encounter");
          }
          return response.json();
        })
        .then((data) => {
          encounterIdRef.current = data.encounterId;
          setIsTemplateSelectLocked(true);
        })
        .catch((error) => {
          console.error("Error creating encounter:", error);

          if (error.message === "Free Encounter Limit Reached") {
            handleError(
              "Free Encounter Limit Reached. Please Subscribe to continue.",
            );

            setTimeout(() => {
              window.location.reload();
            }, 2000); // Reload after 2 seconds
          } else {
            handleError(
              "Unable to create a new encounter. Please check your network connection and try again.",
            );
          }

          setIsSettingUp(false);
          if (
            mediaRecorderRef.current &&
            mediaRecorderRef.current.state !== "inactive"
          ) {
            mediaRecorderRef.current.stop();
          }
          encounterCreationPromiseRef.current = null;
          mediaRecorderRef.current = null;
          setIsRecording(false);
          isRecordingRef.current = false;
          setIsEncounterInProgress(false);
        });
    }

    try {
      await startRecorder();
      isPausedRef.current = false;
      setIsEncounterInProgress(true);
      setIsSettingUp(false);
    } catch (error) {
      console.error("Error starting recorder:", error);
      handleError(
        "Failed to start recording. Please check your microphone settings.",
      );
      setIsSettingUp(false);
    }
  }, [selectedTemplate, startRecorder, handleError, selectedLocale]);

  const pauseRecording = useCallback(async (isError: boolean = false) => {
    if (mediaRecorderRef.current && isRecordingRef.current) {
      isPausingDueToErrorRef.current = isError;
      mediaRecorderRef.current.stop();
      isPausedRef.current = true;
      isRecordingRef.current = false; // Set to false to stop the recorder
      setDisplayPauseState(true); // Update UI
      pauseTimeRef.current = Date.now();
    }
  }, []);

  const resumeRecording = useCallback(async () => {
    if (pauseTimeRef.current) {
      const pauseDuration = Date.now() - pauseTimeRef.current;
      totalPausedTimeRef.current += pauseDuration;
      pauseTimeRef.current = null;

      if (recordingStartTimeRef.current !== null) {
        recordingStartTimeRef.current += pauseDuration;
      }
    }

    // Add a small delay before restarting the recorder
    await new Promise((resolve) => setTimeout(resolve, 100));
    await startRecorder();
    isPausedRef.current = false;
    setDisplayPauseState(false); // Update UI
    isRecordingRef.current = true;
  }, [startRecorder]);

  const cleanUpRefs = useCallback(async () => {
    // Reset all relevant states and refs
    mediaRecorderRef.current = null;
    chunksRef.current = [];
    recordingStartTimeRef.current = null;
    chunkStartTimeRef.current = null;
    pauseTimeRef.current = null;
    totalPausedTimeRef.current = 0;
    partNumberRef.current = 0;
  }, []);

  const endEncounter = useCallback(async () => {
    if (isEndingEncounter) return; // Prevent multiple calls
    setIsEndingEncounter(true);

    isRecordingRef.current = false;
    isPausedRef.current = false;
    setDisplayPauseState(false);
    setIsRecording(false);
    setIsProcessing(true);
    setIsEncounterInProgress(false);

    try {
      // If the MediaRecorder is still active, stop it
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state !== "inactive"
      ) {
        mediaRecorderRef.current.stop();
      } else {
        // If the MediaRecorder is already stopped (e.g., when paused), process any remaining chunks
        const currentTime = Date.now();
        const chunkDuration =
          (currentTime - (chunkStartTimeRef.current || 0)) / 1000;
        const chunkOffset =
          ((chunkStartTimeRef.current || 0) -
            (recordingStartTimeRef.current || 0)) /
          1000;

        await processAudioChunk(
          chunksRef.current,
          partNumberRef.current,
          true, // Mark as complete
          chunkDuration,
          chunkOffset,
        );
      }
      // Wait for any ongoing processes to complete
      await new Promise((resolve) => setTimeout(resolve, 1000));
      // Finalize the encounter
      await finalizeEncounter();
      performFullCleanup();
    } catch (error) {
      console.error("Error ending encounter:", error);
      handleError(
        "Failed to end encounter. The recording has been paused. Please try again or contact support if the issue persists.",
      );

      // Pause the recorder instead of stopping it
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state === "recording"
      ) {
        mediaRecorderRef.current.pause();
      }
      isPausedRef.current = true;
      isRecordingRef.current = false;
      setDisplayPauseState(true);
    } finally {
      setIsProcessing(false);
      setIsEndingEncounter(false);
    }
  }, [processAudioChunk, finalizeEncounter, handleError]);

  const performFullCleanup = useCallback(() => {
    if (mediaRecorderRef.current) {
      // Stop all tracks on the MediaStream
      if (mediaRecorderRef.current.stream && mediaRecorderRef.current) {
        mediaRecorderRef.current.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }

      // Remove event listeners
      mediaRecorderRef.current.ondataavailable = null;
      mediaRecorderRef.current.onstop = null;
      mediaRecorderRef.current.onerror = null;

      // Nullify the MediaRecorder reference
      mediaRecorderRef.current = null;
    }

    cleanUpRefs();
    // Reset audio context and analyser
    if (audioAnalyser.current) {
      audioAnalyser.current.disconnect();
      audioAnalyser.current = null;
    }

    // Clear any remaining chunks
    chunksRef.current = [];
  }, [cleanUpRefs]);

  // Effect for timer
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isRecording && !displayPauseState) {
      interval = setInterval(() => {
        setTimer((prevTime) => {
          const newTime = prevTime + 1;
          const remaining = MAX_RECORDING_TIME - newTime;
          setTimeRemaining(remaining);

          if (remaining <= WARNING_TIME && !showWarning) {
            setShowWarning(true);
          }

          if (remaining <= 0) {
            // Stop the recording when max time is reached
            console.log("Maximum recording time reached. Stopping recording.");
            clearInterval(interval);
            endEncounter();
          }

          return newTime;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRecording, displayPauseState, showWarning]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isRecordingRef.current) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  useEffect(() => {
    if (initialTemplateId) {
      setSelectedTemplate(initialTemplateId);
    } else if (userData?.templates && userData.templates.length > 0) {
      setSelectedTemplate(
        userData.defaultNoteTemplateId || userData.templates[0].id,
      );
    } else {
      setSelectedTemplate("soap");
    }
  }, [userData, initialTemplateId]);

  // Initialize component for resuming an encounter
  useEffect(() => {
    if (initialEncounterId) {
      setIsEncounterInProgress(true);
      setDisplayPauseState(true);
      setIsTemplateSelectLocked(true);
      isPausedRef.current = true;
      setTimer(initialDuration);
      setTimeRemaining(MAX_RECORDING_TIME - initialDuration);
      partNumberRef.current = initialPartNumber;
      encounterIdRef.current = initialEncounterId;
    }
  }, [initialEncounterId, initialDuration, initialPartNumber]);

  useEffect(() => {
    console.log("EncounterRecorder mounted");
    return () => {
      console.log("EncounterRecorder unmounting");
    };
  }, []);

  // Helper functions
  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleTemplateChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    if (!isTemplateSelectLocked) {
      setSelectedTemplate(event.target.value as string);
    }
  };

  // Detect if user is on Mac for display purposes only
  const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
  const modifierSymbol = isMac ? "⌥" : "Alt"; // Option (⌥) on Mac, Alt on Windows

  useEffect(() => {
    console.log("Setting up keyboard shortcuts"); // Debug: Check if effect runs

    const handleKeyDown = async (event: KeyboardEvent) => {
      // Don't trigger shortcuts when typing in input fields
      if (
        event.target instanceof HTMLInputElement ||
        event.target instanceof HTMLTextAreaElement
      ) {
        return;
      }

      if (event.altKey) {
        // Use event.code instead of event.key to get physical key regardless of Alt modification
        switch (event.code) {
          case "Digit1": // Alt + 1
          case "Numpad1":
            event.preventDefault();
            if (
              !isRecordingRef.current &&
              !isProcessing &&
              !isSettingUp &&
              !isEncounterInProgress
            ) {
              await startRecording();
            }
            break;

          case "Digit2": // Alt + 2
          case "Numpad2":
            event.preventDefault();
            if (isEncounterInProgress && !isProcessing && !isSettingUp) {
              isPausedRef.current ? resumeRecording() : pauseRecording(false);
            }
            break;

          case "Digit3": // Alt + 3
          case "Numpad3":
            event.preventDefault();
            if (isRecordingRef.current && !isPausedRef.current) {
              await endEncounter();
            }
            break;
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [
    startRecording,
    endEncounter,
    isProcessing,
    isSettingUp,
    isEncounterInProgress,
  ]);

  return (
    <Card
      elevation={0}
      sx={{
        py: 2,
        px: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        my: "auto",
        bgcolor: "#E8EBF1",
      }}
      role="region"
      aria-label="Encounter Recording Interface"
    >
      {hasAccess ? (
        <Card
          variant="outlined"
          style={{
            borderRadius: "25px",
            marginBottom: "20px",
            width: "350px",
            minHeight: "200px",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            boxShadow:
              isRecordingRef.current && !isPausedRef.current
                ? "0 0 20px #ffc2c2"
                : "",
          }}
          role="region"
          aria-label="Recording Controls"
        >
          <EncounterSettings
            selectedTemplate={selectedTemplate}
            handleTemplateChange={handleTemplateChange}
            isTemplateSelectLocked={isTemplateSelectLocked}
            selectedLocale={selectedLocale}
            setSelectedLocale={(locale) => setSelectedLocale(locale)}
          />

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            px={2}
            mb={2}
            role="timer"
            aria-label="Recording Duration"
          >
            <Typography variant="body1" style={{ minWidth: "70px" }}>
              {isPausedRef.current
                ? `Paused`
                : isEncounterInProgress
                  ? formatTime(timer)
                  : "00:00:00"}
            </Typography>

            <Box flexGrow={1} ml={2}>
              <AudioBarVisualizer
                audioLevel={audioLevel || 0.05}
                isRecording={isRecordingRef.current && !isPausedRef.current}
                aria-label="Audio Level Visualization"
              />
            </Box>
          </Box>

          {isSettingUp && (
            <CircularProgress
              style={{ marginTop: "10px" }}
              aria-label="Setting up recording"
            />
          )}

          <Box>
            {isEncounterInProgress && (
              <Box mb={2}>
                <Button
                  onClick={() =>
                    isPausedRef.current
                      ? resumeRecording()
                      : pauseRecording(false)
                  }
                  disabled={isProcessing || isSettingUp}
                  variant="contained"
                  color="primary"
                  disableElevation
                  fullWidth
                  startIcon={
                    isPausedRef.current ? <PlayArrowIcon /> : <PauseIcon />
                  }
                  aria-label={`${isPausedRef.current ? "Resume" : "Pause"} Recording (${modifierSymbol} + 2)`}
                >
                  {isPausedRef.current ? "Resume Recording" : "Pause Recording"}
                </Button>
              </Box>
            )}

            <Button
              onClick={isEncounterInProgress ? endEncounter : startRecording}
              disabled={
                isProcessing ||
                isSettingUp ||
                (isEncounterInProgress && isPausedRef.current)
              }
              variant="contained"
              color={isEncounterInProgress ? "secondary" : "primary"}
              fullWidth
              disableElevation
              sx={{ fontWeight: "bold" }}
              aria-label={
                isEncounterInProgress
                  ? `End and Process Encounter (${modifierSymbol} + 3)`
                  : `Start Recording (${modifierSymbol} + 1)`
              }
            >
              {isEncounterInProgress ? (
                <>End and Process Encounter</>
              ) : (
                <>
                  {isSettingUp
                    ? "Setting Up Recording..."
                    : isProcessing
                      ? "Processing Recording..."
                      : "Start Recording"}
                </>
              )}
            </Button>
          </Box>
        </Card>
      ) : (
        <SubscriptionCard />
      )}

      {isEncounterInProgress && (
        <Typography variant="caption" color={showWarning ? "error" : "black"}>
          {isPausedRef.current
            ? ""
            : `Time remaining: ${formatTime(timeRemaining)}`}
        </Typography>
      )}

      <SnackbarError
        open={showSnackbar}
        message={error}
        onClose={handleCloseSnackbar}
      />
    </Card>
  );
};

export default EncounterRecorder;
