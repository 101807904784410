import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  getLocaleDisplayNameWithEmoji,
  SupportedLocale,
} from "../../models/localeTypes";

interface TranscriptProps {
  transcript?: string;
  locale?: SupportedLocale;
}

const Transcript: React.FC<TranscriptProps> = ({ transcript, locale }) => {
  const lines = transcript ? transcript.split("\n") : [""];
  return (
    <Accordion variant="outlined">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Transcript</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {locale && (
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontStyle: "italic" }}
            >
              Language: {getLocaleDisplayNameWithEmoji(locale)}
            </Typography>
            <Divider sx={{ my: 1 }} />
          </Box>
        )}
        {lines.map((line, index) => (
          <Typography variant="body2" key={index} sx={{ mb: 2 }}>
            {line}
          </Typography>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default Transcript;
