import fetchAPI from "./fetchAPI";

export const portalRedirect = async (stripeCustomerId: string) => {
  try {
    const requestBody = {
      stripeCustomerId: stripeCustomerId,
    };

    const response = await fetchAPI("CreateStripePortalSession", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      const data = await response.json();
      window.location.href = data.url; // Redirect to Stripe portal page
    } else {
      console.error("Failed to create checkout session");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
