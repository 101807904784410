import React from "react";
import { Box, Typography, Link as MuiLink, Divider } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import LegalTemplate from "./LegalTemplate";
import { termsContent } from "./content/terms";
import { privacyContent } from "./content/privacy";
import { baaContent } from "./content/baa";
import AttendIcon from "/attend.svg";

const Legal: React.FC = () => {
  const navigation = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: { xs: 2, sm: 4 },
      }}
    >
      {/* Logo and Title */}
      <RouterLink
        to="/"
        style={{
          textDecoration: "none",
          color: "inherit",
          display: "flex",
          alignItems: "center",
          gap: "4px",
        }}
      >
        <img
          src={AttendIcon}
          alt="Attend"
          style={{
            width: "32px",
            height: "32px",
          }}
        />
        <Typography
          variant="h6"
          component="span"
          sx={{
            fontWeight: 200,
            display: { xs: "none", sm: "block" },
          }}
        >
          Attend
        </Typography>
      </RouterLink>

      {/* Navigation Links */}
      <Box
        sx={{
          display: "flex",
          gap: { xs: 3, sm: 3 },
          flexGrow: 1,
          justifyContent: { xs: "flex-end", sm: "flex-start" },
          pr: { xs: 1, sm: 0 },
        }}
      >
        <MuiLink href="#terms" underline="hover" color="text.primary">
          Terms of Service
        </MuiLink>
        <MuiLink href="#privacy" underline="hover" color="text.primary">
          Privacy Policy
        </MuiLink>
        <MuiLink href="#baa" underline="hover" color="text.primary">
          BAA
        </MuiLink>
      </Box>
    </Box>
  );

  return (
    <LegalTemplate navigation={navigation}>
      {/* Document sections */}
      <Box id="terms" sx={{ scrollMarginTop: "4rem" }}>
        <Typography variant="h2">Terms of Service</Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Last updated: {new Date().toLocaleDateString()}
        </Typography>
        <div dangerouslySetInnerHTML={{ __html: termsContent }} />
      </Box>

      <Divider sx={{ my: 6 }} />

      {/* Privacy Policy */}
      <Box id="privacy" sx={{ scrollMarginTop: "4rem" }}>
        <Typography variant="h2">Privacy Policy</Typography>
        <div dangerouslySetInnerHTML={{ __html: privacyContent }} />
      </Box>

      <Divider sx={{ my: 6 }} />

      {/* BAA */}
      <Box id="baa" sx={{ scrollMarginTop: "4rem" }}>
        <Typography variant="h2">Business Associate Agreement</Typography>
        <div dangerouslySetInnerHTML={{ __html: baaContent }} />
      </Box>

      {/* Back to Top */}
      <Box sx={{ textAlign: "center", mt: 4, mb: 2 }}>
        <MuiLink href="#" underline="hover">
          Back to Top
        </MuiLink>
      </Box>
    </LegalTemplate>
  );
};

export default Legal;
