import React from "react";
import { Box, Container, Paper, Typography, Button } from "@mui/material";

interface SignUpProps {
  handleSignUp: () => void;
}

const SignUp: React.FC<SignUpProps> = ({ handleSignUp }) => {
  return (
    <Box sx={{ py: 4, background: "#F7F8FA" }}>
      <Container>
        <Paper elevation={3} sx={{ p: 4, textAlign: "center" }}>
          <Typography
            variant="h3"
            fontWeight={700}
            component="h2"
            gutterBottom
            textAlign="center"
            p={2}
          >
            Ready to regain control of your time?
          </Typography>
          <Typography
            variant="h5"
            fontWeight={400}
            component="p"
            gutterBottom
            textAlign="center"
            pb={3}
          >
            Try Attend for a few visits today. It's free to get started
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSignUp}
            sx={{ py: 1.5, px: 4 }}
          >
            Try Attend for free
          </Button>
          <Typography variant="body2" sx={{ mt: 2, color: "text.secondary" }}>
            No credit card required. Start saving time today.
          </Typography>
        </Paper>
      </Container>
    </Box>
  );
};

export default SignUp;
