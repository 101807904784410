import React, { useState, useEffect, useCallback } from "react";
import {
  TextField,
  Typography,
  Box,
  Skeleton,
  Tooltip,
  Fade,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface DynamicNoteSectionEditorProps {
  sectionTitle: string;
  sectionDescription?: string;
  sectionContent: string;
  onContentChange: (content: string) => void;
  awaitingRevision: boolean;
}

const DynamicNoteSectionEditor: React.FC<DynamicNoteSectionEditorProps> = ({
  sectionTitle,
  sectionDescription,
  sectionContent,
  onContentChange,
  awaitingRevision,
}) => {
  const [localContent, setLocalContent] = useState(sectionContent);
  const [isHovered, setIsHovered] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    setLocalContent(sectionContent);
  }, [sectionContent]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newContent = event.target.value;
      setLocalContent(newContent);
      onContentChange(newContent);
    },
    [onContentChange],
  );

  const handleCopy = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      navigator.clipboard
        .writeText(localContent)
        .then(() => {
          console.log(`${sectionTitle} content copied to clipboard`);
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 500); // Reset after .5 seconds
        })
        .catch((err) => console.error("Failed to copy text: ", err));
    },
    [localContent, sectionTitle],
  );

  const copiedColor = "rgba(76, 175, 80, 0.1)"; // Very light green

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        mb={2}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleCopy}
        sx={{
          cursor: "pointer",
          bgcolor: isCopied
            ? copiedColor
            : isHovered
              ? "action.hover"
              : "transparent",
          transition: "background-color 0.2s",
          borderRadius: 1,
          p: 1,
          position: "relative",
        }}
      >
        <Typography variant="h6" fontWeight={400} component="div">
          {sectionTitle}
        </Typography>
        {sectionDescription && (
          <Tooltip title={sectionDescription}>
            <InfoIcon
              fontSize="small"
              sx={{ width: 15, opacity: 0.5, ml: 1 }}
            />
          </Tooltip>
        )}
        {(isHovered || isCopied) && (
          <ContentCopyIcon fontSize="small" sx={{ height: "15px", ml: 1 }} />
        )}
        <Fade in={isCopied} timeout={40}>
          <Typography
            variant="caption"
            sx={{
              position: "absolute",
              right: 8,
              bgcolor: copiedColor,
              px: 1,
              py: 0.5,
              borderRadius: 1,
            }}
          >
            Copied!
          </Typography>
        </Fade>
      </Box>
      {sectionContent !== undefined && !awaitingRevision ? (
        <TextField
          multiline
          fullWidth
          color="secondary"
          value={localContent}
          onChange={handleChange}
          sx={{ mb: 2 }}
        />
      ) : (
        <Skeleton variant="rounded" height={118} animation="pulse" />
      )}
    </>
  );
};

export default DynamicNoteSectionEditor;
