import React, { useMemo } from "react";
import { TextField, MenuItem, Typography, Link } from "@mui/material";
import { useUser } from "../contexts/UserContext"; // Adjust the import path as needed
import { useNavigate } from "react-router-dom"; // Add this import

interface TemplateSelectorProps {
  selectedTemplate: string;
  handleTemplateChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  isTemplateSelectLocked: boolean;
  width?: string | number;
}

const TemplateSelector: React.FC<TemplateSelectorProps> = ({
  selectedTemplate,
  handleTemplateChange,
  isTemplateSelectLocked,
  width,
}) => {
  const { userData } = useUser();
  const navigate = useNavigate(); // Add this line

  const allTemplates = useMemo(() => {
    const defaultSoapTemplate = {
      id: "soap",
      name: "SOAP",
      ownerType: "system" as const,
      description:
        "Standard SOAP (Subjective, Objective, Assessment, Plan) note template",
    };

    return [defaultSoapTemplate, ...(userData?.templates || [])];
  }, [userData?.templates]);

  // If no template is selected, default to the user's default template or 'soap'
  const effectiveSelectedTemplate =
    selectedTemplate || userData?.defaultNoteTemplateId || "soap";

  const handleAddTemplate = (event: React.MouseEvent) => {
    event.preventDefault();
    navigate("/template-library");
  };

  return (
    <TextField
      select
      value={effectiveSelectedTemplate}
      onChange={handleTemplateChange}
      disabled={isTemplateSelectLocked}
      variant="outlined"
      size="small"
      aria-label="Select note template"
      inputProps={{
        "aria-description": "Choose a template for your note",
      }}
      sx={{
        width: width || "100%",
        "& .MuiSelect-select": {
          paddingY: "4px",
          paddingRight: "12px", // Add right padding to prevent text from going behind the arrow
        },
        "& .MuiInputBase-input": {
          fontSize: "0.875rem",
        },
      }}
    >
      {allTemplates.map((template) => (
        <MenuItem
          key={template.id}
          value={template.id}
          aria-label={`${template.name}${template.id === userData?.defaultNoteTemplateId ? " (Default template)" : ""}`}
        >
          <Typography
            variant="body2"
            component="span"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {template.name}
            {template.id === userData?.defaultNoteTemplateId && (
              <span className="sr-only"> (Default)</span>
            )}
          </Typography>
        </MenuItem>
      ))}
      {userData?.templates?.length === 0 && (
        <MenuItem>
          <Link
            component="button"
            variant="body2"
            onClick={handleAddTemplate}
            aria-label="Add a new template"
            sx={{ width: "100%", textAlign: "left" }}
          >
            Add a new template
          </Link>
        </MenuItem>
      )}
    </TextField>
  );
};

export default TemplateSelector;
