export type SupportedLocale =
  | "en-US"
  | "de-DE"
  | "es-ES"
  | "es-MX"
  | "fr-FR"
  | "hi-IN"
  | "it-IT"
  | "ja-JP"
  | "ko-KR"
  | "pt-BR"
  | "zh-CN";

export interface LocaleInfo {
  code: SupportedLocale;
  displayName: string;
  flagEmoji: string;
}

export const supportedLocales: LocaleInfo[] = [
  { code: "en-US", displayName: "English", flagEmoji: "🇺🇸" },
  { code: "de-DE", displayName: "German", flagEmoji: "🇩🇪" },
  { code: "es-ES", displayName: "Spanish (Spain)", flagEmoji: "🇪🇸" },
  { code: "es-MX", displayName: "Spanish (Mexico)", flagEmoji: "🇲🇽" },
  { code: "fr-FR", displayName: "French", flagEmoji: "🇫🇷" },
  { code: "hi-IN", displayName: "Hindi", flagEmoji: "🇮🇳" },
  { code: "it-IT", displayName: "Italian", flagEmoji: "🇮🇹" },
  { code: "ja-JP", displayName: "Japanese", flagEmoji: "🇯🇵" },
  { code: "ko-KR", displayName: "Korean", flagEmoji: "🇰🇷" },
  { code: "pt-BR", displayName: "Brazilian Portuguese", flagEmoji: "🇧🇷" },
  { code: "zh-CN", displayName: "Chinese", flagEmoji: "🇨🇳" },
];

export function isValidLocale(locale: string): locale is SupportedLocale {
  return supportedLocales.some((l) => l.code === locale);
}

export function getLocaleInfo(locale: SupportedLocale): LocaleInfo | undefined {
  return supportedLocales.find((l) => l.code === locale);
}

export function getLocaleDisplayName(locale: SupportedLocale): string {
  const localeInfo = getLocaleInfo(locale);
  return localeInfo ? localeInfo.displayName : locale;
}

export function getLocaleFlagEmoji(locale: SupportedLocale): string {
  const localeInfo = getLocaleInfo(locale);
  return localeInfo ? localeInfo.flagEmoji : "";
}

export function getLocaleDisplayNameWithEmoji(locale: SupportedLocale): string {
  const localeInfo = getLocaleInfo(locale);
  return localeInfo
    ? `${localeInfo.flagEmoji} ${localeInfo.displayName}`
    : locale;
}
