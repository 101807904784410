import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme2";
import App from "./App";
import "./index.css";
import { UserProvider } from "./contexts/UserContext";
import { SignalRProvider } from "./contexts/SignalRContext";
import { HelmetProvider } from "react-helmet-async";
import * as Sentry from "@sentry/react";
import ErrorFallback from "./reusable-components/ErrorFallbackRender";
import config from "./config";

Sentry.init({
  environment: config.ENV,
  release: process.env.BUILD_NUMBER,
  dsn: "https://4c0191e257d690db518ba70b635c0647@o4507975842594816.ingest.us.sentry.io/4508004243210240",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <SignalRProvider>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </SignalRProvider>
        </UserProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
