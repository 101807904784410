import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  LinearProgress,
  Checkbox,
  FormControlLabel,
  Link,
  Autocomplete,
  Alert,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

import Template from "./Template";
import fetchAPI from "../../utilities/fetchAPI";
import ValidateLogin from "./ValidateLogin";

const CLINICAL_SPECIALTIES = [
  "Anesthesiology",
  "Cardiology",
  "Dermatology",
  "Emergency Medicine",
  "Family Medicine",
  "Gastroenterology",
  "Internal Medicine",
  "Neurology",
  "Obstetrics & Gynecology",
  "Otolaryngology",
  "Oncology",
  "Ophthalmology",
  "Orthopedics",
  "Pediatrics",
  "Psychiatry",
  "Pulmonology",
  "Radiology",
  "Surgery",
  "Urology",
  "Behavioral Health",
  "Nursing",
  "Social Work",
  "Palliative Care",
  "Hospice",
];

const SignUp: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const referralCodeFromURL = searchParams.get("ref");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [referralCodeError, setReferralCodeError] = useState<
    string | undefined
  >(undefined);
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    clinicalSpecialties: [] as string[],
    credentials: "",
    referralCode: referralCodeFromURL || "",
  });
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [isSignupComplete, setIsSignupComplete] = useState<boolean>(false);
  const [isReferralCodeLocked, setIsReferralCodeLocked] =
    useState(!!referralCodeFromURL);
  const [showReferralCode, setShowReferralCode] =
    useState<boolean>(!!referralCodeFromURL);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>,
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name as string]: value,
    }));
  };

  const handleSpecialtyChange = (_event: any, newValue: string[]) => {
    setFormData((prev) => ({
      ...prev,
      clinicalSpecialties: newValue,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const missingFields = [];
    if (!formData.email) missingFields.push("Email Address");
    if (!formData.firstName) missingFields.push("First Name");
    if (!formData.lastName) missingFields.push("Last Name");

    if (missingFields.length > 0) {
      setError(
        `Please fill out the following required fields: ${missingFields.join(", ")}`,
      );
      return;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email)) {
      setError("Value must be a valid email address");
      return;
    }
    if (!agreedToTerms) {
      setError("You must agree to the Terms of Service");
      return;
    }

    setError(undefined);
    setReferralCodeError(undefined);
    setIsLoading(true);

    fetchAPI("SignUp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...formData, consentedToTOS: agreedToTerms }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const errorData = await response.json();
          if (response.status === 409) {
            navigate("/login", {
              state: {
                email: formData.email,
                message:
                  "An account with this email already exists. Please sign in.",
              },
            });
            return;
          }
          if (
            response.status === 400 &&
            errorData.error === "Invalid referral code"
          ) {
            setReferralCodeError(errorData.message || "Invalid referral code");
            setIsReferralCodeLocked(false);
            throw new Error(errorData.message || "Invalid referral code");
          }
          throw new Error(errorData.error || "Failed to create account");
        }
        setIsSignupComplete(true);
      })
      .catch((error) => {
        if (!error.message.includes("Invalid referral code")) {
          setError(error.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const textFieldSx = {
    height: { xs: "36px", sm: "42px" },
    "& .MuiInputBase-root": {
      height: { xs: "36px", sm: "42px" },
      fontSize: { xs: "14px", sm: "16px" },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
    },
    mb: 1,
  };

  const labelSx = {
    mt: 2,
    mb: 0,
    pb: 0,
    alignSelf: "flex-start",
    width: "100%",
    textAlign: "left",
  };

  return !isSignupComplete ? (
    <Template
      boxSx={{
        width: {
          md: "600px",
          xs: "100%",
        },
      }}
    >
      <Box component="div">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 3,
          }}
        >
          <Typography variant="body2" color="text.primary" sx={{ mr: 1 }}>
            Already have an account?
          </Typography>
          <Button
            variant="text"
            size="small"
            onClick={() => navigate("/login")}
            disabled={isLoading}
          >
            Sign in
          </Button>
        </Box>

        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          {/* Email Column */}
          <Box>
            <Typography
              variant="body2"
              color="text.primary"
              sx={{ ...labelSx, fontWeight: "600" }}
            >
              Email Address *
            </Typography>
            <TextField
              required
              name="email"
              fullWidth
              variant="outlined"
              value={formData.email}
              onChange={handleInputChange}
              margin="dense"
              sx={textFieldSx}
            />
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
              gap: 2,
            }}
          >
            {/* First Name */}
            <Box>
              <Typography
                variant="body2"
                color="text.primary"
                sx={{ ...labelSx, fontWeight: "600" }}
              >
                First Name *
              </Typography>
              <TextField
                required
                name="firstName"
                fullWidth
                variant="outlined"
                value={formData.firstName}
                onChange={handleInputChange}
                margin="dense"
                sx={textFieldSx}
              />
            </Box>

            {/* Last Name */}
            <Box>
              <Typography
                variant="body2"
                color="text.primary"
                sx={{ ...labelSx, fontWeight: "600" }}
              >
                Last Name *
              </Typography>
              <TextField
                required
                name="lastName"
                fullWidth
                variant="outlined"
                value={formData.lastName}
                onChange={handleInputChange}
                margin="dense"
                sx={textFieldSx}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
              gap: 2,
              mt: 2,
            }}
          >
            <Box>
              <Typography
                variant="body2"
                color="text.primary"
                sx={{ ...labelSx }}
              >
                Clinical Specialty (Optional)
              </Typography>
              <Autocomplete
                multiple
                value={formData.clinicalSpecialties}
                onChange={handleSpecialtyChange}
                options={CLINICAL_SPECIALTIES}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={
                      formData.clinicalSpecialties.length === 0
                        ? "Select Specialties"
                        : ""
                    }
                    variant="standard"
                    margin="dense"
                  />
                )}
                sx={{
                  mb: 1,
                  "& .MuiAutocomplete-input": {
                    height: { xs: "36px", sm: "42px" },
                    fontSize: { xs: "14px", sm: "16px" },
                    padding: "0 !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    minHeight: { xs: "36px", sm: "42px" },
                    height: "auto",
                    padding: "0px 9px !important",
                    borderRadius: "5px",
                  },
                  "& .MuiAutocomplete-endAdornment": {
                    right: "9px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  },
                  "& .MuiAutocomplete-clearIndicator": {
                    padding: "2px",
                  },
                  "& .MuiChip-root": {
                    margin: "2px",
                    height: "24px",
                  },
                }}
                disablePortal
                fullWidth
              />
            </Box>

            <Box>
              <Typography
                variant="body2"
                color="text.primary"
                sx={{ ...labelSx }}
              >
                Credentials (Optional)
              </Typography>
              <TextField
                name="credentials"
                fullWidth
                variant="outlined"
                value={formData.credentials}
                onChange={handleInputChange}
                placeholder="e.g., MD, APRN, RN"
                margin="dense"
                sx={textFieldSx}
              />
            </Box>
          </Box>

          {/* Referral Code Section */}
          {!showReferralCode && !referralCodeFromURL && (
            <Link
              component="button"
              onClick={() => setShowReferralCode(true)}
              sx={{
                mt: 2,
                textAlign: "left",
                width: "100%",
                textDecoration: "none",
                color: "primary.main",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Have a referral code?
            </Link>
          )}

          {(showReferralCode || referralCodeFromURL) && (
            <>
              <Typography variant="body2" color="text.secondary" sx={labelSx}>
                Referral Code (Optional)
              </Typography>
              <TextField
                name="referralCode"
                fullWidth
                variant="outlined"
                value={formData.referralCode}
                onChange={handleInputChange}
                margin="dense"
                sx={{
                  ...textFieldSx,
                  "& .MuiInputBase-root": {
                    ...textFieldSx["& .MuiInputBase-root"],
                    backgroundColor: isReferralCodeLocked
                      ? "rgba(0, 0, 0, 0.04)"
                      : "inherit",
                    cursor: isReferralCodeLocked ? "not-allowed" : "text",
                  },
                  "& .MuiOutlinedInput-input": {
                    color: isReferralCodeLocked
                      ? "rgba(0, 0, 0, 0.38)"
                      : "inherit",
                    cursor: isReferralCodeLocked ? "not-allowed" : "text",
                  },
                }}
                inputProps={{
                  style: {
                    fontFamily: "monospace",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  },
                }}
                InputProps={{
                  readOnly: isReferralCodeLocked,
                }}
              />
            </>
          )}

          {/* Error Display */}
          {error && (
            <Alert
              severity="error"
              sx={{
                mt: 2,
                "& .MuiAlert-message": {
                  width: "100%",
                },
              }}
            >
              {error}
            </Alert>
          )}

          {referralCodeError && (
            <Alert
              severity="error"
              sx={{
                mt: 2,
                "& .MuiAlert-message": {
                  width: "100%",
                },
              }}
            >
              {referralCodeError}
            </Alert>
          )}

          {/* Terms of Service and Submit Button Container */}
          <Box
            sx={{
              mt: 3,
              p: 2,
              border: "1px solid",
              borderColor: "divider",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <FormControlLabel
              sx={{
                alignSelf: "flex-start",
              }}
              control={
                <Checkbox
                  checked={agreedToTerms}
                  onChange={(e) => setAgreedToTerms(e.target.checked)}
                  sx={{ alignSelf: "flex-start" }}
                />
              }
              label={
                <Typography variant="body2" color="text.primary">
                  I agree to the Attend{" "}
                  <Link href="/legal" target="_blank">
                    Terms of Service, Privacy Policy, and BAA
                  </Link>
                </Typography>
              }
            />

            {isLoading && <LinearProgress sx={{ mt: 2 }} />}

            <Button
              type="submit"
              size="large"
              fullWidth
              disableElevation
              variant="contained"
              sx={{
                mt: 2,
                height: { xs: "36px", sm: "42px" },
                fontSize: { xs: "14px", sm: "16px" },
                borderRadius: "3px",
                background: "linear-gradient(45deg, #1565c0, #42a5f5)",
                transition: "all 0.3s ease-in-out",
                boxShadow: "0 0 15px rgba(33, 150, 243, 0.4)",
                "&:hover": {
                  background: "linear-gradient(45deg, #0d47a1, #1976d2)",
                  boxShadow: "0 0 25px rgba(33, 150, 243, 0.6)",
                  transform: "translateY(-1px)",
                },
                "&:active": {
                  transform: "translateY(0)",
                  boxShadow: "0 0 15px rgba(33, 150, 243, 0.4)",
                },
                "&:disabled": {
                  background: "rgba(0, 0, 0, 0.12)",
                  boxShadow: "none",
                },
              }}
              disabled={isLoading || !agreedToTerms}
            >
              Sign Up
            </Button>
          </Box>
        </form>
      </Box>
    </Template>
  ) : (
    <Template
      boxSx={{
        width: {
          sm: "700px",
          xs: "100%",
        },
      }}
    >
      <ValidateLogin email={formData.email} />
      <Button
        fullWidth
        variant="outlined"
        onClick={() => setIsSignupComplete(false)}
        sx={{
          borderRadius: "3px",
        }}
        size="large"
      >
        Send a New One-Time Password
      </Button>
    </Template>
  );
};

export default SignUp;
