// App.tsx
import { Box } from "@mui/material";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./pages/login/Login";
import LandingPage from "./pages/landing/LandingPage";
import { useEffect, useState } from "react";
import ConsentDialog from "./reusable-components/ConsentDialog";
import LoadingCard from "./reusable-components/LoadingCard";
import useMonitorUserConsentAndAccess from "./hooks/useMonitorUserConsentAndAccess";
import UserSettings from "./pages/settings/UserSettings";
import SubscriptionSuccess from "./pages/subscription-success/SubscriptionSuccess";
import SubscriptionSuccessReturningUser from "./pages/subscription-success/SubscriptionSuccessReturningUser";
import FlexibleEncounter from "./pages/encounter/FlexibleEncounter";
import ClinicianTemplateBuilder from "./pages/clinical-note-template-builder/ClinicianTemplateBuilder";
import MainLayout from "./reusable-components/MainLayout";
import TemplateViewer from "./pages/template/templateViewer";
import TemplateLibraryAndManagement from "./pages/template-library/TemplateLibraryV2";
import NotFound from "./reusable-components/NotFoundDisplay";
import EditTemplateWrapper from "./pages/clinical-note-template-editor/EditTemplateWrapper";
import RecentlyDeletedEncounters from "./pages/deleted-encounters/DeletedEncounters";
import EncounterRecorder from "./reusable-components/EncounterRecorder";
import OctaveLogin from "./pages/login/OctaveLogin";
import HealthCheck from "./pages/health-check/HealthCheck";
import { useUser } from "./contexts/UserContext";
import SignUp from "./pages/login/SignUp";
import Legal from "./pages/legal/Legal";
import OnboardingFlow from "./pages/onboarding/OnboardingFlow";

function App() {
  const { isLoggedIn } = useUser();
  const { consentRequired, hasAccess } = useMonitorUserConsentAndAccess();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [consentRequired, hasAccess]);

  return (
    <Router>
      <Box width="100%">
        {isLoggedIn && (
          <>
            {loading && <LoadingCard loadingText="Loading your data" />}
            {!loading && consentRequired && <ConsentDialog />}

            {!loading && !consentRequired && (
              <Routes>
                <Route path="/legal" element={<Legal />} />
                <Route
                  path="/health-check-internal"
                  element={<HealthCheck />}
                />
                <Route element={<MainLayout />}>
                  <Route path="/onboarding" element={<OnboardingFlow />} />
                  <Route
                    path="/edit-note-template/:templateId"
                    element={<EditTemplateWrapper />}
                  />
                  <Route
                    path="/create-note-template"
                    element={<ClinicianTemplateBuilder />}
                  />
                  <Route path="/settings" element={<UserSettings />} />
                  <Route path="/" element={<EncounterRecorder />} />
                  <Route
                    path="/encounter/:encounterId"
                    element={<FlexibleEncounter />}
                  />
                  <Route
                    path="/template/:templateId"
                    element={<TemplateViewer />}
                  />
                  <Route
                    path="/template-library"
                    element={<TemplateLibraryAndManagement />}
                  />
                  <Route
                    path="/deleted-encounters"
                    element={<RecentlyDeletedEncounters />}
                  />
                  <Route
                    path="*"
                    element={
                      <NotFound
                        itemType="Page"
                        message="The page you are looking for doesn't exist. It might have been removed, had its name changed, or is temporarily unavailable."
                        backUrl="/"
                        backText="Go back"
                      />
                    }
                  />
                </Route>
                <Route path="/landing" element={<LandingPage />} />
                <Route
                  path="/demo-encounter/:encounterId"
                  element={<Navigate to="/" />}
                />
                <Route path="/login" element={<Navigate to="/" />} />
                <Route path="/signup" element={<Navigate to="/" />} />
                <Route
                  path="/login/validate/:token?"
                  element={<Navigate to="/" />}
                />
                <Route path="/octave-login" element={<Navigate to="/" />} />
                <Route path="/demo" element={<Navigate to="/" />} />
                <Route
                  path="/subscription-success"
                  element={<SubscriptionSuccessReturningUser />}
                />
              </Routes>
            )}
          </>
        )}
        {!isLoggedIn && (
          <Routes>
            <Route path="/legal" element={<Legal />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/health-check-internal" element={<HealthCheck />} />
            <Route
              path="/subscription-success"
              element={<SubscriptionSuccess />}
            />
            <Route path="/settings" element={<Navigate to="/login" />} />
            <Route
              path="/edit-note-template/:templateId"
              element={<Navigate to="/login" />}
            />
            <Route
              path="/create-note-template"
              element={<Navigate to="/login" />}
            />
            <Route path="/settings" element={<Navigate to="/login" />} />
            <Route
              path="/encounter/:encounterId"
              element={<Navigate to="/login" />}
            />
            <Route
              path="/template/:templateId"
              element={<Navigate to="/login" />}
            />
            <Route
              path="/template-library"
              element={<Navigate to="/login" />}
            />
            <Route
              path="/deleted-encounters"
              element={<Navigate to="/login" />}
            />

            <Route path="/" element={<LandingPage />} />
            <Route path="/demo" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/login/validate/:token?"
              element={<Navigate to="/login" />}
            />
            <Route path="/octave-login" element={<OctaveLogin />} />
            <Route
              path="*"
              element={
                <NotFound
                  itemType="Page"
                  message="The page you are looking for doesn't exist. It might have been removed, had its name changed, or is temporarily unavailable."
                  backUrl="/"
                  backText="Go to Homepage"
                />
              }
            />
          </Routes>
        )}
      </Box>
    </Router>
  );
}

export default App;
