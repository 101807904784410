import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";

interface NoteRevisionInputProps {
  open: boolean;
  onSendInstruction: (instruction: string) => void;
  onClose: () => void;
}

const NoteRevisionInput: React.FC<NoteRevisionInputProps> = ({
  open,
  onSendInstruction,
  onClose,
}) => {
  const [instruction, setInstruction] = useState("");

  const handleInstructionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setInstruction(event.target.value);
  };

  const handleSendInstruction = () => {
    onSendInstruction(instruction);
    setInstruction("");
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-start",
          "& .MuiPaper-root": {
            marginTop: "64px",
          },
        },
      }}
    >
      <DialogTitle>Revise Note</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          helperText="Additional details or edits you'd like to be applied. For example, you can note the patient's appearance, or change the style of the note"
          fullWidth
          multiline
          rows={4}
          value={instruction}
          onChange={handleInstructionChange}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSendInstruction}
          color="primary"
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NoteRevisionInput;
