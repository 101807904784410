import React from "react";
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Button,
  Divider,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import fetchAPI from "../../utilities/fetchAPI";
import config from "../../config";

interface PricingProps {
  handleSignUp: () => void;
}

const Pricing: React.FC<PricingProps> = ({ handleSignUp }) => {
  const handleCheckout = async () => {
    const priceId = config.ATTEND_PRICE_ID;
    try {
      const response = await fetchAPI("CreateAttendProCheckoutSession", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ priceId }),
      });

      if (response.ok) {
        const data = await response.json();
        window.location.href = data.url; // Redirect to Stripe checkout page
      } else {
        console.error("Failed to create checkout session");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  interface PricingCardProps {
    title: string;
    price: string;
    features: string[];
    buttonText: string;
    highlighted?: boolean;
    onClick: () => void;
  }

  const PricingCard: React.FC<PricingCardProps> = ({
    title,
    price,
    features,
    buttonText,
    onClick,
    highlighted = false,
  }) => (
    <Paper
      elevation={highlighted ? 6 : 3}
      sx={{
        p: 4,
        height: "100%",
        maxWidth: 350,
        textAlign: "center",
        mx: "auto",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflow: "hidden",
        borderRadius: 4,
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        "&:hover": {
          boxShadow: 6,
        },
      }}
    >
      {highlighted && (
        <Box
          sx={{
            position: "absolute",
            top: 20,
            right: -35,
            transform: "rotate(45deg)",
            backgroundColor: "#4CAF50",
            color: "white",
            padding: "5px 40px",
          }}
        >
          Best Value
        </Box>
      )}
      <Typography
        variant="h6"
        gutterBottom
        color="text.secondary"
        fontWeight="normal"
      >
        {title}
      </Typography>
      <Typography
        variant="h3"
        component="p"
        gutterBottom
        fontWeight="bold"
        color="primary"
      >
        {price}
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          mt: 2,
          mb: 2,
        }}
      >
        {features.map((feature, index) => (
          <Box
            key={index}
            sx={{ display: "flex", alignItems: "center", mb: 1 }}
          >
            <CheckCircleOutlineIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="body1" align="left">
              {feature}
            </Typography>
          </Box>
        ))}
      </Box>
      <Button
        variant={highlighted ? "contained" : "outlined"}
        color="primary"
        onClick={onClick}
        sx={{ mt: "auto", py: 1.5 }}
        fullWidth
        size="large"
      >
        {buttonText}
      </Button>
    </Paper>
  );

  return (
    <Box
      sx={{
        py: 8,
        background: "linear-gradient(135deg, #A0C8F3 0%, #ffffff 100%)",
      }}
    >
      <Container>
        <Typography
          variant="h3"
          component="h2"
          color="#101A2F"
          gutterBottom
          textAlign="center"
          fontWeight="bold"
          mb={6}
        >
          Choose Your Plan
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={4}>
            <PricingCard
              title="Free"
              price="$0"
              features={["15 free encounters"]}
              buttonText="Sign Up"
              onClick={handleSignUp}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <PricingCard
              title="Pro"
              price="$89"
              features={["Unlimited encounters", "30-day money-back guarantee"]}
              buttonText="Subscribe"
              onClick={handleCheckout}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <PricingCard
              title="Group / Practice"
              price="Contact Us"
              features={["Organizational discounts", "Custom integration"]}
              buttonText="Get in Touch"
              onClick={() =>
                (window.location.href = "mailto:info@crafthealthtech.com")
              }
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Pricing;
