import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Chip,
  Card,
  CardContent,
  Snackbar,
  Alert,
  Stack,
  Divider,
} from "@mui/material";
import { useUser } from "../../contexts/UserContext";
import { checkoutRedirect } from "../../utilities/checkoutRedirect";
import { portalRedirect } from "../../utilities/portalRedirect";
import { useNavigate } from "react-router-dom";
import LoadingCard from "../../reusable-components/LoadingCard";
import EditUserData from "../../reusable-components/EditUserData";
import config from "../../config";

interface UserSettingsProps {}

const UserSettings: React.FC<UserSettingsProps> = () => {
  const { userData, fetchUserData } = useUser();
  const [stripeCustomerId, setStripeCustomerId] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const loadUserData = async () => {
      setIsLoading(true);
      await fetchUserData();
      setIsLoading(false);
    };
    loadUserData();
  }, []);

  useEffect(() => {
    if (userData) {
      setStripeCustomerId(userData.stripeCustomerId || "");
    }
  }, [userData]);

  const handleSubscribe = () => {
    const priceId = config.ATTEND_PRICE_ID;
    if (stripeCustomerId) {
      checkoutRedirect(priceId, stripeCustomerId);
    } else {
      checkoutRedirect(priceId);
    }
  };

  const handlePortal = () => {
    if (stripeCustomerId) {
      portalRedirect(stripeCustomerId);
    } else {
      console.error("No customer ID");
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const renderContent = () => (
    <Card variant="outlined" sx={{ margin: 2, maxWidth: 800 }}>
      <CardContent>
        <Typography
          variant="h5"
          component="h1"
          gutterBottom
          sx={{ color: "black", mb: 2, fontWeight: "medium" }}
        >
          Account
        </Typography>
        <Divider sx={{ mb: 2 }} />
        {userData && !userData.activeSubscriptionAttendPro && (
          <Stack spacing={1} sx={{ mb: 2 }}>
            <Typography>Subscription Tier: Free </Typography>
            <Typography variant="caption">{`${userData.encounterCount} of ${userData.freeEncounterQuota} encounters used`}</Typography>
            <Button
              variant="contained"
              color="success"
              disableElevation
              size="small"
              onClick={handleSubscribe}
              sx={{ width: "fit-content" }}
            >
              Subscribe
            </Button>
          </Stack>
        )}
        {userData && userData.activeSubscriptionAttendPro && (
          <Stack spacing={1} sx={{ mb: 2 }}>
            <Box display="flex" alignItems="center">
              <Typography>Subscription</Typography>
              <Chip label="Pro" color="success" sx={{ ml: 1, height: 20 }} />
            </Box>
            <Typography variant="body2">
              Thanks for supporting Attend!
            </Typography>
            <Button variant="outlined" size="small" onClick={handlePortal}>
              Manage my subscription
            </Button>
          </Stack>
        )}
        <Divider sx={{ mb: 2 }} />
        <Typography
          variant="h6"
          gutterBottom
          sx={{ color: "black", mb: 2, fontWeight: "medium" }}
        >
          Data Retention
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Encounters will be deleted after 7 days. You can restore encounters
          deleted within the last 3 days.
        </Typography>
        <Button
          variant="text"
          color="primary"
          size="small"
          onClick={() => navigate("/deleted-encounters")}
          sx={{ mb: 2, fontWeight: "bold" }}
        >
          Restore deleted encounters
        </Button>
        <Divider sx={{ mb: 2 }} />
        <Typography
          variant="h6"
          gutterBottom
          sx={{ color: "black", mb: 2, fontWeight: "medium" }}
        >
          Note Templates
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Set up templates to control how Attend formats notes.
        </Typography>
        <Button
          onClick={() => {
            navigate(`/template-library`);
          }}
          variant="contained"
          disableElevation
          color="secondary"
          sx={{ mt: 2, width: "fit-content" }}
        >
          Manage Templates
        </Button>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6" sx={{ fontWeight: "medium" }}>
          User Settings
        </Typography>
        {userData && <EditUserData userData={userData} />}
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <LoadingCard loadingText="Loading Settings" />
        </Box>
      ) : (
        renderContent()
      )}

      {/*@todo: Move to global scope*/}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          variant="filled"
          severity="success"
          sx={{ width: "100%" }}
        >
          Settings saved successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserSettings;
