import { createTheme } from "@mui/material/styles";
import "@fontsource/open-sans";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#3c8cff",
      light: "#A0c8F3",
      dark: "#101A2F",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#E8EBF1",
      contrastText: "#03131E",
    },
    background: {
      default: "#E8EBF1",
      paper: "#F7F8FA",
    },
    text: {
      primary: "#03131E",
      secondary: "#101A2F",
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          padding: "4px 10px",
        },
      },
    },
    MuiListItemText: {},
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        color: "secondary",
      },
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#3c8cff",
              borderWidth: "2px",
              backgroundColor: "",
            },
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#3c8cff",
          },
        },
      },
    },
  },
  shape: {
    borderRadius: 15,
  },
  typography: {
    fontFamily: "SF Pro Display, sans-serif",
    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "0.8rem",
    },
    h1: {
      fontWeight: 500, // Bold
      // Add any other styles specific to h1
    },
    h2: {
      fontWeight: 400, // Regular
      // Add any other styles specific to h2
    },
    h3: {
      fontWeight: 300, // Bold
      // Add any other styles specific to h3
    },
    h4: {
      fontWeight: 400, // Regular
      // Add any other styles specific to h4
    },
    h5: {
      fontWeight: 300, // s
      // Add any other styles specific to h5
    },
    h6: {
      fontWeight: 200, // Regular
      // Add any other styles specific to h6
    },
    subtitle1: {
      fontWeight: 300, // Light
      // Add any other styles specific to subtitle1
    },
    subtitle2: {
      fontWeight: 200, // Thin
      // Add any other styles specific to subtitle2
    },
    caption: {
      fontWeight: 200, // Thin
      // Add any other styles specific to caption
    },
  },
});

export default theme;
