function getEnv() {
  const url = window.location.hostname;

  const prodUrls = [
    "attend.health",
    "www.attend.health",
    "wonderful-sand-07bfffe0f.5.azurestaticapps.net",
  ];
  if (prodUrls.includes(url)) {
    return "prod";
  }

  const devTestUrls = [
    "dev-test.attend.health",
    "nice-desert-0ce796d0f.5.azurestaticapps.net",
  ];
  if (devTestUrls.includes(url)) {
    return "devTest";
  }

  return "local";
}

function getConfig() {
  const env = getEnv();
  return {
    prod: {
      ENV: env,
      FUNCTIONS_ENDPOINT:
        "https://cht-encounter-transcription.azurewebsites.net/api",
      ATTEND_PRICE_ID: "price_1PLXVTP2Q3szDsArVQmdx96f",
      REDIRECT_URI: "https://www.attend.health/",
      OKTA_ISSUER: "https://octave.okta.com",
      OKTA_CLIENT_ID: "0oa1s6mfuylFU7upL1d8",
    },
    devTest: {
      ENV: env,
      FUNCTIONS_ENDPOINT: "https://cht-dev-test.azurewebsites.net/api",
      ATTEND_PRICE_ID: "price_1PRIiRP2Q3szDsArkPMVcLc9",
      REDIRECT_URI: "https://nice-desert-0ce796d0f.5.azurestaticapps.net/",
      OKTA_ISSUER: "https://dev-43659044.okta.com/oauth2/default",
      OKTA_CLIENT_ID: "0oakd8jh9tQZjLBBP5d7",
    },
    // Allow providing different values locally without editing this file
    local: {
      ENV: env,
      FUNCTIONS_ENDPOINT: import.meta.env.VITE_FUNCTIONS_ENDPOINT,
      ATTEND_PRICE_ID: import.meta.env.VITE_ATTEND_PRICE_ID,
      REDIRECT_URI: import.meta.env.VITE_REDIRECT_URI,
      OKTA_ISSUER: import.meta.env.VITE_OKTA_ISSUER,
      OKTA_CLIENT_ID: import.meta.env.VITE_OKTA_CLIENT_ID,
    },
  }[env];
}

const config = getConfig();
export default config;
