import React, { useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { IEncounter } from "./Sidebar";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeOutlined";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
  DialogContentText,
} from "@mui/material";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";

import { useUser } from "../contexts/UserContext";
import {
  FileText,
  Mail,
  Settings,
  Trash2,
  LogOut,
  PlusCircle,
} from "lucide-react";

const isNearDeletion = (autoRemoveAt?: string) => {
  if (!autoRemoveAt) return false;
  const removalDate = new Date(autoRemoveAt);
  const now = new Date();
  const daysDifference =
    (removalDate.getTime() - now.getTime()) / (1000 * 3600 * 24);
  return daysDifference <= 3;
};

const formatAutoRemoveDate = (autoRemoveAt?: string) => {
  if (!autoRemoveAt) return "";
  const removalDate = new Date(autoRemoveAt);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return removalDate.toLocaleDateString(undefined, options);
};

const getDaysUntilRemoval = (autoRemoveAt?: string) => {
  if (!autoRemoveAt) return 0;
  const removalDate = new Date(autoRemoveAt);
  const now = new Date();
  return Math.ceil(
    (removalDate.getTime() - now.getTime()) / (1000 * 3600 * 24),
  );
};

const calculateDuration = (totalDurationInSeconds: number) => {
  const durationInMinutes = Math.round(totalDurationInSeconds / 60);
  return durationInMinutes;
};

// EncounterListItem Component
const EncounterListItem: React.FC<{
  encounter: IEncounter;
  isActive: boolean;
  onDelete: (id: string) => void;
  isDeleting: boolean;
}> = ({ encounter, isActive, onDelete, isDeleting }) => {
  const [isHovered, setIsHovered] = useState(false);
  const duration = calculateDuration(encounter.totalDurationInSeconds || 0);

  // Format the date in a more readable way
  const formattedDate = new Date(encounter.startTime).toLocaleString(
    undefined,
    {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
    },
  );

  // Create a more descriptive label for screen readers
  const encounterLabel = `${encounter.title || "Untitled Encounter"} from ${formattedDate}${
    encounter.status === "in progress" ? ", In progress" : ""
  }${duration > 0 ? `, ${duration} minutes long` : ""}`;

  return (
    <ListItem
      sx={{
        pl: 4,
        textDecoration: "none",
        color: "inherit",
        backgroundColor: isActive ? "rgba(0, 0, 0, 0.08)" : "inherit",
        "&:hover": {
          backgroundColor: isActive
            ? "rgba(0, 0, 0, 0.12)"
            : "rgba(0, 0, 0, 0.04)",
        },
        position: "relative",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      role="listitem"
      aria-current={isActive ? "page" : undefined}
    >
      <ListItemText
        primary={
          <Link
            to={`/encounter/${encounter.id}`}
            style={{ textDecoration: "none", color: "inherit" }}
            aria-label={encounterLabel}
          >
            <Typography fontWeight={isActive ? 600 : 400} variant="body1">
              {encounter.title || "Untitled Encounter"}
            </Typography>
          </Link>
        }
        secondary={
          // Hide this visual content from screen readers since we included it in the link's aria-label
          <Typography
            variant="caption"
            sx={{ display: "inline-flex", alignItems: "center" }}
            aria-hidden="true"
          >
            {formattedDate}
            {encounter.autoRemoveAt &&
            isNearDeletion(encounter.autoRemoveAt) ? (
              <Tooltip
                title={`This encounter will be automatically removed on ${formatAutoRemoveDate(
                  encounter.autoRemoveAt,
                )} (in ${getDaysUntilRemoval(encounter.autoRemoveAt)} days)`}
                arrow
              >
                <AccessTimeFilledIcon
                  sx={{ fontSize: "1rem", ml: 1, color: "warning.main" }}
                />
              </Tooltip>
            ) : encounter.status === "completed" ? (
              <Tooltip
                title={
                  duration > 0
                    ? `Duration: ${duration} minutes`
                    : `Completed ${formatAutoRemoveDate(encounter.endTime)}`
                }
                arrow
              >
                <Typography variant="body2" sx={{ ml: 1 }}>
                  {duration > 0 ? `${duration} min` : ``}
                </Typography>
              </Tooltip>
            ) : (
              <Tooltip title="In progress" arrow>
                <PauseCircleOutlineIcon
                  sx={{ fontSize: "1rem", ml: 1, color: "info.main" }}
                />
              </Tooltip>
            )}
          </Typography>
        }
      />
      {(isHovered || isDeleting) && (
        <div
          style={{
            position: "absolute",
            right: "16px",
            top: "50%",
            transform: "translateY(-50%)",
            transition: "opacity 0.3s",
            opacity: isHovered || isDeleting ? 1 : 0,
          }}
        >
          {isDeleting ? (
            <CircularProgress size={24} />
          ) : (
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onDelete(encounter.id);
              }}
              edge="end"
              aria-label="delete"
              sx={{ fontSize: ".7rem" }}
            >
              <Trash2 strokeWidth={1} />
            </IconButton>
          )}
        </div>
      )}
    </ListItem>
  );
};

// EncounterList Component
const EncounterList: React.FC<{
  encounters: IEncounter[];
  currentPath: string;
  onDeleteEncounter: (id: string) => void;
  deletingEncounters: Set<string>;
}> = ({ encounters, currentPath, onDeleteEncounter, deletingEncounters }) => {
  return (
    <List component="div" disablePadding sx={{ overflowY: "auto" }}>
      {encounters.length === 0 ? (
        <ListItem
          sx={{
            pl: 4,
            alignContent: "center",
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <ListItemText
            primary={
              <Typography variant="body2" sx={{ alignItems: "center" }}>
                No encounters
              </Typography>
            }
          />
        </ListItem>
      ) : (
        encounters.map((encounter) => (
          <React.Fragment key={encounter.id}>
            <Divider />
            <EncounterListItem
              encounter={encounter}
              isActive={currentPath === `/encounter/${encounter.id}`}
              onDelete={onDeleteEncounter}
              isDeleting={deletingEncounters.has(encounter.id)}
            />
          </React.Fragment>
        ))
      )}
    </List>
  );
};

interface DrawerContentProps {
  encounters: IEncounter[];
  handleCreateEncounter: () => void;
  handleDeleteEncounter: (encounterId: string) => void;
  handleDeleteAllEncounters: () => void;
  loading: boolean;
  userEmail: string | undefined;
  logout: () => void;
  deletingEncounters: Set<string>;
  currentPath: string;
}

const DrawerContent: React.FC<DrawerContentProps> = ({
  encounters,
  handleCreateEncounter,
  handleDeleteEncounter,
  handleDeleteAllEncounters,
  loading,
  userEmail,
  logout,
  deletingEncounters,
  currentPath,
}) => {
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const { fetchWithAuth } = useUser();
  const [deleteAllDialogOpen, setDeleteAllDialogOpen] = useState(false);

  const handleContactDialogOpen = () => {
    setContactDialogOpen(true);
  };

  const handleContactDialogClose = () => {
    setContactDialogOpen(false);
    setFeedbackMessage("");
  };

  const handleFeedbackSubmit = async () => {
    setIsSubmitting(true);
    setSubmitError(null);

    try {
      const response = await fetchWithAuth("SendUserFeedback", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          feedback: feedbackMessage,
          userEmail: userEmail,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Feedback submitted successfully
      setContactDialogOpen(false);
      setFeedbackMessage("");
      // You might want to show a success message to the user here
    } catch (error) {
      console.error("Error submitting feedback:", error);
      setSubmitError("Failed to submit feedback. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteAllDialogOpen = () => {
    setDeleteAllDialogOpen(true);
  };

  const handleDeleteAllDialogClose = () => {
    setDeleteAllDialogOpen(false);
  };

  const handleConfirmDeleteAll = () => {
    handleDeleteAllEncounters();
    setDeleteAllDialogOpen(false);
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      role="navigation"
      aria-label="Encounter Navigation"
    >
      <Box sx={{ flexGrow: 0 }}>
        <Typography
          variant="h4"
          align="center"
          sx={{
            mt: 2,
            mb: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="/attend.svg"
            alt="Attend Icon"
            width="40"
            height="40"
            style={{ marginRight: "4px" }}
          />
          Attend
        </Typography>
        <Divider />
        <Typography variant="body1" align="center" sx={{ mt: 2, mb: 1 }}>
          Logged in as{" "}
          {userEmail ? (
            userEmail
          ) : (
            <LinearProgress sx={{ width: "100%", ml: 1, mt: 1 }} />
          )}
        </Typography>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            disableElevation
            startIcon={<PlusCircle size={24} />}
            fullWidth
            size="large"
            onClick={handleCreateEncounter}
            sx={{ fontWeight: "bold" }}
          >
            New Encounter
          </Button>
        </Box>
        <List>
          <ListItem disablePadding>
            <ListItemText primary="Previous Encounters" sx={{ pl: 2 }} />
          </ListItem>
        </List>
      </Box>
      <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
        {loading ? (
          <Box>
            <Typography variant="h6" sx={{ pl: 4, pt: 2, pb: 2 }}>
              Loading Encounters ...
            </Typography>
            <LinearProgress></LinearProgress>
          </Box>
        ) : (
          <Box>
            <Typography
              variant="body2"
              sx={{
                pl: 4,
                pt: 1,
                pb: 0,
                pr: 2,
                fontStyle: "italic",
                color: "text.secondary",
              }}
            >
              Attend saves notes for 1 week
            </Typography>
            <Typography
              variant="body2"
              sx={{
                pl: 4,
                pt: 1,
                pb: 1,
                pr: 2,
                fontStyle: "italic",
                color: "text.secondary",
              }}
            >
              Please copy them to your EHR
            </Typography>
            <EncounterList
              encounters={encounters}
              currentPath={currentPath}
              onDeleteEncounter={handleDeleteEncounter}
              deletingEncounters={deletingEncounters}
            />
          </Box>
        )}
      </Box>
      <Box sx={{ flexGrow: 0 }}>
        <Divider />
        <List dense>
          <ListItem>
            <ListItemButton component={Link} to="/template-library">
              <ListItemIcon>
                <FileText strokeWidth={1.5} />
              </ListItemIcon>
              <ListItemText primary="Manage Templates" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={handleContactDialogOpen}>
              <ListItemIcon>
                <Mail strokeWidth={1.5} />
              </ListItemIcon>
              <ListItemText primary="Contact Us" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton component={Link} to="/settings">
              <ListItemIcon>
                <Settings strokeWidth={1.5} />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={handleDeleteAllDialogOpen}>
              <ListItemIcon>
                <Trash2 strokeWidth={1.5} />
              </ListItemIcon>
              <ListItemText primary="Delete All Encounters" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={logout}>
              <ListItemIcon>
                <LogOut strokeWidth={1.5} />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>

      <Dialog
        open={contactDialogOpen}
        onClose={handleContactDialogClose}
        aria-labelledby="contact-dialog-title"
        aria-describedby="contact-dialog-description"
      >
        <DialogTitle id="contact-dialog-title" fontWeight={500}>
          Contact Us
        </DialogTitle>
        <DialogContent>
          <Typography id="contact-dialog-description">
            Reach out here if you need support, or if you have feedback about
            how to make Attend better for you.
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            id="feedback"
            type="text"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={feedbackMessage}
            onChange={(e) => setFeedbackMessage(e.target.value)}
            disabled={isSubmitting}
            aria-label="Feedback Message"
          />
          {submitError && (
            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
              {submitError}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleContactDialogClose} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            onClick={handleFeedbackSubmit}
            variant="contained"
            color="primary"
            disabled={isSubmitting || !feedbackMessage.trim()}
          >
            {isSubmitting ? "Sending..." : "Send Feedback"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteAllDialogOpen}
        onClose={handleDeleteAllDialogClose}
        aria-labelledby="delete-all-dialog-title"
        aria-describedby="delete-all-dialog-description"
      >
        <DialogTitle id="delete-all-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-all-dialog-description">
            Are you sure you want to delete all encounters?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disableElevation onClick={handleDeleteAllDialogClose}>
            Cancel
          </Button>
          <Button
            disableElevation
            onClick={handleConfirmDeleteAll}
            color="error"
            variant="contained"
          >
            Delete All
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DrawerContent;
