import React, {
  createContext,
  useContext,
  useRef,
  ReactNode,
  useCallback,
  useState,
  useEffect,
} from "react";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";
import { IEncounterDocumentResponse } from "../models/templates";
import { SupportedLocale } from "../models/localeTypes";
import { useUser } from "./UserContext";
import * as Sentry from "@sentry/react";

export interface EncounterData {
  encounterId: string;
  title: string;
  status: string;
  patientDetails: string;
  startTime: string;
  endTime: string;
  lastUpdated: string;
  autoRemoveAt?: string;
  subjective: string;
  objective: string;
  assessmentAndPlan: string;
  transcript: string;
  patientInstructions: string;
}

export interface NoteTemplate {
  name: string;
  description?: string;
  sections: {
    title: string;
    description?: string;
  }[];
}

export interface NoteContent {
  [sectionTitle: string]: {
    content: string;
    isAIPrefilled?: boolean;
    userInteraction?: {
      status: "approved" | "edited" | "untouched";
      userId?: string;
      timestamp?: string;
    };
    aiPrefillReference?: {
      transcriptExcerpt: string;
    };
  };
}

export interface FlexibleEncounterData {
  encounterId: string;
  title: string;
  status: string;
  patientDetails: string;
  startTime: string;
  endTime: string;
  autoRemoveAt?: string;
  totalDurationInSeconds: number;
  lastTranscriptPart: number;
  noteTemplate: NoteTemplate;
  noteTemplateVersion: number;
  noteTemplateId: string;
  noteContent: NoteContent;
  transcript: string;
  patientInstructions: string;
  noteStatus?: {
    status:
      | "queued"
      | "completed"
      | "failed"
      | "awaitingRevision"
      | "abortedDueToShortTranscript";
    timestamp: string;
  };
  patientInstructionsStatus?: {
    status: "queued" | "completed" | "failed" | "abortedDueToShortTranscript";
    timestamp: string;
  };
  errors?: IProcessingError[];
  encounterDocuments?: IEncounterDocument[];
  locale?: SupportedLocale;
}

export interface IEncounterDocument {
  id: string;
  name: string;
  templateId: string;
  templateVersion?: number;
  createdAt: string;
  status: "queued" | "completed" | "failed" | "awaitingRevision";
  content?: IEncounterDocumentResponse;
  // Add other necessary fields
}

export interface IProcessingError {
  stage:
    | "transcription"
    | "documentation"
    | "patientInstructions"
    | "medicationCorrection"
    | "other";
  message: string;
  timestamp: string;
  affectedParts?: number[]; // For transcription errors, to track which parts were affected
}

export interface SignalRContextType {
  connection: HubConnection | null;
  sendMessage: (message: string) => void;
  addEncounterUpdateListener: (
    key: string,
    callback: (data: FlexibleEncounterData[]) => void,
  ) => void;
  removeEncounterUpdateListener: (
    key: string,
    callback: (data: FlexibleEncounterData[]) => void,
  ) => void;
}

const SignalRContext = createContext<SignalRContextType | undefined>(undefined);

export const SignalRProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const connectionRef = useRef<HubConnection | null>(null);
  const listenersRef = useRef<
    Map<string, Set<(data: FlexibleEncounterData[]) => void>>
  >(new Map());
  const [isConnecting, setIsConnecting] = useState(false);
  const { fetchWithAuth, isLoggedIn, userData } = useUser();
  const cachedTokenRef = useRef<{
    token: string | null;
    expirationTime: number | null;
  }>({ token: null, expirationTime: null });
  // Get the SignalR access token for the given user ID
  const getAccessToken = useCallback(
    async (userId: string) => {
      /*if (cachedTokenRef.current.expirationTime) {
        console.log(
          "getAccessToken: found cached token with expiration time ",
          new Date(cachedTokenRef.current.expirationTime).toLocaleString(),
        );
      }*/

      if (
        cachedTokenRef.current.token &&
        cachedTokenRef.current.expirationTime &&
        Date.now() < cachedTokenRef.current.expirationTime
      ) {
        Sentry.addBreadcrumb({
          category: "signalr getAccessToken",
          message: "Returning cached token",
          data: {
            expirationTime: new Date(
              cachedTokenRef.current.expirationTime,
            ).toLocaleString(),
          },
          level: "info",
        });

        return cachedTokenRef.current.token;
      }

      try {
        const response = await fetchWithAuth("negotiate", {
          method: "POST",
          headers: { "Negotiate-User-Id": userId },
        });
        const connectionInfo = await response.json();

        // Cache the new token
        cachedTokenRef.current.token = connectionInfo.accessToken;

        // Set expiration time (e.g., 50 minutes from now to refresh before the 1-hour expiration)
        cachedTokenRef.current.expirationTime = Date.now() + 50 * 60 * 1000;

        Sentry.addBreadcrumb({
          category: "signalr getAccessToken",
          message: "New signalR access token cached",
          data: {
            expirationTime: new Date(
              cachedTokenRef.current.expirationTime,
            ).toLocaleString(),
          },
          level: "info",
        });

        return connectionInfo.accessToken;
      } catch (error) {
        console.error("Failed to get SignalR access token:", error);
        Sentry.captureException(error, {
          tags: {
            context: "Get SignalR Access Token",
          },
        });
        throw error;
      }
    },
    [fetchWithAuth],
  );

  const startSignalRConnection = useCallback(async (userId: string) => {
    if (
      connectionRef.current?.state === HubConnectionState.Connected ||
      isConnecting
    ) {
      console.log(
        "SignalR Connection is already connected or in progress, not creating a new one",
      );
      return;
    }

    setIsConnecting(true);

    try {
      const response = await fetchWithAuth("negotiate", {
        method: "POST",
        headers: { "Negotiate-User-Id": userId },
      });
      const connectionInfo = await response.json();
      // Set expiration time (e.g., 50 minutes from now to refresh before the 1-hour expiration)
      cachedTokenRef.current.expirationTime = Date.now() + 50 * 60 * 1000;

      // Cache the new token
      cachedTokenRef.current.token = connectionInfo.accessToken;

      Sentry.addBreadcrumb({
        category: "signalr startConnection",
        message: "New signalR access token cached",
        data: {
          expirationTime: new Date(
            cachedTokenRef.current.expirationTime,
          ).toLocaleString(),
        },
        level: "info",
      });

      const newConnection = new HubConnectionBuilder()
        .withUrl(connectionInfo.url, {
          accessTokenFactory: () => getAccessToken(userId),
        })
        .withAutomaticReconnect()
        .build();

      newConnection.on(
        "updateEncounterOutcome",
        (data: FlexibleEncounterData[]) => {
          // console.log('Received updateEncounterOutcome:', data);
          listenersRef.current.forEach((listeners, _) => {
            // console.log(`Notifying listeners for key: ${key}`);
            listeners.forEach((callback) => callback(data));
          });
        },
      );

      newConnection.onclose((error) => {
        if (error) {
          console.error("SignalR connection closed with error:", error);
          Sentry.captureException(
            new Error("SignalR connection closed with error"),
            {
              extra: {
                error: error,
              },
            },
          );
        } else {
          console.log("SignalR connection closed");
          Sentry.addBreadcrumb({
            category: "signalr",
            message: "SignalR connection closed",
            level: "info",
          });
        }
      });

      newConnection.onreconnecting((error) => {
        if (error) {
          console.log("SignalR connection reconnecting");
          Sentry.addBreadcrumb({
            category: "signalr",
            message: "SignalR connection reconnecting with error",
            level: "info",
            data: {
              error: error,
            },
          });
        } else {
          console.log("SignalR connection reconnecting");
          Sentry.addBreadcrumb({
            category: "signalr",
            message: "SignalR connection reconnecting",
            level: "info",
          });
        }
      });

      await newConnection.start();
      console.log("SignalR connection established.");
      connectionRef.current = newConnection;
    } catch (err) {
      console.error("SignalR Connection Error:", err);
      Sentry.captureException(err, {
        tags: {
          context: "Start SignalR Connection",
        },
      });
    } finally {
      setIsConnecting(false);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn && userData) {
      startSignalRConnection(userData.id);
    }

    if (!isLoggedIn) {
      stopSignalRConnection();
    }

    return stopSignalRConnection;
  }, [isLoggedIn, userData]);

  const stopSignalRConnection = useCallback(() => {
    if (connectionRef.current) {
      connectionRef.current.stop();
      connectionRef.current = null;
    }
  }, []);

  const sendMessage = useCallback((message: string) => {
    if (connectionRef.current) {
      connectionRef.current
        .send("sendMessage", message)
        .catch((err) => console.error("Error sending message:", err));
    }
  }, []);

  const addEncounterUpdateListener = useCallback(
    (key: string, callback: (data: FlexibleEncounterData[]) => void) => {
      if (!listenersRef.current.has(key)) {
        listenersRef.current.set(key, new Set());
      }
      listenersRef.current.get(key)!.add(callback);
      // console.log(`Added update listener for key: ${key}`);

      // If the connection is not connected, try to connect
      if (
        connectionRef.current?.state !== HubConnectionState.Connected &&
        !isConnecting
      ) {
        if (userData?.id) {
          startSignalRConnection(userData?.id);
        }
      }
    },
    [startSignalRConnection, isConnecting],
  );

  const removeEncounterUpdateListener = useCallback(
    (key: string, callback: (data: FlexibleEncounterData[]) => void) => {
      const listeners = listenersRef.current.get(key);
      if (listeners) {
        listeners.delete(callback);
        if (listeners.size === 0) {
          listenersRef.current.delete(key);
        }
      }
      //console.log(`Removed update listener for key: ${key}`);
    },
    [],
  );

  return (
    <SignalRContext.Provider
      value={{
        connection: connectionRef.current,
        sendMessage,
        addEncounterUpdateListener,
        removeEncounterUpdateListener,
      }}
    >
      {children}
    </SignalRContext.Provider>
  );
};

export const useSignalR = (): SignalRContextType => {
  const context = useContext(SignalRContext);
  if (!context) {
    throw new Error("useSignalR must be used within a SignalRProvider");
  }
  return context;
};
