import React from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Stack,
  Alert,
  AlertColor,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import fetchAPI from "../../utilities/fetchAPI";
import config from "../../config";

const HealthCheck: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [alertText, setAlertText] = React.useState<string | null>();
  const [alertType, setAlertType] = React.useState<AlertColor | null>();

  const handleHealthCheck = () => {
    setLoading(true);
    setAlertText(null);
    setAlertType(null);
    fetchAPI("HealthCheck", {
      method: "GET",
    })
      .then(async (r) => {
        const response = await r.json();
        if (response.result === "Success") {
          setAlertText(
            `Connected to the API. Backend build number: ${response.build}.`,
          );
          setAlertType("success");
        } else {
          setAlertText("API didn't return a success response");
          setAlertType("error");
        }
        setLoading(false);
      })
      .catch(() => {
        setAlertText("Couldn't get a response from the API");
        setAlertType("error");
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{ py: 8, background: "#101A2F", minHeight: "100vh", display: "flex" }}
    >
      <Helmet>
        <title>Health check page - {config.ENV}</title>
      </Helmet>
      <Container maxWidth="sm">
        <Box
          sx={{
            textAlign: "center",
            p: 4,
            bgcolor: "white",
            borderRadius: 2,
            boxShadow: 3,
          }}
          color="black"
        >
          <Typography variant="h4" gutterBottom>
            Health check
          </Typography>
          <Typography variant="body1" paragraph>
            This is an internal health check page. Click on the button to test
            connection to the API. Environment: {config.ENV}. Frontend build
            number: {process.env.BUILD_NUMBER}.
          </Typography>
          <Stack spacing={2}>
            <Button
              onClick={handleHealthCheck}
              size="large"
              fullWidth
              disableElevation
              variant="contained"
              disabled={loading}
            >
              Test API connection
            </Button>
            {alertText && alertType ? (
              <Alert severity={alertType}>{alertText}</Alert>
            ) : null}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default HealthCheck;
