import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import ClinicianTemplateBuilder from "../clinical-note-template-builder/ClinicianTemplateBuilder";
import { IFormTemplate } from "../../models/templates";
import SnackbarError from "../../reusable-components/SnackBarError";
import LoadingCard from "../../reusable-components/LoadingCard";
import { useUser } from "../../contexts/UserContext";

const EditTemplateWrapper: React.FC = () => {
  const { templateId } = useParams<{ templateId: string }>();
  const [template, setTemplate] = useState<IFormTemplate | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { fetchWithAuth } = useUser();

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const response = await fetchWithAuth(
          `GetUserClinicalNoteTemplates?templateId=${templateId}`,
        );
        if (!response.ok) {
          throw new Error("Failed to fetch template");
        }
        const data = await response.json();
        setTemplate(data);
      } catch (err) {
        setError("Error fetching template. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchTemplate();
  }, [templateId]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <LoadingCard loadingText="Loading Template" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  if (!template) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h6">Template not found</Typography>
      </Box>
    );
  }

  return (
    <>
      <ClinicianTemplateBuilder preloadedTemplate={template} />
      <SnackbarError
        open={!!error}
        message={error || ""}
        onClose={() => setError(null)}
      />
    </>
  );
};

export default EditTemplateWrapper;
