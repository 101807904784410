import fetchAPI from "./fetchAPI";

export const checkoutRedirect = async (
  priceId: string,
  stripeCustomerId?: string,
) => {
  try {
    const requestBody: { priceId: string; stripeCustomerId?: string } = {
      priceId: priceId,
    };

    if (stripeCustomerId) {
      requestBody.stripeCustomerId = stripeCustomerId;
    }

    const response = await fetchAPI("CreateAttendProCheckoutSession", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      const data = await response.json();
      window.location.href = data.url; // Redirect to Stripe checkout page
    } else {
      console.error("Failed to create checkout session");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
