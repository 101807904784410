import React from "react";
import { TextField, MenuItem, Typography } from "@mui/material";
import {
  supportedLocales,
  SupportedLocale,
  getLocaleDisplayNameWithEmoji,
} from "../models/localeTypes";

interface LanguageSelectorProps {
  value: SupportedLocale;
  onChange: (locale: SupportedLocale) => void;
  disabled?: boolean;
  variant?: "caption" | "body2";
  width?: string | number;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  value,
  onChange,
  disabled = false,
  width,
}) => {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as SupportedLocale);
  };

  return (
    <TextField
      select
      value={value}
      onChange={handleChange}
      variant="outlined"
      disabled={disabled}
      size="small"
      aria-label="Select Language"
      inputProps={{
        "aria-label": "Select language",
        role: "combobox",
      }}
      sx={{
        width: width || "100%",
        "& .MuiSelect-select": {
          paddingY: "4px",
        },
        "& .MuiInputBase-input": {
          fontSize: "0.875rem", // Changed to always use body2 font size
        },
      }}
    >
      {supportedLocales.map((locale) => (
        <MenuItem
          key={locale.code}
          value={locale.code}
          aria-label={`Select ${locale.displayName} language`}
        >
          <Typography variant="body2">
            {getLocaleDisplayNameWithEmoji(locale.code)}
          </Typography>
        </MenuItem>
      ))}
    </TextField>
  );
};

export default LanguageSelector;
