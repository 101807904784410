interface IBaseFormTemplate {
  id: string;
  name: string;
  description?: string;
  sections: FormSection[];
  tags?: string[];
  version: number;
  createdAt: string;
  updatedAt: string;
  formType: "clinical" | "patient";
  isPublic: boolean;
  deletedAt?: string;
}

interface ISystemFormTemplate extends IBaseFormTemplate {
  ownerType: "system";
}

interface IUserFormTemplate extends IBaseFormTemplate {
  ownerType: "user";
  userId?: string;
}

export type IFormTemplate = ISystemFormTemplate | IUserFormTemplate;

// Form Section Types

export type FormSection = TextFormSection;

interface BaseFormSection {
  title: string;
  description?: string;
  required?: boolean;
  allowAIPrefill: boolean;
}

interface TextFormSection extends BaseFormSection {
  type: "text";
  defaultValue?: string;
  example?: string;
  minLength?: number;
  maxLength?: number;
  validationPattern?: string;
}

export interface Option {
  id: string;
  label: string;
}

export interface MultipleChoiceFormSection extends BaseFormSection {
  type: "multiple-choice";
  options: Option[];
  defaultOptionId?: string;
}

export interface MultiSelectFormSection extends BaseFormSection {
  type: "multi-select";
  options: Option[];
  defaultOptionIds?: string[];
  minSelections?: number;
  maxSelections?: number;
}

//form response types

export interface IBaseFormResponse {
  id?: string;
  formTemplateId: string;
  formTemplateVersion: number;
  sections: FormResponseSection[];
  submittedAt: string;
  lastUpdated: string;
}

export interface IClinicalFormResponse extends IBaseFormResponse {
  responseType: "clinical";
  userId: string;
  patientId?: string;
  encounterId: string;
}

export interface IPatientFormResponse extends IBaseFormResponse {
  responseType: "patient";
  patientId: string;
}

export interface IEncounterDocumentResponse extends IBaseFormResponse {
  responseType: "encounterDocument";
  userId: string;
  patientId?: string;
  encounterId: string;
}

export interface IBaseFormResponse {
  id?: string;
  formTemplateId: string;
  formTemplateVersion: number;
  sections: FormResponseSection[];
  submittedAt: string;
  lastUpdated: string;
}

export interface IClinicalFormResponse extends IBaseFormResponse {
  responseType: "clinical";
  userId: string;
  patientId?: string;
  encounterId: string;
}

export interface IPatientFormResponse extends IBaseFormResponse {
  responseType: "patient";
  patientId: string;
}

export interface IEncounterDocumentResponse extends IBaseFormResponse {
  responseType: "encounterDocument";
  userId: string;
  patientId?: string;
  encounterId: string;
}

export type IFormResponse =
  | IClinicalFormResponse
  | IPatientFormResponse
  | IEncounterDocumentResponse;

export type FormResponseSection =
  | TextFormResponseSection
  | MultipleChoiceFormResponseSection
  | MultiSelectFormResponseSection;

interface BaseFormResponseSection {
  title: string;
  isAIPrefilled: boolean;
  userInteraction: UserInteraction;
  aiPrefillReference?: AIPrefillReference; // New field for AI prefill information
}

export interface AIPrefillReference {
  transcriptExcerpt: string;
}

export interface UserInteraction {
  status: "approved" | "edited" | "untouched";
  userId?: string; // clinicianId or patientId
  timestamp?: string;
}

export interface TextFormResponseSection extends BaseFormResponseSection {
  type: "text";
  content: string;
}

export interface MultipleChoiceFormResponseSection
  extends BaseFormResponseSection {
  type: "multiple-choice";
  selectedOptionId: string;
}

export interface MultiSelectFormResponseSection
  extends BaseFormResponseSection {
  type: "multi-select";
  selectedOptionIds: string[];
}

export function getFormResponseSectionContent(
  section: FormResponseSection | undefined,
): string {
  if (!section) return "";

  if ("content" in section) {
    return section.content || "";
  }

  if (section.type === "multiple-choice" || section.type === "multi-select") {
    return ""; // TODO: Implement this
  }

  return "";
}
