import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import Sidebar from "./Sidebar";
import { Helmet } from "react-helmet-async";

const drawerWidth = 300; // Adjust this value as needed

const MainLayout = () => {
  return (
    <Box display="flex" height="100vh" bgcolor="#E8EBF1">
      <Helmet>
        <title>Attend AI</title>
        <meta
          name="description"
          content="The intuitive AI assistant for clinical professionals."
        />
      </Helmet>

      <Sidebar />
      <Box
        component="main"
        flexGrow={1}
        display="flex" // Add this
        flexDirection="column" // Add this
        sx={{
          bgcolor: "#E8EBF1",
          overflowY: "auto",
          pr: { xs: 0, sm: 2 },
          mr: { xs: 0, sm: 0 },
          ml: { xs: 0, sm: `${drawerWidth}px` },
          mt: { xs: 8, sm: 2 },
          mb: { xs: 4, sm: 0 },
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
