import React from "react";
import { Box, Container, useTheme, AppBar, Toolbar } from "@mui/material";

interface LegalTemplateProps {
  children: React.ReactNode;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  boxSx?: object;
  navigation?: React.ReactNode;
}

const LegalTemplate: React.FC<LegalTemplateProps> = ({
  children,
  maxWidth = "md",
  boxSx = {},
  navigation,
}) => {
  const theme = useTheme();

  return (
    <>
      {/* Fixed App Bar Navigation */}
      {navigation && (
        <AppBar
          position="fixed"
          elevation={0}
          sx={{
            backgroundColor: "rgba(247, 248, 250, 0.95)",
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(8px)",
            height: { xs: "48px", sm: "56px" },
          }}
        >
          <Container maxWidth={maxWidth}>
            <Toolbar
              sx={{
                minHeight: { xs: "48px", sm: "56px" },
                px: { xs: 0, sm: 0 },
                color: "text.primary",
              }}
            >
              {navigation}
            </Toolbar>
          </Container>
        </AppBar>
      )}

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          minHeight: "100vh",
          width: "100vw",
          background:
            "linear-gradient(to top, #A0C8F3 0%, #E8EBF1 33%, #E8EBF1 100%)",
          display: "flex",
          boxSizing: "border-box",
          pt: { xs: "64px", sm: "72px" }, // Offset for fixed AppBar
        }}
      >
        <Container
          maxWidth={maxWidth}
          sx={{
            py: { xs: 2, sm: 3 },
          }}
        >
          <Box
            sx={{
              backgroundColor: "#F7F8FA",
              border: "1px solid rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              p: { xs: "16px 24px", sm: "24px 32px" },
              color: theme.palette.text.primary,
              // Legal document specific styles
              "& h1": {
                fontSize: { xs: "1.75rem", sm: "2rem" },
                fontWeight: 600,
                mb: 3,
                color: theme.palette.text.primary,
              },
              "& h2": {
                fontSize: { xs: "1.25rem", sm: "1.5rem" },
                fontWeight: 500,
                mb: 2,
                color: theme.palette.text.primary,
              },
              "& p": {
                mb: 2,
                lineHeight: 1.7,
                fontSize: { xs: "0.875rem", sm: "1rem" },
              },
              "& ul, & ol": {
                mb: 2,
                pl: 3,
              },
              "& li": {
                mb: 1,
                lineHeight: 1.6,
              },
              "& a": {
                color: theme.palette.primary.main,
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              },
              ...boxSx,
            }}
          >
            {children}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default LegalTemplate;
