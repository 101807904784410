import React from "react";
import { Box, Typography } from "@mui/material";
import TemplateSelector from "./TemplateSelector";
import LanguageSelector from "./LanguageSelector";
import { SupportedLocale } from "../models/localeTypes";

interface EncounterSettingsProps {
  selectedTemplate: string;
  handleTemplateChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  isTemplateSelectLocked: boolean;
  selectedLocale: SupportedLocale;
  setSelectedLocale: (locale: SupportedLocale) => void;
}

const EncounterSettings: React.FC<EncounterSettingsProps> = ({
  selectedTemplate,
  handleTemplateChange,
  isTemplateSelectLocked,
  selectedLocale,
  setSelectedLocale,
}) => {
  return (
    <Box sx={{ pb: 3 }}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body2" sx={{ flexShrink: 0, marginRight: 1 }}>
            Note Template:
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <TemplateSelector
              selectedTemplate={selectedTemplate}
              handleTemplateChange={handleTemplateChange}
              isTemplateSelectLocked={isTemplateSelectLocked}
              width="250px"
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body2" sx={{ flexShrink: 0, marginRight: 1 }}>
            Language:
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <LanguageSelector
              value={selectedLocale}
              onChange={setSelectedLocale}
              disabled={isTemplateSelectLocked}
              variant="caption"
              width="250px"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EncounterSettings;
