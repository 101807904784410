import React, { useMemo } from "react";
import { Alert, Box, Button } from "@mui/material";
import DynamicNoteSectionEditor from "./DynamicNoteSectionEditor";
import { NoteContent } from "../../contexts/SignalRContext";

interface NoteTemplate {
  sections: Array<{
    title: string;
    description?: string;
  }>;
}

interface DynamicNoteRendererProps {
  noteTemplate: NoteTemplate;
  noteContent: NoteContent;
  updateNoteContentRef: (sectionTitle: string, content: string) => void;
  awaitingRevision: boolean;
}

const DynamicNoteRenderer: React.FC<DynamicNoteRendererProps> = ({
  noteTemplate,
  noteContent,
  updateNoteContentRef,
  awaitingRevision,
}) => {
  const isTemplateMismatched = useMemo(() => {
    const templateSections = new Set(
      noteTemplate.sections.map((section) => section.title),
    );
    const contentSections = new Set(Object.keys(noteContent));

    return (
      ![...templateSections].every((section) => contentSections.has(section)) ||
      ![...contentSections].every((section) => templateSections.has(section))
    );
  }, [noteTemplate, noteContent]);

  if (isTemplateMismatched) {
    return (
      <Alert severity="warning" sx={{ mb: 2 }}>
        There was an error loading the note template. Please reload the page.
        <Button onClick={() => window.location.reload()} sx={{ ml: 2 }}>
          Reload
        </Button>
      </Alert>
    );
  }

  const renderedSections = useMemo(() => {
    return noteTemplate.sections.map((section) => (
      <DynamicNoteSectionEditor
        key={section.title}
        sectionTitle={section.title}
        sectionDescription={section.description}
        sectionContent={noteContent[section.title].content || ""}
        onContentChange={(content) =>
          updateNoteContentRef(section.title, content)
        }
        awaitingRevision={awaitingRevision}
      />
    ));
  }, [noteTemplate, awaitingRevision]);

  return <Box>{renderedSections}</Box>;
};

export default React.memo(DynamicNoteRenderer);
