import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Typography,
  TextField,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useUser } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { TemplateListItem } from "../../contexts/UserContext";
import SnackbarError from "../../reusable-components/SnackBarError";
import LoadingCard from "../../reusable-components/LoadingCard";
import SearchIcon from "@mui/icons-material/Search";
import { Tabs, Tab } from "@mui/material";

interface ITemplate extends TemplateListItem {
  isFavorite: boolean;
  isDefault: boolean;
}

const TemplateLibraryAndManagement: React.FC = () => {
  const [templates, setTemplates] = useState<ITemplate[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [viewMode, setViewMode] = useState<"favorites" | "library">(
    "favorites",
  );

  const {
    userData,
    setUserData,
    updateDefaultNoteTemplate,
    addSystemTemplateToUser,
    removeSystemTemplateFromUser,
    fetchUserTemplates,
    fetchWithAuth,
  } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    //console.log("Template library mounting")
    fetchAllTemplates();
  }, []);

  const updateTemplatesFromUserData = useCallback(() => {
    console.log("Updating templates from user data");
    setTemplates((prevTemplates) => {
      const userTemplates = userData?.templates || [];

      const updatedTemplates = prevTemplates.map((template) => {
        const userTemplate = userTemplates.find((ut) => ut.id === template.id);
        if (userTemplate) {
          return {
            ...template,
            ...userTemplate,
            ownerType: userTemplate.ownerType,
            isFavorite: true, // All templates in userData are favorites
            isDefault: template.id === userData?.defaultNoteTemplateId,
          };
        }
        return {
          ...template,
          isFavorite: template.ownerType === "user" || template.id === "soap",
          isDefault: template.id === userData?.defaultNoteTemplateId,
        };
      });

      // Add new user templates that weren't in prevTemplates
      userTemplates.forEach((userTemplate) => {
        if (!updatedTemplates.some((t) => t.id === userTemplate.id)) {
          updatedTemplates.push({
            ...userTemplate,
            ownerType: userTemplate.ownerType,
            isFavorite: true,
            isDefault: userTemplate.id === userData?.defaultNoteTemplateId,
          });
        }
      });

      console.log("Templates after update:", updatedTemplates);
      return updatedTemplates;
    });
  }, [userData]);

  useEffect(() => {
    if (userData && templates.length > 0) {
      console.log("Updating templates from user data: ", userData);
      updateTemplatesFromUserData();
    }
  }, [userData?.templates, updateTemplatesFromUserData]);

  const fetchAllTemplates = useCallback(async () => {
    setIsLoading(true);
    try {
      await fetchUserTemplates((fetchedTemplates) => {
        const userTemplates = fetchedTemplates || [];

        fetchWithAuth("GetSystemTemplates?formType=clinical")
          .then((response) => response.json())
          .then((systemTemplatesData) => {
            const systemTemplates: ITemplate[] =
              systemTemplatesData.templates.map((template: any) => ({
                id: template.id,
                name: template.name,
                ownerType: "system",
                description: template.description,
                isFavorite: userTemplates.some((ut) => ut.id === template.id),
                isDefault: template.id === userData?.defaultNoteTemplateId,
              }));

            const userTemplatesFormatted: ITemplate[] = userTemplates.map(
              (ut) => ({
                ...ut,
                ownerType: ut.ownerType || "user",
                isFavorite: true,
                isDefault: ut.id === userData?.defaultNoteTemplateId,
              }),
            );

            const allTemplates = [
              ...userTemplatesFormatted,
              ...systemTemplates.filter(
                (st) => !userTemplatesFormatted.some((ut) => ut.id === st.id),
              ),
            ];

            const soapTemplate: ITemplate = {
              id: "soap",
              name: "SOAP Note",
              ownerType: "system",
              description:
                "Standard SOAP (Subjective, Objective, Assessment, Plan) note template",
              isFavorite: true,
              isDefault: userData?.defaultNoteTemplateId === "soap",
            };

            setTemplates([soapTemplate, ...allTemplates]);
          })
          .catch((error) => {
            console.error("Failed to fetch system templates:", error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    } catch (error) {
      console.error("Error fetching templates:", error);
      setIsLoading(false);
    }
  }, [fetchUserTemplates, userData]);

  const handleToggleFavorite = async (
    templateId: string,
    templateName: string,
  ) => {
    const template = templates.find((t) => t.id === templateId);
    if (template && template.ownerType === "system" && templateId !== "soap") {
      // Optimistically update the UI
      setTemplates((prevTemplates) =>
        prevTemplates.map((t) =>
          t.id === templateId ? { ...t, isFavorite: !t.isFavorite } : t,
        ),
      );

      try {
        if (!template.isFavorite) {
          await addSystemTemplateToUser(templateId, templateName);
        } else {
          await removeSystemTemplateFromUser(templateId);
        }
      } catch (error) {
        console.error("Error toggling favorite status:", error);
        // Revert the optimistic update
        setTemplates((prevTemplates) =>
          prevTemplates.map((t) =>
            t.id === templateId ? { ...t, isFavorite: !t.isFavorite } : t,
          ),
        );
        setError("Failed to update favorite status. Please try again.");
      }
    }
  };

  const handleSetDefault = async (templateId: string) => {
    await updateDefaultNoteTemplate(templateId);
  };

  const handleDeleteTemplate = async (templateId: string) => {
    try {
      const response = await fetchWithAuth("DeleteUserClinicalNoteTemplate", {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id: templateId }),
      });
      if (response.ok) {
        // Update local state
        setTemplates((prevTemplates) =>
          prevTemplates.filter((t) => t.id !== templateId),
        );

        // Update userData context
        setUserData((prevUserData) => {
          if (!prevUserData) return prevUserData;
          return {
            ...prevUserData,
            templates:
              prevUserData.templates?.filter((t) => t.id !== templateId) || [],
          };
        });

        // If the deleted template was the default, set SOAP as the new default
        if (userData?.defaultNoteTemplateId === templateId) {
          await updateDefaultNoteTemplate("soap");
        }
      } else {
        console.error("Failed to delete template");
        setError("Failed to delete template. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting template:", error);
      setError(
        "An error occurred while deleting the template. Please try again.",
      );
    }
  };

  const handleRowClick = (template: ITemplate) => {
    if (template.ownerType === "user") {
      navigate(`/edit-note-template/${template.id}`);
    } else {
      navigate(`/template/${template.id}`);
    }
  };

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    templateId: string,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedTemplate(templateId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedTemplate(null);
  };

  const handleViewModeChange = (
    _event: React.SyntheticEvent,
    newValue: "favorites" | "library",
  ) => {
    setViewMode(newValue);
  };

  const filteredTemplates = useMemo(() => {
    let filtered = templates;
    if (viewMode === "favorites") {
      filtered = templates.filter(
        (t) => t.isFavorite || t.ownerType === "user" || t.id === "soap",
      );
    } else {
      filtered = templates.filter((t) => t.ownerType === "system");
    }
    return filtered.filter(
      (t) =>
        t.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        t.description?.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [templates, viewMode, searchTerm]);

  const renderTemplateTable = (
    templates: ITemplate[],
    emptyMessage: string,
  ) => (
    <TableContainer component={Paper} elevation={0}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            {viewMode === "favorites" && (
              <>
                <TableCell>Type</TableCell>
                <TableCell align="center">Default</TableCell>
                <TableCell>Actions</TableCell>
              </>
            )}
            {viewMode === "library" && (
              <TableCell align="center">Add to Favorites</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {templates.length === 0 ? (
            <TableRow>
              <TableCell colSpan={viewMode === "favorites" ? 5 : 3}>
                <Typography variant="body2" color="textSecondary">
                  {emptyMessage}
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            templates.map((template) => (
              <TableRow
                key={template.id}
                onClick={() => handleRowClick(template)}
                sx={{
                  backgroundColor:
                    template.isFavorite ||
                    template.ownerType === "user" ||
                    template.isDefault
                      ? "rgba(60, 140, 255, 0.08)"
                      : "inherit",
                  "&:hover": {
                    backgroundColor: "rgba(60, 140, 255, 0.12)",
                    cursor: "pointer",
                  },
                }}
              >
                <TableCell>{template.name}</TableCell>
                <TableCell>{template.description}</TableCell>
                {viewMode === "favorites" && (
                  <>
                    <TableCell>{getDisplayType(template.ownerType)}</TableCell>
                    <TableCell align="center">
                      {template.isDefault && (
                        <CheckCircleIcon color="primary" />
                      )}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          onClick={(event) =>
                            handleMenuOpen(event, template.id)
                          }
                          size="small"
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </>
                )}
                {viewMode === "library" && (
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleToggleFavorite(template.id, template.name);
                      }}
                      size="small"
                    >
                      {template.isFavorite ? <StarIcon /> : <StarBorderIcon />}
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <LoadingCard loadingText="Loading Templates" />
      </Box>
    );
  }

  const renderMenuItems = () => {
    if (!selectedTemplate) return [];

    const template = templates.find((t) => t.id === selectedTemplate);
    if (!template) return [];

    const menuItems = [];

    if (!template.isDefault) {
      menuItems.push(
        <MenuItem
          key="set-default"
          onClick={() => {
            handleSetDefault(selectedTemplate);
            handleMenuClose();
          }}
        >
          Set as Default
        </MenuItem>,
      );
    }

    if (template.ownerType === "user") {
      menuItems.push(
        <MenuItem
          key="delete"
          onClick={() => {
            handleDeleteTemplate(selectedTemplate);
            handleMenuClose();
          }}
        >
          Delete
        </MenuItem>,
      );
    }

    if (template.ownerType === "system") {
      menuItems.push(
        <MenuItem
          key="remove-favorite"
          onClick={() => {
            handleToggleFavorite(selectedTemplate, template.name);
            handleMenuClose();
          }}
        >
          Remove from Favorites
        </MenuItem>,
      );
    }

    return menuItems;
  };

  return (
    <Card
      sx={{
        p: 2,
        maxWidth: 800,
        "overflow-y": "scroll",
      }}
      variant="outlined"
    >
      <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
        Manage Templates
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <TextField
          sx={{ flexGrow: 1, mr: 2 }}
          variant="outlined"
          placeholder="Search templates..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon color="action" sx={{ mr: 1 }} />,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          disableElevation
          startIcon={<AddIcon />}
          onClick={() => navigate("/create-note-template")}
          size="large"
        >
          Create New Template
        </Button>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
        <Tabs
          value={viewMode}
          onChange={handleViewModeChange}
          aria-label="template view mode"
        >
          <Tab label="My Favorites" value="favorites" />
          <Tab label="Template Library" value="library" />
        </Tabs>
      </Box>

      {renderTemplateTable(
        filteredTemplates,
        viewMode === "favorites"
          ? "No favorite templates found."
          : "No templates found in the library.",
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {renderMenuItems()}
      </Menu>

      <SnackbarError
        open={!!error}
        message={error || ""}
        onClose={() => setError(null)}
      />
    </Card>
  );
};

const getDisplayType = (ownerType: string): string => {
  switch (ownerType.toLowerCase()) {
    case "user":
      return "Personal";
    case "system":
      return "Library";
    default:
      return ownerType;
  }
};

export default TemplateLibraryAndManagement;
