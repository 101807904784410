// SubscriptionCard.tsx
import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { useUser } from "../contexts/UserContext";
import { checkoutRedirect } from "../utilities/checkoutRedirect";
import config from "../config";

const SubscriptionCard: React.FC = () => {
  const { userData } = useUser();
  const priceId = config.ATTEND_PRICE_ID;

  const handleSubscribe = () => {
    checkoutRedirect(priceId, userData?.stripeCustomerId);
  };

  return (
    <Card sx={{ mx: 2, maxWidth: 600 }}>
      <CardHeader title="Please subscribe to continue" />
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <img
            src="/attend.svg"
            alt="Loading Icon"
            style={{ width: 70, height: 70, marginRight: 16 }}
          />
          <Box>
            <Typography variant="h6" fontWeight="400">
              Attend Pro
            </Typography>
            <Typography variant="body2">
              $89/mo or $960/yr (save $108)
            </Typography>
            <Typography variant="body2">Unlimited encounters</Typography>
            <Typography variant="body2">30 day money back guarantee</Typography>
          </Box>
        </Box>
        <Typography variant="body1" sx={{ mt: 6 }}>
          You have completed your {userData?.freeEncounterQuota} free
          encounters. Please subscribe to enjoy unlimited encounters.
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          onClick={handleSubscribe}
          color="primary"
          fullWidth
          variant="contained"
        >
          Subscribe
        </Button>
      </CardActions>
    </Card>
  );
};

export default SubscriptionCard;
