import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  LinearProgress,
  Alert,
  TextField,
  Button,
  Box,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";
import fetchAPI from "../../utilities/fetchAPI";

interface IValidateLoginProps {
  email: string;
}

const MAX_TRIES = 3;

const LoginValidate: React.FC<IValidateLoginProps> = ({
  email,
}: IValidateLoginProps) => {
  const navigate = useNavigate();
  const { authenticate } = useUser();
  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [triesCount, setTriesCount] = useState<number>(0);
  const inputsContainerRef = useRef<HTMLElement>(null);
  const [codeArray, setCodeArray] = useState<string[]>([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);

  const handleCodeSubmit = (e: any = null) => {
    e?.preventDefault();
    const code = codeArray.join("");
    const strippedCode = code.replace("-", "");
    if (strippedCode.length === 6) {
      setError("");
      setLoadingValidation(true);
      fetchAPI("ValidateLogin", {
        method: "POST",
        body: JSON.stringify({
          code: strippedCode,
          email: email,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            return Promise.reject(response);
          }

          return response.json();
        })
        .then((body) => {
          if (body.success === true) {
            authenticate(body);
            if (body.isFirstSession) {
              navigate("/onboarding");
            } else {
              navigate("/");
            }
          } else {
            const error = new Error(body.error);
            error.name = "";
            throw error;
          }
        })
        .catch(async (e) => {
          if (triesCount + 1 === MAX_TRIES) {
            setError(
              "You have reached maximum amount of tries. Please send the one-time password again.",
            );
            setIsFormDisabled(true);
          } else {
            let error;
            try {
              if (!error) {
                error = await e.text();
              }
            } catch {
              error = e.toString();
            }

            if (error) {
              setError(error);
            } else {
              setError(
                "Something went wrong. Please try again or contact support@crafthealthtech.com.",
              );
            }
            setTriesCount(triesCount + 1);
          }
          setLoadingValidation(false);
        });
    } else {
      setError("The code you entered is either incorrect or expired.");
    }
  };

  const setCodeSymbol = (i: number, value: string) => {
    setError("");
    const newCodeArray = [...codeArray];
    newCodeArray[i] = value.toUpperCase();
    if (value) {
      setFocusOnInput(i + 1);
    }
    setCodeArray(newCodeArray);
  };

  const setFocusOnInput = (i: number) => {
    if (inputsContainerRef.current) {
      const inputs = inputsContainerRef.current.getElementsByTagName("input");
      if (inputs.length && inputs[i]) {
        inputs[i].focus();
      }
    }
  };

  const onCodePaste = (i: number, e: any) => {
    e.preventDefault();
    setError("");
    let value = e.clipboardData.getData("text") as string;
    value = value.replace("-", "").substring(0, 6);
    if (value && inputsContainerRef.current) {
      const newCodeArray = [...codeArray];
      const inputs = inputsContainerRef.current.getElementsByTagName("input");
      Array.from(value).every((symbol: string): boolean => {
        if (inputs[i]) {
          newCodeArray[i] = symbol.toUpperCase();
          i++;
          setFocusOnInput(i);
          return true;
        } else {
          return false;
        }
      });
      setCodeArray(newCodeArray);
    }
  };

  const handleBackspace = (i: number, e: any) => {
    if (e.keyCode && e.keyCode === 8 && codeArray[i - 1]) {
      setFocusOnInput(i - 1);
    }
  };

  useEffect(() => {
    setFocusOnInput(0);
  }, []);

  useEffect(() => {
    if (codeArray.every((val) => !!val)) {
      handleCodeSubmit();
    }
  }, [codeArray]);

  return (
    <Box
      component="div"
      sx={{
        boxSizing: "border-box",
        color: "text.primary",
        textAlign: "center",
        mt: 4,
      }}
    >
      <Box component="div" sx={{ width: "100%" }}>
        <Typography variant="h4" color="text.primary" sx={{}}>
          Check your email for a code
        </Typography>
        <Typography
          variant="body1"
          color="text.primary"
          sx={{ mt: 2, mb: 2, pb: 2, alignSelf: "flex-start" }}
        >
          We've sent a 6-character code to{" "}
          <Chip
            size="medium"
            label={email}
            sx={{
              borderRadius: "4px",
              backgroundColor: "secondary.main",
              color: "text.primary",
              fontWeight: "bold",
              fontSize: "1.2rem",
            }}
          />
          . <br /> The code expires shortly, so please enter it soon.
        </Typography>
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleCodeSubmit}
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              mb: 4,
            }}
            ref={inputsContainerRef}
          >
            {[0, 1, 2, 2.5, 3, 4, 5].map((i) =>
              i === 2.5 ? (
                <span key="divider">-</span>
              ) : (
                <TextField
                  id={`code-input-${i}`}
                  required
                  name="code"
                  fullWidth
                  variant="outlined"
                  value={codeArray[i]}
                  onChange={(e) => setCodeSymbol(i, e.target.value)}
                  onFocus={(e) => e.target.select()}
                  onPaste={(e) => onCodePaste(i, e)}
                  onKeyUp={(e) => handleBackspace(i, e)}
                  disabled={loadingValidation || isFormDisabled}
                  margin="dense"
                  key={`input-${i}`}
                  sx={{
                    "&": {
                      display: "block",
                    },
                  }}
                  error={!!error}
                  InputProps={{
                    sx: {
                      borderRadius: "5px",
                      height: {
                        md: "120px",
                        sm: "100px",
                        xs: "80px",
                      },
                      width: {
                        md: "100px",
                        xs: "unset",
                      },
                      fontSize: {
                        sm: "75px",
                        xs: "50px",
                      },
                      "& .MuiInputBase-input": {
                        textAlign: "center",
                        paddingLeft: {
                          md: "14px",
                          xs: "5px",
                        },
                        paddingRight: {
                          md: "14px",
                          xs: "5px",
                        },
                      },
                      fontWeight: {
                        sm: "normal",
                      },
                    },
                  }}
                  inputProps={{
                    maxLength: "1",
                  }}
                />
              ),
            )}
          </Box>

          {error && (
            <Alert
              severity="error"
              sx={{
                width: "100%",
                mb: 2,
                boxSizing: "border-box",
                fontSize: "1.2rem",
                alignItems: "center",
              }}
            >
              {error}
            </Alert>
          )}

          {loadingValidation && <LinearProgress sx={{ mb: 2 }} />}

          <Button
            type="submit"
            size="large"
            fullWidth
            disableElevation
            variant="contained"
            sx={{
              mb: 1,
              borderRadius: "3px",
            }}
            disabled={loadingValidation || isFormDisabled}
          >
            Sign in
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default LoginValidate;
