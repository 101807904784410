import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  CardContent,
  Box,
  Button,
  Typography,
  Modal,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  TextField,
  Divider,
  Chip,
  Step,
  Stepper,
  StepLabel,
  Alert,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  FlexibleEncounterData,
  IEncounterDocument,
} from "../../contexts/SignalRContext";
import {
  getFormResponseSectionContent,
  IEncounterDocumentResponse,
  TextFormResponseSection,
} from "../../models/templates";
import SnackbarSuccess from "../../reusable-components/SnackBarSuccess";
import DocumentTabs, { TabContent } from "./DocumentTabs";
import { useUser } from "../../contexts/UserContext";

interface EncounterProcessorProps {
  encounterData: FlexibleEncounterData;
  encounterDocuments: IEncounterDocument[];
  setEncounterDocuments: React.Dispatch<
    React.SetStateAction<IEncounterDocument[]>
  >;
  patientInstructions: string;
  setPatientInstructions: (content: string) => void;
}

interface EncounterDocumentTemplate {
  id: string;
  name: string;
}

const EncounterProcessor: React.FC<EncounterProcessorProps> = ({
  encounterData,
  encounterDocuments,
  setEncounterDocuments,
  patientInstructions,
  setPatientInstructions,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const { fetchWithAuth } = useUser();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [templates, setTemplates] = useState<EncounterDocumentTemplate[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTemplates, setFilteredTemplates] = useState(templates);
  const [isCustomDocument, setIsCustomDocument] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedTemplateName, setSelectedTemplateName] = useState("");
  const [additionalContext, setAdditionalContext] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
    null,
  );
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    const filtered = templates.filter((template) =>
      template.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    setFilteredTemplates(filtered);
  }, [searchTerm, templates]);

  const fetchTemplates = async () => {
    try {
      const response = await fetchWithAuth(
        "GetSystemTemplates?formType=encounterDocument",
      );
      if (response.ok) {
        const data = await response.json();
        setTemplates(data.templates);
      } else {
        console.error("Error fetching templates:", response.status);
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  /*const handleGenerateInstructions = () => {
    if (encounterData && encounterData.encounterId) {
      setAwaitingInstructionGeneration(true);
      const requestBody = {
        encounterId: encounterData.encounterId,
      };

      fetchWithAuth('CreatePatientInstructions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      })
      .then(response => {
        if (response.ok) {
          console.log('Patient instructions requested successfully');
        } else {
          console.error('Error requesting patient instructions:', response.status);
        }
      })
      .catch(error => {
        console.error('Error requesting patient instructions:', error);
      })
    }
  };*/

  const handleCopyContent = useCallback(() => {
    let content = "";
    if (activeTab === 0) {
      content = patientInstructions;
    } else {
      const doc = encounterDocuments[activeTab - 1];
      content = getFormResponseSectionContent(doc.content?.sections[0]);
    }

    if (content) {
      navigator.clipboard.writeText(content);
      setOpenSnackbar(true);
    }
  }, [activeTab, patientInstructions, encounterDocuments]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDocumentContentChange = (index: number, newContent: string) => {
    setEncounterDocuments((prevDocs) =>
      prevDocs.map((doc, i) => {
        if (i === index) {
          const updatedContent: IEncounterDocumentResponse = {
            ...doc.content!,
            sections: doc.content!.sections.map((section, sectionIndex) => {
              if (sectionIndex === 0) {
                // Assuming we're always updating the first section
                return {
                  ...section,
                  content: newContent,
                  userInteraction: {
                    status: "edited",
                    userId: doc.content?.userId,
                    timestamp: new Date().toISOString(),
                  },
                } as TextFormResponseSection;
              }
              return section;
            }),
            lastUpdated: new Date().toISOString(),
          };
          return { ...doc, content: updatedContent };
        }
        return doc;
      }),
    );
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleGenerateCustomDocument = () => {
    if (searchTerm.trim() === "") return;
    setIsCustomDocument(true);
    handleGenerateDocument(null, searchTerm);
  };

  const handleGenerateDocument = async (
    templateId: string | null,
    customPrompt?: string,
  ) => {
    setIsLoading(true);
    setIsCustomDocument(!!customPrompt);
    if (customPrompt) {
      setSelectedTemplateName(customPrompt);
      setSelectedTemplateId(null);
    } else {
      const template = templates.find((t) => t.id === templateId);
      setSelectedTemplateName(template ? template.name : "");
      setSelectedTemplateId(templateId);
    }
    setActiveStep(1);
    setIsLoading(false);
  };

  const validateInput = (input: string, maxLength: number): string | null => {
    if (input.length > maxLength)
      return `Input exceeds maximum length of ${maxLength} characters`;
    if (/<script|<\/script|<iframe|<\/iframe/i.test(input))
      return "Input contains unsafe HTML tags";
    if (/javascript:/i.test(input)) return "Input contains unsafe JavaScript";
    return null;
  };

  const handleFinalGenerate = async () => {
    setIsLoading(true);
    setError(null);

    const customPromptError = validateInput(selectedTemplateName, 500);
    const additionalContextError = validateInput(additionalContext, 1000);

    if (customPromptError || additionalContextError) {
      setError(customPromptError || additionalContextError);
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetchWithAuth("GenerateEncounterDocument", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          templateId: isCustomDocument ? null : selectedTemplateId,
          customPrompt: isCustomDocument ? selectedTemplateName : null,
          encounterId: encounterData?.encounterId,
          additionalContext,
        }),
      });

      if (response.ok) {
        const { message, document } = await response.json();
        if (document) {
          setEncounterDocuments((prevDocs) => [...prevDocs, document]);
          setActiveTab(encounterDocuments.length + 1);
          console.log(message);
          setIsModalOpen(false);
        } else {
          setError("Generated document not found in the response");
        }
      } else {
        const errorData = await response.json();
        setError(
          errorData.error || "An error occurred while generating the document",
        );
      }
    } catch (error) {
      console.error("Error generating document:", error);
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setIsLoading(false);
      if (!error) {
        setIsCustomDocument(false);
        setActiveStep(0);
        setSelectedTemplateName("");
        setAdditionalContext("");
      }
    }
  };

  const handleBack = () => {
    setActiveStep(0);
    setSelectedTemplateName("");
    setAdditionalContext("");
  };

  const handleDeleteDocument = useCallback(
    async (documentId: string) => {
      // Store the current state for potential rollback
      const previousDocuments = encounterDocuments;
      const previousActiveTab = activeTab;

      // Optimistically update the UI
      const documentIndex = encounterDocuments.findIndex(
        (doc) => doc.id === documentId,
      );
      setEncounterDocuments((prevDocs) =>
        prevDocs.filter((doc) => doc.id !== documentId),
      );

      // Adjust activeTab if necessary
      if (activeTab > documentIndex) {
        setActiveTab(activeTab - 1);
      } else if (
        activeTab === documentIndex + 1 &&
        activeTab === encounterDocuments.length
      ) {
        setActiveTab(activeTab - 1);
      }

      try {
        const response = await fetchWithAuth("DeleteEncounterDocument", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            encounterId: encounterData.encounterId,
            documentId,
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to delete document");
        }

        // If successful, no need to do anything else as we've already updated the UI
        // Optionally, show a success message
        // setSnackbarMessage('Document successfully deleted');
        // setSnackbarOpen(true);
      } catch (error) {
        console.error("Error deleting document:", error);
        // Revert the changes in case of an error
        setEncounterDocuments(previousDocuments);
        setActiveTab(previousActiveTab);
        // Optionally, show an error message
        // setSnackbarMessage('Failed to delete document. Please try again.');
        // setSnackbarOpen(true);
      }
    },
    [
      encounterData.encounterId,
      encounterDocuments,
      activeTab,
      setEncounterDocuments,
      setActiveTab,
    ],
  );

  return (
    <Card variant="outlined">
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          role="region"
          aria-label="Document Management"
        >
          <Typography variant="h5" component="h2">
            Documents
          </Typography>
          <Box>
            <Button
              variant="contained"
              disableElevation
              startIcon={<AddIcon />}
              sx={{ backgroundColor: "primary.dark", color: "white" }}
              onClick={() => setIsModalOpen(true)}
              size="small"
              aria-label="Create new document"
            >
              New Document
            </Button>
          </Box>
        </Box>

        <DocumentTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          encounterDocuments={encounterDocuments}
          aria-label="Document sections"
        />

        <TabContent
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          patientInstructions={patientInstructions}
          setPatientInstructions={setPatientInstructions}
          encounterDocuments={encounterDocuments}
          handleDocumentContentChange={handleDocumentContentChange}
          handleCopyContent={handleCopyContent}
          onDeleteDocument={handleDeleteDocument}
        />

        <Modal
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setActiveStep(0);
            setSelectedTemplateName("");
            setAdditionalContext("");
          }}
          aria-labelledby="template-modal-title"
          aria-describedby="template-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              minWidth: 400,
              minHeight: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
            }}
            role="dialog"
            aria-modal="true"
          >
            <Box sx={{ p: 2 }}>
              <Typography
                id="template-modal-title"
                variant="h6"
                component="h2"
                gutterBottom
                sx={{ color: "text.primary", fontSize: "1.1rem", pb: 2 }}
              >
                New Document
              </Typography>
              <Stepper
                activeStep={activeStep}
                aria-label="Document creation steps"
              >
                <Step key="select">
                  <StepLabel>Choose Template</StepLabel>
                </Step>
                <Step key="generate">
                  <StepLabel>Generate</StepLabel>
                </Step>
              </Stepper>
            </Box>

            {activeStep === 0 ? (
              // Step 1: Template selection (existing content)
              <Box role="region" aria-label="Template selection">
                <Typography
                  variant="subtitle1"
                  color="text.primary"
                  sx={{ p: 5 }}
                >
                  Choose a template or create a custom document
                </Typography>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    mx: 2,
                    mb: 2,
                    border: 1,
                    borderColor: "divider",
                    borderRadius: 1,
                  }}
                >
                  <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
                    <Box sx={{ display: "flex" }}>
                      <TextField
                        size="small"
                        placeholder="Request a document"
                        value={searchTerm}
                        onChange={handleSearch}
                        InputProps={{
                          sx: {
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                          },
                        }}
                        sx={{ flexGrow: 1 }}
                      />
                      <Button
                        variant="contained"
                        disableElevation
                        sx={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          border: 1,
                          borderLeft: 0,
                          borderColor: "divider",
                          "&:hover": {
                            backgroundColor: "primary.main",
                            color: "primary.contrastText",
                          },
                        }}
                        onClick={handleGenerateCustomDocument}
                        disabled={isLoading || searchTerm.trim() === ""}
                        size="small"
                      >
                        Create
                      </Button>
                    </Box>
                  </Box>
                  <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
                    {filteredTemplates.length > 0 ? (
                      <List
                        disablePadding
                        dense
                        role="listbox"
                        aria-label="Available templates"
                      >
                        {filteredTemplates.map((template, index) => (
                          <React.Fragment key={template.id}>
                            {index > 0 && <Divider />}
                            <ListItem
                              sx={{
                                py: 0.5,
                                "&:hover": {
                                  backgroundColor: "action.hover",
                                },
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleGenerateDocument(template.id)
                              }
                              disabled={isLoading}
                              role="option"
                              aria-selected={selectedTemplateId === template.id}
                            >
                              <ListItemText
                                primary={template.name}
                                sx={{
                                  color: "text.primary",
                                  "& .MuiListItemText-primary": {
                                    fontSize: "0.8rem",
                                  },
                                }}
                              />
                            </ListItem>
                          </React.Fragment>
                        ))}
                      </List>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ p: 2 }}
                        >
                          No templates found. Click "Create" to generate a
                          custom document.
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            ) : (
              // Step 2: Document generation
              <Box
                role="region"
                aria-label="Document configuration"
                sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography
                    color="text.primary"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Document Template:{" "}
                  </Typography>
                  <Chip
                    label={
                      <Typography variant="caption" fontWeight="bold">
                        {selectedTemplateName}
                      </Typography>
                    }
                    color="secondary"
                    sx={{ borderRadius: "3px", p: 0.5 }}
                  />
                </Box>
                <Typography
                  color="text.primary"
                  variant="caption"
                  sx={{ pt: 2, pb: 0, mb: 0 }}
                >
                  {
                    "Additional context to include like dates, names, etc. (Optional)"
                  }
                </Typography>
                <TextField
                  multiline
                  minRows={1}
                  maxRows={2}
                  variant="outlined"
                  color="secondary"
                  value={additionalContext}
                  onChange={(e) => {
                    setAdditionalContext(e.target.value);
                    setError(null);
                  }}
                  error={!!error}
                  helperText={error || "Max 1000 characters"}
                  inputProps={{
                    maxLength: 1000,
                    "aria-label": "Additional context for document",
                  }}
                />

                {error && (
                  <Alert severity="error" sx={{ mt: 2 }} role="alert">
                    {error}
                  </Alert>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <Button onClick={handleBack}>Back</Button>
                  <Button
                    variant="contained"
                    fullWidth
                    disableElevation
                    onClick={handleFinalGenerate}
                    disabled={isLoading || !!error}
                  >
                    Create Document
                  </Button>
                </Box>
              </Box>
            )}

            {isLoading && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  p: 2,
                }}
                role="status"
                aria-live="polite"
              >
                <CircularProgress size={24} sx={{ mb: 1 }} />
                <Typography variant="body2" color="text.secondary">
                  {isCustomDocument
                    ? "Preparing a custom document (this may take a moment)"
                    : "Queuing up your document..."}
                </Typography>
              </Box>
            )}
          </Box>
        </Modal>

        <SnackbarSuccess
          open={openSnackbar}
          message="Content copied to clipboard"
          onClose={handleCloseSnackbar}
        />
      </CardContent>
    </Card>
  );
};

export default EncounterProcessor;
