import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Alert,
  Button,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

interface NotFoundProps {
  itemType: string;
  message?: string;
  backUrl?: string;
  backText?: string;
}

const NotFound: React.FC<NotFoundProps> = ({
  itemType,
  message = "The requested item could not be found. It might have been deleted or you may not have permission to view it.",
  backUrl = "/",
  backText = "Go Back",
}) => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="#f5f5f5"
    >
      <Card elevation={3} sx={{ maxWidth: 400, width: "100%", m: 2 }}>
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <Typography variant="h5" component="h1" gutterBottom>
              {itemType} Not Found
            </Typography>
            <Alert severity="warning" sx={{ mb: 2, width: "100%" }}>
              {message}
            </Alert>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              onClick={() => navigate(backUrl)}
            >
              {backText}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default NotFound;
