import * as Sentry from "@sentry/react";
import { Box, Typography, Button } from "@mui/material";

const ErrorFallback: Sentry.FallbackRender = ({ error, resetError }) => {
  // Helper function to safely get the error message
  const getErrorMessage = (error: unknown): string => {
    if (error instanceof Error) {
      return error.message;
    }
    return String(error);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
      padding={2}
    >
      <Typography variant="h4" gutterBottom>
        Oops! Something went wrong.
      </Typography>
      <Typography variant="body1" paragraph>
        We're sorry, but an unexpected error occurred. Our team has been
        notified and we're working to fix it.
      </Typography>
      <Typography variant="body2" color="error" paragraph>
        Error: {getErrorMessage(error)}
      </Typography>
      <Button variant="contained" color="primary" onClick={resetError}>
        Try Again
      </Button>
    </Box>
  );
};

export default ErrorFallback;
