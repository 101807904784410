import React, { useState, useEffect } from "react";
import { TextField, Box, Typography, Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

interface TemplateHeaderProps {
  name: string;
  description?: string;
  onNameChange: (value: string) => void;
  onDescriptionChange: (value: string) => void;
  nameError?: string;
}

const TemplateHeader: React.FC<TemplateHeaderProps> = ({
  name,
  description,
  onNameChange,
  onDescriptionChange,
  nameError,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(name);
  const [editedDescription, setEditedDescription] = useState(description || "");

  useEffect(() => {
    setEditedName(name);
    setEditedDescription(description || "");
  }, [name, description]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    onNameChange(editedName);
    onDescriptionChange(editedDescription);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedName(name);
    setEditedDescription(description || "");
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <Box>
        <TextField
          fullWidth
          label="Template Name"
          value={editedName}
          onChange={(e) => setEditedName(e.target.value)}
          margin="normal"
          required
          error={!!nameError}
          helperText={nameError || "Required"}
        />
        <TextField
          fullWidth
          label="Template Description"
          value={editedDescription}
          onChange={(e) => setEditedDescription(e.target.value)}
          margin="normal"
          multiline
          rows={3}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button onClick={handleCancel} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button onClick={handleSave} variant="text" color="primary">
            Save
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: "relative",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      }}
      onClick={handleEdit}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <Typography variant="h4" component="h1" sx={{ mr: 1 }}>
          {name || "Untitled Template"}
        </Typography>
        <IconButton size="small" aria-label="edit template details">
          <EditIcon />
        </IconButton>
      </Box>
      {description && (
        <Typography
          variant="body1"
          color="text.secondary"
          paragraph
          sx={{ px: 2 }} // Added horizontal padding
        >
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default TemplateHeader;
