import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  Checkbox,
  Button,
  CircularProgress,
  Card,
} from "@mui/material";
import { IEncounter } from "../../reusable-components/Sidebar";
import SnackbarError from "../../reusable-components/SnackBarError";
import SnackbarSuccess from "../../reusable-components/SnackBarSuccess";
import { useNavigate } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useUser } from "../../contexts/UserContext.tsx";

const RecentlyDeletedEncounters: React.FC = () => {
  const [deletedEncounters, setDeletedEncounters] = useState<IEncounter[]>([]);
  const [selectedEncounters, setSelectedEncounters] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [restoring, setRestoring] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState({
    open: false,
    message: "",
  });
  const [successSnackbar, setSuccessSnackbar] = useState({
    open: false,
    message: "",
  });
  const navigate = useNavigate();
  const { fetchWithAuth } = useUser();

  useEffect(() => {
    fetchDeletedEncounters();
  }, []);

  const fetchDeletedEncounters = async () => {
    try {
      setLoading(true);
      const response = await fetchWithAuth("GetEncounters?showDeleted=true");
      if (response.status === 403) {
        showErrorSnackbar(
          "You do not have permission to view deleted encounters",
        );
        return;
      }
      if (!response.ok) {
        throw new Error("Failed to fetch deleted encounters");
      }
      const data = await response.json();
      setDeletedEncounters(data);
    } catch (error) {
      console.error("Error fetching deleted encounters:", error);
      showErrorSnackbar("Failed to fetch deleted encounters");
    } finally {
      setLoading(false);
    }
  };

  const handleToggleEncounter = (encounterId: string) => {
    setSelectedEncounters((prev) =>
      prev.includes(encounterId)
        ? prev.filter((id) => id !== encounterId)
        : [...prev, encounterId],
    );
  };

  const handleRestoreEncounters = async () => {
    if (selectedEncounters.length === 0) {
      showErrorSnackbar("Please select encounters to restore");
      return;
    }

    try {
      setRestoring(true);
      const response = await fetchWithAuth("RestoreEncounters", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ encounterIds: selectedEncounters }),
      });

      if (response.status === 403) {
        showErrorSnackbar("You do not have permission to restore encounters");
        return;
      }

      if (!response.ok) {
        throw new Error("Failed to restore encounters");
      }

      const result = await response.json();
      showSuccessSnackbar(
        `Successfully restored ${result.restoredCount} encounter(s)`,
      );
      setSelectedEncounters([]);
      fetchDeletedEncounters(); // Refresh the list
    } catch (error) {
      console.error("Error restoring encounters:", error);
      showErrorSnackbar("Failed to restore encounters");
    } finally {
      setRestoring(false);
    }
  };

  const showErrorSnackbar = (message: string) => {
    setErrorSnackbar({ open: true, message });
  };

  const showSuccessSnackbar = (message: string) => {
    setSuccessSnackbar({ open: true, message });
  };

  const handleCloseErrorSnackbar = () => {
    setErrorSnackbar({ ...errorSnackbar, open: false });
  };

  const handleCloseSuccessSnackbar = () => {
    setSuccessSnackbar({ ...successSnackbar, open: false });
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString(undefined, {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <Card
      sx={{ p: 2, maxWidth: 800, minHeight: 400, maxHeight: "100vh" }}
      variant="outlined"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h6">Recently Deleted Encounters</Typography>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/settings")}
          variant="outlined"
        >
          Back to Settings
        </Button>
      </Box>
      <Typography variant="body2" sx={{ pb: 2 }}>
        Showing encounters deleted within the last 3 days
      </Typography>
      {loading ? (
        <Box display="flex" justifyContent="center" my={2}>
          <CircularProgress />
        </Box>
      ) : deletedEncounters.length === 0 ? (
        <Typography>No recently deleted encounters.</Typography>
      ) : (
        <>
          <List sx={{ maxHeight: 400, overflow: "auto" }}>
            {deletedEncounters.map((encounter) => (
              <ListItem
                key={encounter.id}
                dense
                button
                onClick={() => handleToggleEncounter(encounter.id)}
                sx={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  py: 1,
                }}
              >
                <Box display="flex" width="100%" alignItems="center">
                  <Checkbox
                    edge="start"
                    checked={selectedEncounters.includes(encounter.id)}
                    tabIndex={-1}
                    disableRipple
                  />
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{ fontWeight: "medium" }}
                  >
                    {encounter.title || "Untitled Encounter"}
                  </Typography>
                </Box>
                <Box pl={4} mt={0.5}>
                  <Typography variant="body2" color="text.secondary">
                    Created: {formatDate(encounter.startTime)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Deleted:{" "}
                    {encounter.deletedAt
                      ? formatDate(encounter.deletedAt)
                      : "N/A"}
                    {encounter.deletedBy && ` by ${encounter.deletedBy}`}
                  </Typography>
                </Box>
              </ListItem>
            ))}
          </List>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRestoreEncounters}
              disabled={selectedEncounters.length === 0 || restoring}
            >
              {restoring ? <CircularProgress size={24} /> : "Restore Selected"}
            </Button>
          </Box>
        </>
      )}
      <SnackbarError
        open={errorSnackbar.open}
        message={errorSnackbar.message}
        onClose={handleCloseErrorSnackbar}
      />
      <SnackbarSuccess
        open={successSnackbar.open}
        message={successSnackbar.message}
        onClose={handleCloseSuccessSnackbar}
      />
    </Card>
  );
};

export default RecentlyDeletedEncounters;
