import { Card, CardContent, Typography } from "@mui/material";

const LoadingCard = ({ loadingText = "Loading", helperText = "" }) => {
  return (
    <Card
      elevation={0}
      sx={{ display: "flex", alignItems: "center", p: 2, maxWidth: "500" }}
    >
      <img
        src="/attend.svg"
        alt="Loading Icon"
        className="rotating"
        style={{ width: 40, height: 40, marginRight: 16 }}
      />
      <CardContent sx={{ justifyContent: "left" }}>
        <Typography variant="h5" align="left" color="text.primary">
          {loadingText}
        </Typography>
        {helperText && (
          <Typography variant="caption" align="left" color="text.secondary">
            {helperText}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default LoadingCard;
