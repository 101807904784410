import { useState, useEffect } from "react";

const useAudioLevel = (
  isRecording: boolean,
  audioAnalyser: AnalyserNode | null,
) => {
  const [audioLevel, setAudioLevel] = useState(0);

  useEffect(() => {
    if (isRecording && audioAnalyser) {
      const bufferLength = audioAnalyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const updateAudioLevel = () => {
        audioAnalyser.getByteFrequencyData(dataArray);

        // Calculate the average of the frequency data
        const sum = dataArray.reduce((acc, value) => acc + value, 0);
        const avg = sum / bufferLength;

        // Simple normalization without any smoothing
        const normalizedLevel = Math.min(avg / 255, 1);

        setAudioLevel(normalizedLevel);
      };

      const intervalId = setInterval(updateAudioLevel, 20); // Even more frequent updates

      return () => clearInterval(intervalId);
    }
  }, [isRecording, audioAnalyser]);

  return audioLevel;
};

export default useAudioLevel;
