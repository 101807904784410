import { useEffect, useState } from "react";
import { useUser } from "../contexts/UserContext";

const useMonitorUserConsentAndAccess = () => {
  const { userData, checkUserConsent, evaluateUserAccess } = useUser();
  const [consentRequired, setConsentRequired] = useState(false);
  const [hasAccess, setHasAccess] = useState(true);

  useEffect(() => {
    if (userData) {
      if (!checkUserConsent()) {
        setConsentRequired(true);
      } else {
        const access = evaluateUserAccess();
        setHasAccess(access);
        setConsentRequired(false);
      }
    }
  }, [userData, checkUserConsent, evaluateUserAccess]);

  return { consentRequired, hasAccess };
};

export default useMonitorUserConsentAndAccess;
