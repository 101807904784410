import React from "react";
import { Box, Container, Grid, Typography, Paper } from "@mui/material";
import NoteIcon from "@mui/icons-material/NoteAdd";
import SpeedIcon from "@mui/icons-material/Speed";
import Fingerprint from "@mui/icons-material/Fingerprint";

const features = [
  {
    icon: <SpeedIcon />,
    title: "Simple and efficient",
    description: `Get started in seconds with an AI Scribe. No complicated set up or integration needed.`,
  },
  {
    icon: <Fingerprint />,
    title: "Learns your style",
    description:
      "Attend efficiently learns to write notes exactly how you want them. Sound like yourself while delegating the work.",
  },
  {
    icon: <NoteIcon />,
    title: "Patient-friendly instructions",
    description: `Create personalized after visit instructions without the effort. Help your patients stay engaged and on track.`,
  },
];

const Features: React.FC = () => {
  return (
    <Box sx={{ py: 8, backgroundColor: "#101A2F" }}>
      <Container>
        <Typography
          variant="h3"
          color="white"
          fontWeight={300}
          component="h1"
          gutterBottom
        >
          The Intuitive AI Assistant for Clinical Professionals
        </Typography>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Paper
                elevation={3}
                sx={{ p: 4, textAlign: "center", height: 250 }}
              >
                {feature.icon}
                <Typography variant="h6" component="h2" gutterBottom>
                  {feature.title}
                </Typography>
                <Typography component="h3">{feature.description}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Features;
