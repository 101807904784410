import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
//import { useNavigate } from 'react-router-dom';

const steps = [
  {
    title: "Attend listens and transcribes as you speak to your patient",
    image: "1-record.jpg", // Replace with actual image path
  },
  {
    title:
      "After your visit, Attend builds a clinical note and patient-friendly instructions",
    image: "2-edit.jpg", // Replace with actual image path
  },
];

const HowItWorks: React.FC = () => {
  /*const navigate = useNavigate();

  const handleDemoClick = () => {
    navigate('/demo');
  };*/

  return (
    <Box sx={{ py: 6, px: 2, backgroundColor: "#FFFFFF" }}>
      <Typography
        variant="h3"
        component="h2"
        align="center"
        gutterBottom
        sx={{ mb: 4, fontWeight: 700 }}
      >
        How It Works
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {steps.map((step, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                transition: "all 0.3s ease-in-out",

                "&:hover": {
                  transform: "translateY(-4px)",
                  boxShadow: 6,
                },
              }}
            >
              <CardContent
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                  p: 4,
                  backgroundColor: "rgba(255, 255, 255, 0.7)", // Add slight white overlay for better text contrast
                }}
              >
                <Typography
                  variant="h5"
                  component="h3"
                  sx={{ fontWeight: 600, mb: 3, color: "#333" }}
                >
                  {step.title}
                </Typography>
              </CardContent>
              <CardMedia
                component="img"
                height="300"
                image={step.image}
                alt={step.title}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
      {/*<Box display="flex" justifyContent="center" mt={6}>
        <Button 
          variant="contained" 
          color="primary" 
          size="large"
          onClick={handleDemoClick}
          sx={{ 
            py: 1.5, 
            px: 4, 
            fontSize: '1.1rem',
            
          }}
        >
          Give it a try
        </Button>
      </Box>*/}
    </Box>
  );
};

export default HowItWorks;
