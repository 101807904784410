import React from "react";
import { Box, Button, Typography, Container } from "@mui/material";
//import { useNavigate } from 'react-router-dom';

interface HeroProps {
  handleLogin: () => void;
  handleSignUp: () => void;
}

const Hero: React.FC<HeroProps> = ({ handleLogin, handleSignUp }) => {
  // const navigate = useNavigate();
  // const [currentWordIndex, setCurrentWordIndex] = useState(0);
  // const [fade, setFade] = useState(true);

  // useEffect(() => {
  //   const wordInterval = setInterval(() => {
  //     setFade(false);
  //     setTimeout(() => {
  //       setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
  //       setFade(true);
  //     }, 500); // Wait for fade out before changing word
  //   }, 3000); // Change word every 3 seconds

  //   return () => clearInterval(wordInterval);
  // }, []);

  const handleTryItClick = () => {
    handleSignUp();
  };

  return (
    <Box
      sx={{
        position: "relative",
        minHeight: "70vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        background: 'url("/hero3c.jpeg") no-repeat center center/cover',
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.6)", // Darker overlay for better readability
          zIndex: 1,
        },
      }}
    >
      <Container sx={{ zIndex: 2, position: "relative", py: 4 }}>
        <Typography
          variant="h2"
          fontWeight={700}
          color="white"
          paragraph
          sx={{ mb: 2 }}
        >
          Attend to your <span>patients,</span>
        </Typography>
        <Typography
          variant="h2"
          fontWeight={700}
          color="white"
          paragraph
          sx={{ mb: 2 }}
        >
          not your notes
        </Typography>
        <Typography
          variant="h5"
          component="h1"
          fontWeight={400}
          color="white"
          paragraph
          sx={{ mb: 4, maxWidth: "800px" }}
        >
          AI that does your charting, so you can focus on what matters most
        </Typography>

        <Box sx={{ display: "flex", gap: 2, maxWidth: 400, mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ flex: 2, py: 1.5, fontSize: "1.1rem" }}
            onClick={handleTryItClick}
            aria-label="Start Your Free Trial"
          >
            Try it for Free
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            sx={{
              flex: 1,
              py: 1.5,
              fontSize: "1.1rem",
              borderColor: "white",
              color: "white",
            }}
            onClick={handleLogin}
          >
            Log In
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
