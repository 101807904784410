import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import TemplateSelector from "./TemplateSelector";
import { useUser } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";

interface ChangeTemplateDialogProps {
  open: boolean;
  onClose: () => void;
  selectedTemplate: string;
  onTemplateChange: (templateId: string) => void;
  isTemplateSelectLocked: boolean;
}

const ChangeTemplateDialog: React.FC<ChangeTemplateDialogProps> = ({
  open,
  onClose,
  selectedTemplate,
  onTemplateChange,
  isTemplateSelectLocked,
}) => {
  const [tempSelectedTemplate, setTempSelectedTemplate] =
    useState(selectedTemplate);
  const { userData } = useUser();
  const navigate = useNavigate();

  const handleTemplateChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setTempSelectedTemplate(event.target.value as string);
  };

  const handleApply = () => {
    onTemplateChange(tempSelectedTemplate);
    onClose();
  };

  const handleCancel = () => {
    setTempSelectedTemplate(selectedTemplate);
    onClose();
  };

  const hasTemplates = userData?.templates && userData.templates.length > 0;

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth>
      <DialogTitle>Change Note Template</DialogTitle>
      <DialogContent>
        {hasTemplates ? (
          <>
            <TemplateSelector
              selectedTemplate={tempSelectedTemplate}
              handleTemplateChange={handleTemplateChange}
              isTemplateSelectLocked={isTemplateSelectLocked}
            />
            <Box sx={{ mt: 2, mb: 2 }}>
              <Typography variant="body2" gutterBottom>
                Select a template to regenerate your note. This will replace the
                current encounter note.
              </Typography>
            </Box>
          </>
        ) : (
          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography variant="body2" gutterBottom>
              You don't have any custom note templates set up yet.
            </Typography>
            <Button
              variant="text"
              onClick={() => {
                navigate("/template-library");
                onClose();
              }}
            >
              Manage templates
            </Button>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Close</Button>
        {hasTemplates && (
          <Button
            onClick={handleApply}
            color="primary"
            variant="contained"
            disabled={
              tempSelectedTemplate === selectedTemplate ||
              isTemplateSelectLocked
            }
          >
            Apply Changes
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ChangeTemplateDialog;
