import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  LinearProgress,
  Link,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import Template from "./Template";
import fetchAPI from "../../utilities/fetchAPI";
import { oktaAuthClient } from "./OctaveLogin";
import ValidateLogin from "./ValidateLogin";
import { useLocation } from "react-router-dom";

const Login: React.FC = () => {
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const location = useLocation();
  const [email, setEmail] = useState(location.state?.email || "");
  const navigate = useNavigate();

  React.useEffect(() => {
    if (location.state?.message) {
      setError(location.state.message);
    }
  }, [location]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setError(undefined);
  };

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email) {
      setError("Email is required");
      return;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setError("Value must be a valid email address");
      return;
    }

    setError(undefined);
    setIsLoading(true);

    fetchAPI("SignIn", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then(async (r) => {
        if (!r.ok) {
          const errorData = await r.json();
          if (r.status === 404) {
            throw new Error(
              errorData.message || "No account exists with this email",
            );
          }
          throw new Error(
            "We're unable to log you in at this time. Please try again. If the error persists, contact support@crafthealthtech.com",
          );
        }

        const response = await r.json();
        if (response.redirectToSso) {
          await oktaAuthClient.signInWithRedirect();
        } else {
          setIsEmailSent(true);
        }
      })
      .catch((e) => {
        if (e.message.includes("No account exists")) {
          setError(e.message);
        } else {
          setError(
            "We're unable to log you in at this time. Please try again. If the error persists, contact support@crafthealthtech.com",
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return !isEmailSent ? (
    <Template
      boxSx={{
        width: {
          md: "400px",
          xs: "100%",
        },
      }}
    >
      <Typography
        variant="body2"
        color="text.primary"
        sx={{ mt: 2, mb: 0, pb: 0, alignSelf: "flex-start" }}
      >
        Email Address
      </Typography>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleLogin}
        style={{
          width: "100%",
          textAlign: "center",
        }}
      >
        <TextField
          required
          name="email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={handleEmailChange}
          margin="dense"
          sx={{
            height: { xs: "36px", sm: "42px" },
            "& .MuiInputBase-root": {
              height: { xs: "36px", sm: "42px" },
              fontSize: { xs: "14px", sm: "16px" },
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "5px",
            },
            mb: 1,
          }}
          error={!!error && !error.includes("No account exists")}
        />

        {error && (
          <Alert
            severity={
              error.includes("No account exists") ||
              error.includes("already exists")
                ? "info"
                : "error"
            }
            sx={{
              mt: 1,
              mb: 2,
              "& .MuiAlert-message": {
                width: "100%",
              },
            }}
          >
            {error.includes("No account exists") ? (
              <>
                We couldn't find an existing account for {email}.{" "}
                <Link
                  component="button"
                  onClick={() => navigate("/signup", { state: { email } })}
                  sx={{
                    textDecoration: "none",
                    cursor: "pointer",
                    fontWeight: "500",
                  }}
                >
                  Sign up with {email}
                </Link>
              </>
            ) : (
              error
            )}
          </Alert>
        )}

        {isLoading && <LinearProgress sx={{ mt: 2 }} />}

        <Button
          type="submit"
          size="large"
          fullWidth
          disableElevation
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
            height: { xs: "36px", sm: "42px" },
            fontSize: { xs: "14px", sm: "16px" },
            borderRadius: "3px",
          }}
          disabled={isLoading}
        >
          Sign In
        </Button>
      </form>
      <Box component="div">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 3,
          }}
        >
          <Typography variant="body2" color="text.primary" sx={{ mr: 1 }}>
            New to Attend?
          </Typography>
          <Button
            variant="text"
            size="small"
            onClick={() => navigate("/signup")}
            disabled={isLoading}
          >
            Sign up for free
          </Button>
        </Box>
      </Box>
    </Template>
  ) : (
    <Template
      boxSx={{
        width: {
          sm: "700px",
          xs: "100%",
        },
      }}
    >
      <ValidateLogin email={email} />
      <Button
        fullWidth
        variant="outlined"
        onClick={() => setIsEmailSent(false)}
        sx={{
          borderRadius: "3px",
        }}
        size="large"
      >
        Send a New One-Time Password
      </Button>
    </Template>
  );
};

export default Login;
