import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { Alert, Box, Card, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import FlexibleDocumentationDetails from "./FlexibleDocumentationDetails";
import EncounterDetailHeader from "./EncounterDetailHeader";
import {
  useSignalR,
  FlexibleEncounterData,
  NoteContent,
  IEncounterDocument,
} from "../../contexts/SignalRContext";
import useDebounce from "../../hooks/useDebounce";
import LoadingCard from "../../reusable-components/LoadingCard";
import EncounterProcessor from "./EncounterProcessor";
import NotFound from "../../reusable-components/NotFoundDisplay";
import EncounterRecorder from "../../reusable-components/EncounterRecorder";
import Transcript from "./Transcript";
import SnackbarError from "../../reusable-components/SnackBarError";
import { useUser } from "../../contexts/UserContext";
import * as Sentry from "@sentry/react";

const MAX_RETRY_ATTEMPTS = 3;
//const RETRY_INTERVAL = 120000; // 2 minutes in milliseconds

const FlexibleEncounter: React.FC = () => {
  const { encounterId } = useParams<{ encounterId: string }>();
  const [encounterData, setEncounterData] =
    useState<FlexibleEncounterData | null>(null);
  const [encounterDocuments, setEncounterDocuments] = useState<
    IEncounterDocument[]
  >([]);
  const [noteContent, setNoteContent] = useState<NoteContent>({});
  const [updatingDetails, setUpdatingDetails] = useState(false);
  const [updatingNote, setUpdatingNote] = useState(false);
  const [pendingUpdates, setPendingUpdates] = useState<NoteContent>({});
  const [updatingPatientInstructions, setUpdatingPatientInstructions] =
    useState(false);
  const [encounterTitle, setEncounterTitle] = useState("");
  const [patientDetails, setPatientDetails] = useState("");
  const [awaitingRevision, setAwaitingRevision] = useState(false);

  const [hasLoadingFailed, setHasLoadingFailed] = useState(false);
  const [patientInstructions, setPatientInstructions] = useState("");
  const { addEncounterUpdateListener, removeEncounterUpdateListener } =
    useSignalR();
  const [retryError, setRetryError] = useState<string | null>(null);
  const retryTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const retryCountRef = useRef<number>(0);
  const transcriptStatusRef = useRef<"undefined" | "available">("undefined");
  const [isPolling, setIsPolling] = useState(false);
  const initialEncounterDataRef = useRef<FlexibleEncounterData | null>(null);
  const isInitialLoadDoneRef = useRef<boolean>(false);
  const { fetchWithAuth } = useUser();

  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    let pollInterval: NodeJS.Timeout | null = null;

    const shouldPoll =
      encounterData?.status === "completed" &&
      transcriptStatusRef.current === "undefined";

    if (shouldPoll && !isPolling) {
      setIsPolling(true);
    } else if (!shouldPoll && isPolling) {
      setIsPolling(false);
    }

    if (isPolling) {
      pollInterval = setInterval(() => {
        loadEncounterData();
      }, 30000); // Poll every 30 seconds
    }

    return () => {
      if (pollInterval) {
        clearInterval(pollInterval);
      }
    };
  }, [encounterData, isPolling]);

  const loadEncounterData = async () => {
    try {
      const response = await fetchWithAuth(
        `GetEncounterOutcome?encounterId=${encounterId}`,
      );
      const data = (await response.json()) as FlexibleEncounterData;
      setEncounterData({
        ...data,
        encounterDocuments: undefined, // Remove from main state
      });
      if (data.encounterDocuments) {
        setEncounterDocuments(data.encounterDocuments);
      } else {
        setEncounterDocuments([]); // Ensure encounterDocuments is empty if none are returned
      }
      setNoteContent(data.noteContent);
      setEncounterTitle(data.title || "");
      setPatientDetails(data.patientDetails || "");
      setPatientInstructions(data.patientInstructions);
      setAwaitingRevision(false);

      // Only set initialEncounterDataRef if it hasn't been set yet
      if (!isInitialLoadDoneRef.current) {
        initialEncounterDataRef.current = data;
        isInitialLoadDoneRef.current = true;
      }

      //setErrors(data.errors || []);
      //console.log("loading encounter data:", data);
      if (data.transcript !== undefined) {
        transcriptStatusRef.current = "available";
        retryCountRef.current = 0;
        setIsPolling(false);
      } else {
        transcriptStatusRef.current = "undefined";
      }

      // Check if it's been over 2 minutes since the encounter ended
      if (data.status === "completed" && data.endTime) {
        const endTime = new Date(data.endTime).getTime();
        const currentTime = Date.now();
        const timeSinceEnd = currentTime - endTime;

        if (
          timeSinceEnd > 120000 &&
          transcriptStatusRef.current === "undefined"
        ) {
          // 120000 ms = 2 minutes
          await retryBuildTranscript();
        }
      }
    } catch (error) {
      console.error("Error loading encounter data:", error);
      setHasLoadingFailed(true);
      setIsPolling(false);
      setEncounterDocuments([]); // Reset encounterDocuments on error
    }
  };

  useEffect(() => {
    if (encounterId) {
      setHasLoadingFailed(false);
      setEncounterData(null);
      setEncounterDocuments([]); // Reset encounterDocuments when encounterId changes
      transcriptStatusRef.current = "undefined";
      isInitialLoadDoneRef.current = false; // Reset this when encounterId changes
      initialEncounterDataRef.current = null; // Reset this when encounterId changes
      loadEncounterData();

      const handleEncounterSignalRUpdate = (
        updatedDataArray: FlexibleEncounterData[],
      ) => {
        if (Array.isArray(updatedDataArray)) {
          const updatedEncounterOutcome = updatedDataArray.find(
            (outcome) => outcome.encounterId === encounterId,
          );
          console.log("SignalR update: got encounter data");
          if (updatedEncounterOutcome) {
            setEncounterData((prevData) => ({
              ...prevData,
              ...updatedEncounterOutcome,
              encounterDocuments: undefined, // Remove from main state
            }));

            if (
              updatedEncounterOutcome.encounterDocuments &&
              Array.isArray(updatedEncounterOutcome.encounterDocuments)
            ) {
              setEncounterDocuments((prevDocuments) => {
                const updatedDocs = updatedEncounterOutcome.encounterDocuments;
                if (!updatedDocs) return prevDocuments;
                const newDocuments = updatedDocs.filter(
                  (updatedDoc) =>
                    !prevDocuments.some((doc) => doc.id === updatedDoc.id),
                );
                const updatedPrevDocuments = prevDocuments.map(
                  (doc) =>
                    updatedDocs.find(
                      (updatedDoc) => updatedDoc.id === doc.id,
                    ) || doc,
                );
                return [...updatedPrevDocuments, ...newDocuments];
              });
            }

            if (!updatingDetails) {
              if (updatedEncounterOutcome.title) {
                setEncounterTitle(updatedEncounterOutcome.title);
              }
              if (updatedEncounterOutcome.patientDetails) {
                setPatientDetails(updatedEncounterOutcome.patientDetails);
              }
            }

            if (!updatingNote) {
              if (updatedEncounterOutcome.noteContent) {
                setNoteContent(updatedEncounterOutcome.noteContent);
              }
            }

            if (
              updatedEncounterOutcome.patientInstructions !== undefined &&
              !updatingPatientInstructions
            ) {
              setPatientInstructions(
                updatedEncounterOutcome.patientInstructions,
              );
            }
            setAwaitingRevision(
              updatedEncounterOutcome.noteStatus?.status === "awaitingRevision",
            );

            //if(updatedEncounterOutcome.errors) setErrors(updatedEncounterOutcome.errors || []);

            if (updatedEncounterOutcome.transcript !== undefined) {
              transcriptStatusRef.current = "available";
              retryCountRef.current = 0;
              setIsPolling(false);
            }
          }
        } else {
          console.error(
            "Invalid encounter data format received from SignalR update.",
          );
          Sentry.captureException(
            new Error("Invalid encounter data format from SignalR"),
            {
              extra: {
                dataType: typeof updatedDataArray,
                isArray: Array.isArray(updatedDataArray),
                objectKeys:
                  updatedDataArray && typeof updatedDataArray === "object"
                    ? Object.keys(updatedDataArray)
                    : null,
              },
              tags: {
                context: "SignalR Update",
                encounterId: encounterId,
              },
            },
          );
        }
      };

      addEncounterUpdateListener(`encounter`, handleEncounterSignalRUpdate);

      return () => {
        removeEncounterUpdateListener(
          "encounter",
          handleEncounterSignalRUpdate,
        );
        if (retryTimeoutRef.current) {
          clearTimeout(retryTimeoutRef.current);
        }
      };
    }
  }, [encounterId]);

  const debouncedNoteUpdate = useDebounce((updates: NoteContent) => {
    if (encounterData) {
      // Merge the updates with the existing note content
      const completeNoteContent = {
        ...noteContent,
        ...updates,
      };

      const requestBody = {
        encounterId: encounterData.encounterId,
        noteContent: completeNoteContent,
      };

      fetchWithAuth("UpdateNoteContent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => {
          if (response.ok) {
            console.log("Note content updated successfully", requestBody);
            setNoteContent(completeNoteContent);
          } else {
            console.error("Error updating note content:", response.status);
          }
        })
        .catch((error) => {
          console.error("Error updating note content:", error);
        });
    }
    setUpdatingNote(false);
    setPendingUpdates({});
  }, 3000);

  const debouncedPatientInstructionsUpdate = useDebounce(
    (patientInstructions) => {
      if (encounterData) {
        const requestBody = {
          encounterId: encounterData.encounterId,
          patientInstructions: patientInstructions,
        };
        fetchWithAuth("UpdatePatientInstructions", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        })
          .then((response) => {
            if (response.ok) {
              console.log(
                "Patient Instructions updated successfully",
                requestBody,
              );
            } else {
              console.error(
                "Error updating patient instructions:",
                response.status,
              );
              // Add Sentry logging for non-OK responses
              Sentry.captureException(
                new Error(
                  `Failed to update patient instructions: ${response.status}`,
                ),
                {
                  extra: {
                    encounterId: encounterData.encounterId,
                    responseStatus: response.status,
                  },
                  tags: {
                    context: "UpdatePatientInstructions",
                  },
                },
              );
            }
          })
          .catch((error) => {
            console.error("Error updating patient instructions:", error);
            // Add Sentry error logging
            Sentry.captureException(error, {
              extra: {
                encounterId: encounterData.encounterId,
              },
              tags: {
                context: "UpdatePatientInstructions",
              },
            });
          });
      }
      setUpdatingPatientInstructions(false);
    },
    3000,
  );

  const handleNoteContentChange = useCallback(
    (sectionTitle: string, content: string) => {
      setPendingUpdates((prev) => ({
        ...prev,
        [sectionTitle]: { content, isAIPrefilled: false },
      }));
      setUpdatingNote(true);
    },
    [],
  );

  useEffect(() => {
    if (Object.keys(pendingUpdates).length > 0) {
      debouncedNoteUpdate(pendingUpdates);
    }
  }, [pendingUpdates, debouncedNoteUpdate]);

  const handlePatientInstructionChange = useCallback(
    (content: string) => {
      setPatientInstructions(content);
      setUpdatingPatientInstructions(true);
      debouncedPatientInstructionsUpdate(content);
    },
    [debouncedPatientInstructionsUpdate],
  );

  const debouncedUpdateDetails = useDebounce((title, details) => {
    const requestBody = {
      encounterId: encounterId,
      encounterTitle: title,
      patientDetails: details,
    };

    fetchWithAuth("UpdateEncounterDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Details updated successfully", requestBody);
        } else {
          console.error("Error updating details:", response.status);
          // Add Sentry logging for non-OK responses
          Sentry.captureException(
            new Error(`Failed to update encounter details: ${response.status}`),
            {
              extra: {
                encounterId: encounterId,
                responseStatus: response.status,
                requestBody: requestBody,
              },
              tags: {
                context: "UpdateEncounterDetails",
              },
            },
          );
        }
      })
      .catch((error) => {
        console.error("Error updating details:", error);
        Sentry.captureException(error, {
          extra: {
            encounterId: encounterId,
            requestBody: requestBody,
          },
          tags: {
            context: "UpdateEncounterDetails",
          },
        });
      });
    setUpdatingDetails(false);
  }, 3000);

  const handleEncounterNameChange = useCallback(
    (name: string) => {
      setEncounterTitle(name);
      setUpdatingDetails(true);
      debouncedUpdateDetails(name, patientDetails);
    },
    [debouncedUpdateDetails, patientDetails],
  );

  const handlePatientDetailsChange = useCallback(
    (details: string) => {
      setPatientDetails(details);
      setUpdatingDetails(true);
      debouncedUpdateDetails(encounterTitle, details);
    },
    [debouncedUpdateDetails, encounterTitle],
  );

  const handleTemplateChange = useCallback(
    (templateId: string) => {
      if (!encounterData) return;

      // Optimistically update the local state
      const originalNoteStatus = encounterData.noteStatus;
      const originalTemplateId = encounterData.noteTemplateId;

      setEncounterData((prevData) => ({
        ...prevData!,
        noteStatus: { status: "queued", timestamp: new Date().toISOString() },
        noteTemplateId: templateId,
      }));

      // Make the API call to regenerate documentation
      fetchWithAuth("RegenerateDocumentation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          encounterId: encounterData.encounterId,
          templateId,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            // Add Sentry logging for non-OK responses
            Sentry.captureException(
              new Error(`Failed to change template: ${response.status}`),
              {
                extra: {
                  encounterId: encounterId,
                  responseStatus: response.status,
                },
                tags: {
                  context: "ChangeTemplate",
                },
              },
            );
          }
        })
        .catch((error) => {
          console.error("Error changing template:", error);
          Sentry.captureException(error, {
            extra: {
              encounterId: encounterId,
            },
            tags: {
              context: "ChangeTemplate",
            },
          });
          // Revert the optimistic update
          setEncounterData((prevData) => ({
            ...prevData!,
            noteStatus: originalNoteStatus,
            noteTemplateId: originalTemplateId,
          }));
          setSnackbarMessage("Failed to change template. Please try again.");
          setErrorSnackbarOpen(true);
        });
    },
    [encounterData],
  );

  const handleErrorSnackbarClose = () => {
    setErrorSnackbarOpen(false);
  };

  interface DateTimeFormattingOptions {
    year: "numeric" | "2-digit";
    month: "numeric" | "2-digit" | "narrow" | "short" | "long";
    day: "numeric" | "2-digit";
    hour: "numeric" | "2-digit";
    minute: "numeric" | "2-digit";
    hour12: boolean;
  }

  const formatDateTime = (
    dateTimeString: string,
    options?: Partial<DateTimeFormattingOptions>,
  ): string => {
    const date = new Date(dateTimeString);
    const defaultOptions: DateTimeFormattingOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formatOptions = { ...defaultOptions, ...options };
    return date.toLocaleString(undefined, formatOptions);
  };

  const renderEncounterContent = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <EncounterDetailHeader
          encounterTitle={encounterTitle}
          patientDetails={patientDetails}
          totalDurationInSeconds={encounterData!.totalDurationInSeconds}
          encounterStartDate={formatDateTime(encounterData!.startTime)}
          onEncounterNameChange={handleEncounterNameChange}
          onPatientDetailsChange={handlePatientDetailsChange}
        />
      </Grid>

      {transcriptStatusRef.current === "available" ? (
        encounterData!.noteStatus?.status === "failed" ? (
          <Grid item xs={12}>
            <Card elevation={0}>
              <Alert sx={{ m: 2 }} severity="error">
                Something went wrong when generating a note. We have sent a
                report to our technical team.
              </Alert>
              <Transcript
                transcript={encounterData!.transcript}
                locale={encounterData?.locale}
              />
            </Card>
          </Grid>
        ) : encounterData!.noteStatus?.status ===
          "abortedDueToShortTranscript" ? (
          <Grid item xs={12}>
            <Card elevation={0}>
              <Alert sx={{ m: 2 }} severity="info">
                The transcript was too short to create a note. Please start a
                new encounter.
              </Alert>
              <Transcript
                transcript={encounterData!.transcript}
                locale={encounterData?.locale}
              />
            </Card>
          </Grid>
        ) : encounterData!.noteStatus?.status === "queued" ||
          !noteContent ||
          Object.keys(noteContent).length === 0 ? (
          <Grid item xs={12}>
            <LoadingCard
              loadingText="Preparing note"
              helperText="You can start a new encounter while you wait"
            />
          </Grid>
        ) : (
          <Grid item xs={12} lg={6}>
            <FlexibleDocumentationDetails
              encounterData={encounterData!}
              noteContent={noteContent}
              onNoteContentChange={handleNoteContentChange}
              awaitingRevision={awaitingRevision}
              setAwaitingRevision={setAwaitingRevision}
              onTemplateChange={handleTemplateChange}
            />
          </Grid>
        )
      ) : retryError ? (
        <Grid item xs={12}>
          <Alert severity="error" sx={{ mb: 2 }}>
            {retryError}
          </Alert>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <LoadingCard
            loadingText="Transcribing"
            helperText="You can start a new encounter while you wait"
          />
        </Grid>
      )}
      {noteContent && Object.keys(noteContent).length > 0 && (
        <Grid item sm={12} xs={12} md={12} lg={6}>
          <EncounterProcessor
            encounterData={encounterData!}
            encounterDocuments={encounterDocuments}
            setEncounterDocuments={setEncounterDocuments}
            patientInstructions={patientInstructions}
            setPatientInstructions={handlePatientInstructionChange}
          />
        </Grid>
      )}
    </Grid>
  );

  const retryBuildTranscript = useCallback(async () => {
    if (retryCountRef.current >= MAX_RETRY_ATTEMPTS) {
      setRetryError("Maximum retry attempts reached. Please contact support.");
      return;
    }

    try {
      const response = await fetchWithAuth(
        `RetryBuildTranscript?encounterId=${encounterId}`,
        {
          method: "POST",
        },
      );

      if (response.ok) {
        setRetryError(null);
      } else {
        // Add Sentry logging for non-OK responses
        Sentry.captureException(
          new Error(`Failed to retry building transcript: ${response.status}`),
          {
            extra: {
              encounterId: encounterId,
              responseStatus: response.status,
            },
            tags: {
              context: "RetryBuildTranscript",
            },
          },
        );
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          encounterId: encounterId,
        },
        tags: {
          context: "RetryBuildTranscript",
        },
      });
      console.error("Error retrying transcript build:", error);
      setRetryError("Audio transcription failed. Please try again later.");
    }

    retryCountRef.current += 1;
  }, [encounterId]);

  const handleRecordingFinished = useCallback(() => {
    if (encounterData) {
      // console.log("handleRecordingFinished - setting status to completed");
      setEncounterData((prevData) => ({
        ...prevData!,
        status: "completed",
      }));
    }
  }, [encounterData]);

  const memoizedEncounterRecorder = useMemo(() => {
    if (initialEncounterDataRef.current) {
      return (
        <EncounterRecorder
          key={encounterId}
          initialEncounterId={encounterId}
          initialDuration={
            initialEncounterDataRef.current.totalDurationInSeconds
          }
          initialPartNumber={initialEncounterDataRef.current.lastTranscriptPart}
          onRecordingFinished={handleRecordingFinished}
          initialLocale={encounterData?.locale}
          initialTemplateId={encounterData?.noteTemplateId}
        />
      );
    }
    return null;
  }, [initialEncounterDataRef.current]);

  return (
    <>
      {hasLoadingFailed ? (
        <NotFound itemType="Encounter" />
      ) : encounterData && isInitialLoadDoneRef.current ? (
        encounterData.status === "in progress" ? (
          memoizedEncounterRecorder
        ) : (
          renderEncounterContent()
        )
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <LoadingCard loadingText="Loading Encounter" />
        </Box>
      )}

      <SnackbarError
        open={errorSnackbarOpen}
        message={snackbarMessage}
        onClose={handleErrorSnackbarClose}
      />
    </>
  );
};

export default FlexibleEncounter;
