import React from "react";
import { Box, Container, Typography, Paper } from "@mui/material";

const Security: React.FC = () => {
  return (
    <Box sx={{ py: 8, backgroundColor: "#101A2F" }}>
      <Container>
        <Typography
          variant="h4"
          component="h2"
          color="#FFFFFF"
          gutterBottom
          textAlign="center"
        >
          Security and Compliance
        </Typography>
        <Paper elevation={3} sx={{ p: 4, textAlign: "left" }}>
          <Typography variant="body1" gutterBottom>
            Attend is HIPAA compliant and adheres to enterprise-grade security
            standards with Microsoft Azure. We don't store recordings. All data
            is encrypted in transit and at rest. We'll never use your data to
            train models or share with 3rd parties.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <img
              src="HIPAA.png"
              alt="HIPAA Compliance"
              style={{ marginRight: "1rem", height: "100px" }}
            />
            <img
              src="/azure.png"
              alt="Microsoft Azure Security"
              style={{ marginLeft: "1rem", height: "100px" }}
            />
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Security;
