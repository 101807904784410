import React, { useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Hero from "./Hero";
import Features from "./Features";
import About from "./About";
import Pricing from "./Pricing";
import Security from "./Security";
import SignUp from "./SignUp";
import Footer from "./Footer";
import { AppBar, Toolbar, Typography } from "@mui/material";
import HowItWorks from "./HowItWorks";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../contexts/UserContext.tsx";
//import Testimonials from './Testimonials';

const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useUser();
  useEffect(() => {
    const handleLoginResponse = async () => {
      try {
        // const response = await instance.handleRedirectPromise();
        if (isLoggedIn) {
          // instance.setActiveAccount(response.account);
          navigate("/");
        }
        // else if (accounts.length > 0) {
        //   instance.setActiveAccount(accounts[0]);
        navigate("/");
        // }
      } catch (error) {
        console.error(error);
      }
    };

    handleLoginResponse();
  }, [isLoggedIn, navigate]);

  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    name: "Attend Health",
    url: "https://www.attend.health",
    description:
      "Fast, easy, and accurate AI scribe that automates clinical documentation and patient instructions. Helping clinicians save hours every day and improve the patient experience.",
    operatingSystem: "Windows, macOS, iOS, Web",
    applicationCategory: "MedicalApplication",
    offers: {
      "@type": "Offer",
      price: "89.00",
      priceCurrency: "USD",
    },
    image: "https://www.attend.health/hero3c.jpeg",
    author: {
      "@type": "Organization",
      name: "Craft Health Technologies",
    },
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleSignUp = () => {
    navigate("/signup");
  };

  return (
    <>
      <Helmet>
        <title>Attend | AI Scribe for Clinicians</title>
        <meta
          name="description"
          content="Reduce documentation time with Attend's AI scribe software. Improve patient care and combat physician burnout with automated clinical notes and instructions."
        />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Attend | AI Scribe for Clinicians" />
        <meta
          property="og:description"
          content="Reduce documentation time and improve patient care with Attend's AI scribe software."
        />
        <meta
          property="og:image"
          content="https://www.attend.health/hero3.jpg"
        />
        <meta property="og:url" content="https://www.attend.health" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Attend | Intuitive, accurate AI Scribe for Clinicians"
        />
        <meta
          name="twitter:description"
          content="Reduce documentation time and improve patient care with Attend's AI scribe software."
        />
        <meta
          name="twitter:image"
          content="https://www.attend.health/hero3.jpg"
        />

        {/* JSON-LD Structured Data */}
        <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
      </Helmet>
      <AppBar
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          backgroundColor: "#101A2F",
        }}
      >
        <Toolbar>
          <Typography variant="h4" color="white" noWrap component="div">
            <img
              src="/attend.svg"
              alt="Attend Icon"
              width="30"
              height="30"
              style={{ marginTop: "4px", marginRight: "4px" }}
            />
            Attend
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <CssBaseline />
      <Hero handleLogin={handleLogin} handleSignUp={handleSignUp} />
      <HowItWorks />
      <Features />

      <About />
      <Pricing handleSignUp={handleSignUp} />
      <Security />
      <SignUp handleSignUp={handleSignUp} />
      <Footer />
    </>
  );
};

export default LandingPage;
