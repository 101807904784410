import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import AttendIcon from "/attend.svg";
import { SxProps } from "@mui/system/styleFunctionSx";

const Template: React.FC<{
  children: React.ReactNode;
  boxSx?: SxProps;
}> = ({ children, boxSx = {} }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      component="main"
      sx={{
        height: "100%",
        minHeight: "100vh",
        width: "100vw",
        background:
          "linear-gradient(to top, #A0C8F3 0%, #E8EBF1 33%, #E8EBF1 100%)",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          boxSizing: "border-box",
          margin: "0 auto",
          backgroundColor: "#F7F8FA",
          border: "1px solid rgba(0, 0, 0, 0.1)", // 1px border with slight transparency
          borderRadius: "8px", // Rounded corners
          padding: { xs: "16px 24px", sm: "24px 32px" },
          ...boxSx,
        }}
      >
        {/* Header with Icon */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={AttendIcon}
            alt="Attend"
            style={{
              marginRight: 8,
              width: isMobile ? "40px" : "50px",
              height: isMobile ? "40px" : "50px",
            }}
          />
          <Typography
            component="h3"
            color="black"
            variant={isMobile ? "h4" : "h3"}
            sx={{ fontWeight: 200 }}
          >
            Attend
          </Typography>
        </Box>

        {children}
      </Box>
    </Box>
  );
};

export default Template;
