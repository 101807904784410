import React, { useState } from "react";
import {
  List,
  ListItem,
  Typography,
  IconButton,
  Stack,
  Box,
  Divider,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

interface TemplateSection {
  title: string;
  description?: string;
  type: "text";
  required?: boolean;
  allowAIPrefill: boolean;
  example?: string;
}

interface SectionListProps {
  sections: TemplateSection[];
  onUpdateSection: (section: TemplateSection, index: number) => void;
  onRemoveSection: (index: number) => void;
  onMoveSection: (index: number, direction: "up" | "down") => void;
  onAddSection: (section: TemplateSection) => void;
  onEditStateChange: (isEditing: boolean) => void;
}

interface SectionListProps {
  sections: TemplateSection[];
  onUpdateSection: (section: TemplateSection, index: number) => void;
  onRemoveSection: (index: number) => void;
  onMoveSection: (index: number, direction: "up" | "down") => void;
  onAddSection: (section: TemplateSection) => void;
  onEditStateChange: (isEditing: boolean) => void;
}

const SectionList: React.FC<SectionListProps> = ({
  sections,
  onUpdateSection,
  onRemoveSection,
  onMoveSection,
  onAddSection,
  onEditStateChange,
}) => {
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleEditSection = (index: number) => {
    setEditingIndex(index);
    onEditStateChange(true);
  };

  const handleSaveSection = (
    updatedSection: TemplateSection,
    index: number,
  ) => {
    if (!updatedSection.title.trim()) {
      setError("Section title is required");
      return;
    }
    onUpdateSection(updatedSection, index);
    setEditingIndex(null);
    setError(null);
    onEditStateChange(false);
  };

  const handleRemoveSection = (index: number) => {
    onRemoveSection(index);
    setEditingIndex(null);
    setError(null);
    onEditStateChange(false);
  };

  const handleAddNewSection = () => {
    const newSection: TemplateSection = {
      title: "",
      type: "text",
      description: "",
      allowAIPrefill: true,
    };
    onAddSection(newSection);
    setEditingIndex(sections.length);
  };

  const handleCloseEditing = () => {
    setEditingIndex(null);
    setError(null);
    onEditStateChange(false);
  };

  return (
    <List sx={{ p: 0 }}>
      {sections.map((section, index) => (
        <React.Fragment key={index}>
          <ListItem
            sx={{
              display: "block",
              p: 2,
              backgroundColor:
                editingIndex === index
                  ? "rgba(33, 150, 243, 0.08)"
                  : "transparent",
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor:
                  editingIndex === index
                    ? "rgba(33, 150, 243, 0.08)"
                    : "rgba(33, 150, 243, 0.04)",
              },
            }}
            onClick={() => editingIndex === null && handleEditSection(index)}
          >
            {editingIndex === index ? (
              <EditingSection
                section={section}
                onSave={(updatedSection) =>
                  handleSaveSection(updatedSection, index)
                }
                onRemove={() => handleRemoveSection(index)}
                onClose={handleCloseEditing}
                error={error}
              />
            ) : (
              <DisplaySection
                section={section}
                index={index}
                totalSections={sections.length}
                onMove={onMoveSection}
                onEdit={() => handleEditSection(index)}
              />
            )}
          </ListItem>
          {index < sections.length - 1 && <Divider />}
        </React.Fragment>
      ))}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddNewSection}
        >
          Add New Section
        </Button>
      </Box>
    </List>
  );
};

interface EditingSectionProps {
  section: TemplateSection;
  onSave: (section: TemplateSection) => void;
  onRemove: () => void;
  onClose: () => void; // Add this new prop
  error: string | null;
}

const EditingSection: React.FC<EditingSectionProps> = ({
  section,
  onSave,
  onRemove,
  onClose,
  error,
}) => {
  const [editedSection, setEditedSection] = useState(section);

  const handleChange =
    (field: keyof TemplateSection) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEditedSection({
        ...editedSection,
        [field]:
          field === "allowAIPrefill"
            ? event.target.checked
            : event.target.value,
      });
    };

  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      <TextField
        fullWidth
        label="Section Title"
        value={editedSection.title}
        onChange={handleChange("title")}
        margin="normal"
        error={!!error}
        helperText={error}
      />
      <TextField
        fullWidth
        label="Section Description"
        value={editedSection.description}
        onChange={handleChange("description")}
        margin="normal"
        multiline
      />

      <TextField
        fullWidth
        label="Example"
        value={editedSection.example || ""}
        onChange={handleChange("example")}
        margin="normal"
        multiline
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={editedSection.allowAIPrefill}
            onChange={handleChange("allowAIPrefill")}
            name="allowAIPrefill"
          />
        }
        label="Auto fill"
      />
      <Typography variant="caption" color="textSecondary">
        Uncheck to have Attend leave this section blank.
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button
          variant="text"
          color="error" // Changed to error for red color
          disableElevation
          onClick={onRemove}
          startIcon={<DeleteIcon />}
          sx={{ mr: 1 }}
        >
          Delete
        </Button>
        <Button
          variant="text"
          color="primary"
          onClick={onClose} // Use the new onClose prop
          startIcon={<CloseIcon />}
          sx={{ mr: 1 }}
        >
          Close
        </Button>
        <Button
          variant="text"
          color="primary"
          onClick={() => onSave(editedSection)}
          startIcon={<SaveIcon />}
          disableElevation
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

interface DisplaySectionProps {
  section: TemplateSection;
  index: number;
  totalSections: number;
  onMove: (index: number, direction: "up" | "down") => void;
  onEdit: () => void;
}

const DisplaySection: React.FC<DisplaySectionProps> = ({
  section,
  index,
  totalSections,
  onMove,
}) => {
  return (
    <Box
      sx={{
        position: "relative",
        "&:hover .section-controls": {
          opacity: 1,
        },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mr: 2, flexGrow: 1 }}
        >
          {section.title}
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          className="section-controls"
          sx={{
            opacity: 0,
            transition: "opacity 0.2s",
          }}
        >
          <IconButton
            size="small"
            aria-label="move up"
            onClick={(e) => {
              e.stopPropagation();
              onMove(index, "up");
            }}
            disabled={index === 0}
          >
            <ArrowUpwardIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            aria-label="move down"
            onClick={(e) => {
              e.stopPropagation();
              onMove(index, "down");
            }}
            disabled={index === totalSections - 1}
          >
            <ArrowDownwardIcon fontSize="small" />
          </IconButton>
        </Stack>
      </Box>
      {section.description && (
        <Typography variant="body1" sx={{ mb: 1, mr: 3 }}>
          {section.description}
        </Typography>
      )}
      {section.example && (
        <Typography
          variant="body2"
          sx={{
            fontStyle: "italic",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            mb: 1,
            ml: 2,
            mr: 3,
            mt: 0,
            color: "text.secondary",
          }}
        >
          "{section.example}"
        </Typography>
      )}
    </Box>
  );
};

export default SectionList;
