import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  Box,
  Typography,
  Stack,
  Fade,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

interface OnboardingStep {
  id: "welcome" | "ai-scribe" | "templates" | "encounters" | "support";
  title: string;
  description: string;
  image?: string;
}

const onboardingSteps: OnboardingStep[] = [
  {
    id: "welcome",
    title: "Welcome to Attend!",
    description:
      "Here's a quick tour of everything you need to streamline your charting.",
    image: "/onboarding/phone2.png",
  },
  {
    id: "ai-scribe",
    title: "AI Scribe",
    description:
      "To automate your documentation, simply start a new encounter and give Attend permission to use your microphone. Attend will transcribe in the background during your visit.",
    image: "/onboarding/record-screen.png",
  },
  {
    id: "encounters",
    title: "AI Editing and Composition",
    description:
      "After your encounter, Attend will create a clinical note and a personalized summary for your patient or client. Use the revise button to ask Attend to edit the note.\n\nUse the new document button to create additional documents like SMART goals, referral letters, or prior auth letters.",
    image: "/onboarding/edit-buttons.png",
  },
  {
    id: "templates",
    title: "Templates",
    description:
      "By default, Attend will use a SOAP template. Go to the templates section to create your own or add templates to your favorites from our library.",
  },
  {
    id: "support",
    title: "Support",
    description:
      "If you have questions or feedback, contact us any time. We're here to help!",
  },
];

export default function OnboardingFlow() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleComplete = async () => {
    navigate("/");
  };

  const handleNextStep = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentStep((prev) => prev + 1);
      setIsTransitioning(false);
    }, 200); // Match this with the exit animation duration
  };

  const handlePreviousStep = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentStep((prev) => prev - 1);
      setIsTransitioning(false);
    }, 200);
  };

  const currentStepData = onboardingSteps[currentStep];
  const isLastStep = currentStep === onboardingSteps.length - 1;

  return (
    <Dialog
      open={true}
      onClose={handleComplete}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 1,
          p: 2,
          maxHeight: "90vh",
          m: 2,
        },
      }}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
      }}
    >
      <DialogContent>
        <Stack spacing={3}>
          <Fade in={!isTransitioning} timeout={{ enter: 400, exit: 200 }}>
            <Stack spacing={3}>
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{
                  fontSize: { xs: "1.5rem", sm: "1.8rem" },
                  mb: 1,
                }}
              >
                {currentStepData.title}
              </Typography>

              {currentStepData.image && (
                <Box
                  component="img"
                  src={currentStepData.image}
                  alt={currentStepData.title}
                  sx={{
                    width: "100%",
                    height: 250,
                    objectFit: "contain",
                    my: 2,
                  }}
                />
              )}

              <Typography
                color="text.secondary"
                sx={{
                  fontSize: "1rem",
                  lineHeight: 1.5,
                  mb: 2,
                  mt: !currentStepData.image ? 2 : 0,
                }}
              >
                {currentStepData.description.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </Typography>

              <Stack direction="row" spacing={2} width="100%" mt="auto">
                {currentStep > 0 && (
                  <Button
                    variant="outlined"
                    onClick={handlePreviousStep}
                    sx={{ flex: 1 }}
                    size="large"
                  >
                    Back
                  </Button>
                )}

                <Button
                  variant="contained"
                  onClick={isLastStep ? handleComplete : handleNextStep}
                  sx={{
                    flex: currentStep > 0 ? 1 : "auto",
                    minWidth: currentStep === 0 ? 200 : "auto",
                  }}
                  size="large"
                  disableElevation
                  fullWidth={false}
                >
                  {isLastStep ? "Get Started" : "Next"}
                </Button>
              </Stack>

              <Box sx={{ display: "flex", gap: 1, justifyContent: "center" }}>
                {onboardingSteps.map((_, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: 8,
                      height: 8,
                      borderRadius: "50%",
                      bgcolor:
                        index === currentStep ? "primary.main" : "grey.300",
                    }}
                  />
                ))}
              </Box>
            </Stack>
          </Fade>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
