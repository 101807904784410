// ConsentDialog.tsx
import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Box,
} from "@mui/material";
import { useUser } from "../contexts/UserContext";

const ConsentDialog: React.FC = () => {
  const { userData, updateUserConsentStatus, fetchWithAuth } = useUser();
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!userData?.consentedToTOS) {
      setOpen(true);
    }
  }, [userData]);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleConsent = async () => {
    if (checked) {
      try {
        await fetchWithAuth("RecordTOSConsent", {
          method: "POST",
          body: JSON.stringify({ consentedToTOS: true }),
        });
        updateUserConsentStatus(true);
        setOpen(false);
      } catch (error) {
        console.error("Error updating consent status:", error);
      }
    }
  };

  return (
    <Dialog open={open} disableEscapeKeyDown>
      <DialogTitle>Attend AI Terms of Service</DialogTitle>
      <DialogContent>
        <Box>
          Welcome to Attend! To get started, please agree to the{" "}
          <Link href="/legal" target="_blank">
            Platform Terms of Service and BAA.
          </Link>
        </Box>
        <Box mt={2}>
          <FormControlLabel
            control={
              <Checkbox checked={checked} onChange={handleCheckboxChange} />
            }
            label="I agree to the terms of service"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleConsent} disabled={!checked}>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConsentDialog;
