import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from "react";
import { getCookie, setCookie } from "../utilities/cookieUtils";
import fetchAPI from "../utilities/fetchAPI";
import * as Sentry from "@sentry/react";

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

interface ISoapNote {
  Subjective: string;
  Objective: string;
  "Assessment and Plan": string;
}

export interface TemplateListItem {
  id: string;
  name: string;
  ownerType: string;
  description?: string;
  isDefault?: boolean;
}

export interface IUserContextData {
  id: string;
  givenName?: string;
  surname?: string;
  email?: string;
  clinicalSpecialty?: string;
  activeSubscriptionAttendPro: boolean;
  stripeCustomerId?: string;
  freeEncounterQuota: number;
  encounterCount: number;
  consentedToTOS: boolean;
  consentedToTOSDate: string;
  exampleSoapNote?: ISoapNote;
  signatureBlock?: string;
  defaultNoteTemplateId: string | null;
  templates?: TemplateListItem[];
}

interface IUserContext {
  userData: IUserContextData | null;
  setUserData: React.Dispatch<React.SetStateAction<IUserContextData | null>>;
  fetchUserData: () => Promise<void>;
  checkUserConsent: () => boolean;
  evaluateUserAccess: () => boolean;
  updateUserConsentStatus: (status: boolean) => void;
  updateDefaultNoteTemplate: (templateId: string | null) => Promise<void>;
  fetchUserTemplates: (
    callback?: (templates: TemplateListItem[]) => void,
  ) => Promise<void>;
  addSystemTemplateToUser: (
    templateId: string,
    templateName: string,
  ) => Promise<void>;
  removeSystemTemplateFromUser: (templateId: string) => Promise<void>;
  isLoggedIn: boolean;
  authenticate: ({
    token,
    refreshToken,
  }: {
    token: string;
    refreshToken: string;
  }) => void;
  handleLogout: () => void;
  fetchWithAuth: (
    uri: string,
    options?: RequestInit,
    authToken?: string,
  ) => Promise<any>;
  retryFetch: (
    url: string,
    options: RequestInit,
    retries?: number,
  ) => Promise<Response>;
}

const UserContext = createContext<IUserContext | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
    () => !!getCookie("authorization"),
  );
  const [userData, setUserData] = useState<IUserContextData | null>(null);

  useEffect(() => {
    if (isLoggedIn) {
      fetchUserData();

      // const tempUserId = getCookie('tempUserId');
      // if (tempUserId && getCookie('demoEncountersMigrated') !== 'true') {
      //   migrateDemoEncounters(tempUserId).then(() => {
      //     setCookie('demoEncountersMigrated', 'true', 60);
      //   });
      // }
    } else {
      setUserData(null);
      // Clear Sentry user on logout
      Sentry.setUser(null);
    }
  }, [isLoggedIn]);

  const fetchUserData = async () => {
    if (userData) {
      return;
    }
    const response = await fetchWithAuth("GetUserData");
    if (!response) throw new Error("No response from GetUserData");
    const data: IUserContextData = await response.json();
    setUserData(data);

    // Set Sentry user after successful data fetch
    Sentry.setUser({
      email: data.email,
      activeSubscriptionAttendPro: data.activeSubscriptionAttendPro,
    });

    await fetchUserTemplates(); // Fetch templates after user data
  };

  const addSystemTemplateToUser = async (
    templateId: string,
    templateName: string,
  ) => {
    if (!userData) return;

    // Optimistic update
    setUserData((prevData) => {
      if (!prevData) return prevData;
      return {
        ...prevData,
        templates: [
          ...(prevData.templates || []),
          { id: templateId, name: templateName, ownerType: "system" },
        ],
      };
    });

    try {
      const response = await fetchWithAuth("ToggleSystemTemplate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ templateId }),
      });

      if (!response?.ok) {
        throw new Error("Failed to add system template");
      }
    } catch (error) {
      console.error("Error adding system template:", error);
      // Revert the optimistic update
      setUserData((prevData) => {
        if (!prevData) return prevData;
        return {
          ...prevData,
          templates: prevData.templates?.filter((t) => t.id !== templateId),
        };
      });
    }
  };

  const removeSystemTemplateFromUser = async (templateId: string) => {
    if (!userData) return;

    // Optimistic update
    setUserData((prevData) => {
      if (!prevData) return prevData;
      return {
        ...prevData,
        templates: prevData.templates?.filter((t) => t.id !== templateId),
      };
    });

    try {
      const response = await fetchWithAuth("ToggleSystemTemplate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ templateId }),
      });

      if (!response?.ok) {
        throw new Error("Failed to remove system template");
      }

      if (userData.defaultNoteTemplateId === templateId) {
        await updateDefaultNoteTemplate("soap");
      }
    } catch (error) {
      console.error("Error removing system template:", error);
      // Revert the optimistic update
      setUserData((prevData) => {
        if (!prevData) return prevData;
        // Instead of looking for the removed template, we'll just add it back
        return {
          ...prevData,
          templates: [
            ...(prevData.templates || []),
            { id: templateId, name: "Unknown", ownerType: "system" }, // We don't have the name, so we use 'Unknown'
          ],
        };
      });
    }
  };

  const fetchUserTemplates = useCallback(
    async (callback?: (templates: TemplateListItem[]) => void) => {
      try {
        const response = await fetchWithAuth("GetUserClinicalNoteTemplates");
        if (response && response.ok) {
          const fetchedTemplates: TemplateListItem[] = await response.json();
          setUserData((prevData) => {
            const newData = prevData
              ? {
                  ...prevData,
                  templates: [...fetchedTemplates],
                }
              : null;
            return newData;
          });
          if (callback) {
            callback(fetchedTemplates);
          }
        } else {
          console.error("Failed to fetch templates");
        }
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    },
    [],
  );

  const checkUserConsent = (): boolean => {
    return userData?.consentedToTOS ?? false;
  };

  const evaluateUserAccess = (): boolean => {
    if (!userData) return false;
    return (
      userData.activeSubscriptionAttendPro ||
      userData.encounterCount < userData.freeEncounterQuota
    );
  };

  const updateUserConsentStatus = (status: boolean) => {
    if (userData) {
      setUserData({
        ...userData,
        consentedToTOS: status,
        consentedToTOSDate: new Date().toISOString(),
      });
    }
  };

  const updateDefaultNoteTemplate = async (templateId: string | null) => {
    if (!userData) return;

    try {
      const response = await fetchWithAuth("SetDefaultUserNoteTemplate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ templateId }),
      });

      if (response && response.ok) {
        setUserData({
          ...userData,
          defaultNoteTemplateId: templateId,
        });
      } else {
        throw new Error("Failed to update default template");
      }
    } catch (error) {
      console.error("Error updating default template:", error);
    }
    await fetchUserTemplates(); // Refetch templates after updating default
  };

  const authenticate = ({
    token,
    refreshToken,
  }: {
    token: string;
    refreshToken: string;
  }) => {
    setCookie("authorization", token, 30);
    setCookie("refreshToken", refreshToken, 60);
    if (!isLoggedIn) {
      setIsLoggedIn(true);
    }
  };

  const handleLogout = () => {
    setCookie("authorization", "", 0);
    setCookie("refreshToken", "", 0);
    setIsLoggedIn(false);

    // Clear Sentry user on logout
    Sentry.setUser(null);
  };

  // const migrateDemoEncounters = async (tempUserId: string) => {
  //   try {
  //     const response = await fetchWithAuth('MigrateDemoEncounters', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ tempUserId })
  //     });
  //
  //     if (!response.ok) {
  //       throw new Error('Failed to copy encounters');
  //     }
  //   } catch (error) {
  //     console.error('Error copying encounters:', error);
  //   }
  // };

  const fetchWithAuth = async (
    uri: string,
    options: RequestInit = {},
    optionalAuthToken?: string,
  ): Promise<Response> => {
    const auth = getCookie("authorization");
    if (!auth && !optionalAuthToken) {
      handleLogout();
      throw new Error("Unauthorized - No authentication token found");
    }

    try {
      // Append the Authorization header to the existing headers
      const headers = new Headers(options.headers);
      headers.append("Authorization", `Bearer ${optionalAuthToken || auth}`);

      // Update the options object to include the Authorization header
      const updatedOptions = { ...options, headers };

      // Perform the fetch request with the Authorization header
      const fetchResponse = await fetchAPI(uri, updatedOptions);

      if (fetchResponse.status === 403) {
        // return 403 to caller to handle (used to show the sign up component)
        Sentry.captureMessage(`Forbidden access to ${uri}`, {
          level: "warning",
          extra: { status: 403 },
        });
        return fetchResponse;
      }

      // Return 400/429 responses directly instead of throwing
      if (fetchResponse.status === 400 || fetchResponse.status === 429) {
        return fetchResponse;
      }

      if (!fetchResponse.ok) {
        const errorMessage = await fetchResponse.text().catch(() => null);
        Sentry.captureException(new Error(`API Error: ${uri}`), {
          extra: {
            status: fetchResponse.status,
            statusText: fetchResponse.statusText,
            errorMessage,
            endpoint: uri,
          },
        });
        throw fetchResponse;
      }

      return fetchResponse;
    } catch (error) {
      console.log(error, uri);
      if (
        typeof error === "object" &&
        error !== null &&
        "status" in error &&
        error.status === 401
      ) {
        const refreshToken = getCookie("refreshToken");
        if (!refreshToken) {
          handleLogout();
          throw new Error("Session expired - Please log in again");
        }

        return fetchAPI("RefreshToken", {
          method: "POST",
          body: JSON.stringify({
            token: refreshToken,
          }),
        })
          .then((response) => {
            if (!response.ok) {
              return Promise.reject(response);
            }

            return response.json();
          })
          .then((body) => {
            authenticate(body);
            return retryFetch(uri, options);
          })
          .catch(() => {
            handleLogout();
            throw new Error("Unauthorized");
          });
      } else {
        if (error instanceof Response) {
          switch (error.status) {
            case 404:
              throw new Error(`Resource not found - ${uri}`);
            case 500:
              Sentry.captureException(error, {
                extra: { endpoint: uri },
              });
              throw new Error("Server error - Our team has been notified");
            default:
              Sentry.captureException(error, {
                extra: { endpoint: uri, status: error.status },
              });
              throw new Error(
                `Request failed (${error.status}) - Please try again or contact support`,
              );
          }
        }
        // Handle network or other errors
        Sentry.captureException(error, {
          extra: { endpoint: uri },
        });
        throw new Error(
          "Network error - Please check your connection and try again",
        );
      }
    }
  };

  const retryFetch = async (
    url: string,
    options: RequestInit,
    retries: number = MAX_RETRIES,
  ): Promise<Response> => {
    try {
      const response = await fetchWithAuth(url, options);

      // If it's a 400 error, don't retry
      if (response?.status === 400 || response?.status === 403) {
        console.log("Server returned a 400 or 403 error. Not retrying.");
        return response;
      }

      if (!response || !response.ok) {
        throw new Error(
          "Something went wrong. Please try again or contact support@crafthealthtech.com.",
        );
      }

      return response;
    } catch (error) {
      if (retries > 0) {
        console.log(
          `Retrying... (${MAX_RETRIES - retries + 1}/${MAX_RETRIES})`,
        );
        await sleep(RETRY_DELAY);
        return retryFetch(url, options, retries - 1);
      } else {
        throw error;
      }
    }
  };

  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData,
        fetchUserData,
        checkUserConsent,
        evaluateUserAccess,
        updateUserConsentStatus,
        updateDefaultNoteTemplate,
        fetchUserTemplates,
        addSystemTemplateToUser,
        removeSystemTemplateFromUser,
        isLoggedIn,
        authenticate,
        handleLogout,
        fetchWithAuth,
        retryFetch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
