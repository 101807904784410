import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Typography,
  Alert,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { IFormTemplate } from "../../models/templates";
import LoadingCard from "../../reusable-components/LoadingCard";
import { useUser } from "../../contexts/UserContext";

interface TemplateContentInputProps {
  onTemplateGenerated: (template: IFormTemplate) => void;
}

const TemplateContentInput: React.FC<TemplateContentInputProps> = ({
  onTemplateGenerated,
}) => {
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [content, setContent] = useState("");
  const { fetchWithAuth } = useUser();

  const processContent = async () => {
    if (!content.trim()) return;

    setIsProcessing(true);
    setError(null);

    try {
      const response = await fetchWithAuth("GenerateTemplateFromContent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text: content }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to generate template");
      }

      const template: IFormTemplate = await response.json();
      onTemplateGenerated(template);
    } catch (err: unknown) {
      let errorMessage = "Failed to generate template. Please try again.";

      if (err instanceof Error) {
        if (err.message.includes("Max retries reached")) {
          errorMessage =
            "Unable to generate a valid template after multiple attempts. Please try simplifying your input or try again later.";
        } else if (err.message.includes("Invalid template schema")) {
          errorMessage =
            "The generated template was invalid. Please try again or modify your input.";
        } else if (err.message.toLowerCase().includes("too many requests")) {
          errorMessage =
            "Our service is experiencing high demand. Please try again in a few minutes.";
        }
        console.error("Error generating template:", err.message);
      } else {
        console.error("An unknown error occurred:", err);
      }

      setError(errorMessage);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCancel = () => {
    navigate("/");
  };

  return (
    <Box maxWidth={800}>
      {isProcessing ? (
        <LoadingCard
          loadingText="Generating Template"
          helperText="This may take up to 30 seconds. Please wait while we create your custom template."
        />
      ) : (
        <>
          <Typography variant="body1">
            Paste your content below to generate a custom note template. You can
            include:
          </Typography>
          <List dense>
            <ListItem disableGutters>
              <ListItemText primary="• An outline of your preferred note structure" />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary="• An example clinical note with identifying information removed" />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary="• A description of the sections you want in your template and how to format them" />
            </ListItem>
          </List>
          <Typography variant="body1">
            Our AI will analyze your input and create a template with
            appropriate sections and descriptions.
          </Typography>
          <TextField
            multiline
            rows={10}
            fullWidth
            variant="outlined"
            placeholder={`Chief Complaint:
- Presenting symptoms
- Duration of symptoms

History of Present Illness:
- Onset and progression
- Associated symptoms

Past Medical History:
- Chronic conditions
- Previous surgeries

...`}
            value={content}
            onChange={(e) => setContent(e.target.value)}
            sx={{ my: 2 }}
          />
        </>
      )}
      <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
        <Button
          variant="outlined"
          onClick={handleCancel}
          disabled={isProcessing}
          sx={{ flexBasis: "33%" }}
          disableElevation
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={processContent}
          disabled={isProcessing || !content.trim()}
          sx={{ flexBasis: "67%" }}
          disableElevation
        >
          {isProcessing ? "Generating..." : "Generate Template"}
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </Box>
  );
};

export default TemplateContentInput;
