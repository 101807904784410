import React, { memo, useMemo, useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  Typography,
  Grid,
  Box,
  Fade,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InputAdornment from "@mui/material/InputAdornment";

interface EncounterDetailHeaderProps {
  encounterTitle: string;
  patientDetails: string;
  encounterStartDate: string;
  totalDurationInSeconds: number;
  onEncounterNameChange: (name: string) => void;
  onPatientDetailsChange: (details: string) => void;
}

const EncounterDetailHeader: React.FC<EncounterDetailHeaderProps> = ({
  encounterTitle,
  patientDetails,
  encounterStartDate,
  totalDurationInSeconds,
  onEncounterNameChange,
  onPatientDetailsChange,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const formattedDateAndDuration = useMemo(() => {
    const durationInMinutes = Math.ceil(totalDurationInSeconds / 60);
    return `${durationInMinutes} minute encounter on ${encounterStartDate}`;
  }, [encounterStartDate, totalDurationInSeconds]);

  const handleCopyDateAndDuration = () => {
    navigator.clipboard
      .writeText(formattedDateAndDuration)
      .then(() => {
        console.log("Date and duration copied to clipboard");
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 500); // Reset after 2 seconds
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  };

  const copiedColor = "rgba(76, 175, 80, 0.1)"; // Very light green

  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              onClick={handleCopyDateAndDuration}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "8px",
                borderRadius: "4px",
                bgcolor: isCopied
                  ? copiedColor
                  : isHovered
                    ? "action.hover"
                    : "transparent",
                transition: "background-color 0.2s",
                position: "relative",
              }}
            >
              <Typography variant="h6" fontWeight={400}>
                {formattedDateAndDuration}
              </Typography>
              {(isHovered || isCopied) && (
                <ContentCopyIcon
                  fontSize="small"
                  sx={{ height: "15px", ml: 1 }}
                />
              )}
              <Fade in={isCopied} timeout={40}>
                <Typography
                  variant="caption"
                  sx={{
                    position: "absolute",
                    right: 8,
                    bgcolor: copiedColor,
                    px: 1,
                    py: 0.5,
                    borderRadius: 1,
                    whiteSpace: "nowrap",
                  }}
                >
                  Copied!
                </Typography>
              </Fade>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Encounter Description"
              variant="outlined"
              value={encounterTitle}
              onChange={(e) => onEncounterNameChange(e.target.value)}
              InputProps={{
                style: { fontSize: "1rem" },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Patient Details"
              variant="outlined"
              value={patientDetails}
              onChange={(e) => onPatientDetailsChange(e.target.value)}
              InputProps={{
                style: { fontSize: "1rem" },
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default memo(EncounterDetailHeader);
