import React, { useEffect, useState } from "react";
import { Alert, Button, Snackbar, TextField, Typography } from "@mui/material";
import { IUserContextData, useUser } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";

interface EditUserDataProps {
  userData: IUserContextData;
  authToken?: string;
  onSave?: () => {};
}

const EditUserData: React.FC<EditUserDataProps> = ({
  userData,
  authToken,
  onSave,
}: EditUserDataProps) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [signature, setSignature] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const { fetchWithAuth } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      setFirstName(userData?.givenName || "");
      setLastName(userData?.surname || "");
      setSignature(userData?.signatureBlock || "");
    }
  }, [userData]);

  const handleSave = async () => {
    const updatedUserData: IUserContextData = {
      ...userData,
      givenName: firstName,
      surname: lastName,
      signatureBlock: signature,
    };

    try {
      await fetchWithAuth(
        "UpdateUserData",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedUserData),
        },
        authToken,
      ).then(onSave);
      setOpenSnackbar(true);
      navigate("/");
    } catch (error) {
      console.error("Error updating user settings:", error);
    }
  };

  return (
    <>
      <TextField
        label="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        fullWidth
        margin="normal"
        InputLabelProps={{ style: { color: "black" } }}
        sx={{ mb: 2 }}
      />
      <TextField
        label="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        fullWidth
        margin="normal"
        InputLabelProps={{ style: { color: "black" } }}
        sx={{ mb: 2 }}
      />
      <Typography variant="body2" gutterBottom sx={{ color: "black", mb: 2 }}>
        Add a signature block to be used when generating documents to send to
        patients or other third parties.
      </Typography>
      <TextField
        label="Signature Block"
        value={signature}
        placeholder="Thorin Oakenshield, MD
Primary Care Associates
(555) 555-5555"
        onChange={(e) => setSignature(e.target.value)}
        multiline
        rows={4}
        fullWidth
        margin="normal"
        InputLabelProps={{ style: { color: "black" } }}
        sx={{ mb: 2 }}
      />
      <Button variant="contained" color="primary" onClick={handleSave}>
        Save settings
      </Button>

      {/*@todo: Move to global scope*/}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          variant="filled"
          severity="success"
          sx={{ width: "100%" }}
        >
          Settings saved successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditUserData;
